import Vue from "vue";
import Vuex from "vuex";
import home from "./modules/home";
import layer from "./modules/layer";
import edit from "./modules/edit";
import measurement from "./modules/measurement";
import search from "./modules/search";
import routing from "./modules/routing";
import more from "./modules/more";
import setting from "./modules/setting";
import featurePanel from "./modules/featurePanel";
import geolocation from "./modules/geolocation";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home: home,
    layer: layer,
    edit: edit,
    measurement: measurement,
    search: search,
    more: more,
    setting: setting,
    routing: routing,
    geolocation: geolocation,
    featurePanel: featurePanel
  },
  state: {
    isSearchBarHidden: true,
    isHomeButtonHidden: true,
    isLayerButtonHidden: true,
    isEditButtonHidden: true,
    isMeasurementButtonHidden: true,
    isDeleteButtonVisible: false,
    isUndoButtonVisible: false,
    isRedoButtonVisible: false,
    isMenuDialog: false,
    userDefaults: { latlongoServer: localStorage["server-name"] },
    userName: " - ",
    configId: 0,
    /** login, home, layers, search, feature, edit, measurements,
    internal, schematic, help **/
    appMode: "",
    isHelpDialog: false,
    isAboutDialog: false,
    isSettingsDialog: false,
    isPrint: false
  },
  mutations: {
    changeMenuDialogState(state, value) {
      state.isMenuDialog = value;
    },
    hideModeButtons(state) {
      state.isSearchBarHidden = false;
      state.isHomeButtonHidden = false;
      state.isLayerButtonHidden = false;
      state.isEditButtonHidden = false;
      state.isMeasurementButtonHidden = false;
    },
    showModeButtonsAndSearchBar(state) {
      state.isSearchBarHidden = true;
      state.isHomeButtonHidden = true;
      state.isLayerButtonHidden = true;
      state.isEditButtonHidden = true;
      state.isMeasurementButtonHidden = true;
    },
    hideSearchBar(state, value) {
      state.isSearchBarHidden = value;
    },
    hideDeleteRedoAndUndoButtons(state) {
      state.isDeleteButtonVisible = false;
      state.isUndoButtonVisible = false;
      state.isRedoButtonVisible = false;
    },
    showDeleteRedoAndUndoButtons(state) {
      state.isDeleteButtonVisible = true;
      state.isUndoButtonVisible = true;
      state.isRedoButtonVisible = true;
    },
    commitLoginSuccess(state, userName) {
      state.userName = userName;
    },
    commitAppMode(state, appMode) {
      state.appMode = appMode;
    },
    commitConfigId(state, configId) {
      state.configId = configId;
    },
    changeIsHelpDialogState(state, value) {
      state.isHelpDialog = value;
    },
    changeIsAboutDialogState(state, value) {
      state.isAboutDialog = value;
    },
    changeIsSettingsDialogState(state, value) {
      state.isSettingsDialog = value;
    },
    changeIsPrintState(state, value) {
      state.isPrint = value;
    }
  },
  actions: {
    handleUndo({ state, dispatch }) {
      if (state.appMode === "measurement") {
        dispatch("measurement/handleMeasurementUndo");
      }
    },
    handleRedo({ state, dispatch }) {
      if (state.appMode === "measurement") {
        dispatch("measurement/handleMeasurementRedo");
      }
    },
    handleDelete({ state, dispatch }) {
      if (state.appMode === "measurement") {
        dispatch("measurement/handleMeasurementDelete");
      }
    },
    handleSwitchToHome({ state, commit }) {
      commit("commitAppMode", "home");
      commit("showModeButtonsAndSearchBar");
      commit("hideDeleteRedoAndUndoButtons");
      commit("changeMenuDialogState", false);
    },
    handleSwitchFromHome({ state, commit, dispatch }, payload) {
      // remove search suggestions panel when switching mode
      if (search.state.searchQuery !== null) {
        dispatch("search/handleExitMyMode");
      }
      commit("hideModeButtons");
      commit("changeMenuDialogState", false);
      if (payload.mode === "search") {
        commit("hideSearchBar", true);
      } else if (payload.mode === "featurePanel") {
        dispatch("featurePanel/handlePopulateFeature", payload.value);
      } else if (payload.mode === "measurement") {
        commit("showDeleteRedoAndUndoButtons");
      } else if (payload.mode === "routing") {
        dispatch("featurePanel/handleExitMyMode");
      }
      commit("commitAppMode", payload.mode);
    },
    handleBack({ state, dispatch, commit }) {
      // Logic for closing modes.
      if (state.appMode === "routing") {
        dispatch("routing/handleExitMyMode");
      } else if (state.appMode === "measurement") {
        dispatch("measurement/handleExitMyMode");
      } else if (state.appMode === "edit") {
        dispatch("edit/handleExitMyMode");
      } else if (state.appMode === "layer") {
        dispatch("layer/handleExitMyMode");
      } else if (state.appMode === "search") {
        dispatch("search/handleExitMyMode");
      } else if (state.appMode === "featurePanel") {
        dispatch("featurePanel/handleExitMyMode");
      }
      if (state.appMode === "routing") {
        // Exception in closing mode for Routing.
        dispatch(
          "handleSwitchFromHome",
          {
            mode: "featurePanel",
            value: state.featurePanel.currentFeatureInfo
          },
          { root: true }
        );
      } else if (
        state.appMode === "featurePanel" &&
        (state.featurePanel.currentFeatureInfo.type === "editFeature" ||
        state.featurePanel.currentFeatureInfo.type === "multiEditFeatures")
      ) {
        // Exception in closing mode for edit if edit feature is selected
        commit("commitAppMode", "edit");
      } else if (search.state.searchQuery === null) {
        // Exception in closing mode for Searchbar.
        dispatch("handleSwitchToHome");
      } else {
        // Exception in closing default mode.
        dispatch("search/handleExitMyMode");
      }
    }
  }
});
