<template>
  <v-container id="mapGround" fill-height>
    <vue-ins-progress-bar></vue-ins-progress-bar>
    <v-row class="map-row">
      <div
        id="mapDiv"
        ref="map"
        class="mapDivClass"
        v-resize="onResize"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseDown"
      ></div>
    </v-row>
    <v-overlay
      dark
      absolute
      z-index="0"
      :value="isMenuDialog"
      @click.native="onOverlayClick"
    />
    <div id="map-zoom-and-scale">
      <div>
        {{ $t("Scale") }}
        {{ scale }}
      </div>
      <div>
        {{ $t("Zoom") + ":" }}
        {{ zoom }}
      </div>
    </div>
    <div
      id="rssLayersLoadingMsg"
      class="geoRssLoadingColor"
      v-if="isGeorssVisible && isGeoRssLoading"
    >
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
      Loading: {{ geoRssLoadingText }}
    </div>
    <div class="printMe" ref="iprint">
      <canvas></canvas>
    </div>
  </v-container>
</template>

<script>
import HereUtil from "@/util/here";
import HereStoreUtil from "@/util/hereStore";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { mapGetters, mapState } from "vuex";
import featuresApi from "@/api/features";
import georssApi from "@/api/georss";

export default {
  name: "hereMap",

  data() {
    return {
      hereUtil: null,
      hereStoreUtil: new HereStoreUtil(),
      tileLayers: [],
      removeLine: null,
      isEditBoundSet: false,
      removePolygon: null,
      highLightObj: [],
      unsubscribe: {},
      unsubscribeAction: {},
      scale: "",
      zoom: "",
      geoRssScale: "",
      geoRssLayer: {},
      geoRssLoadingText: "",
      geoRssCurrentFeature: "",
      isGeoRssLoading: false,
      isGeoRssObjectRemoved: false,
      isFeatureClicked: false,
      featureTappedCoord: "",
      featureResponse: "",
      lineString: new H.geo.LineString(),
      homeBoundsRect: null,
      isShiftKeyPressed: false
    };
  },
  computed: {
    ...mapState({
      isMenuDialog: state => state.isMenuDialog,
      configUnitSystem: state => state.home.config.units,
      mapCenter: state => state.home.mapCenter,
      routeArray: state => state.routing.routeArray,
      permalink: state => state.home.permalink,
      isLayerWindow: state => state.layer.isLayerWindow,
      appMode: state => state.appMode,
      activeOverlayLayerIds: state => state.layer.activeOverlayLayerIds,
      isEditFeature: state => state.featurePanel.currentFeatureInfo.type,
      isPolygonValid: state => state.measurement.isPolygonValid,
      currentMeasurementMode: state => state.measurement.currentMeasurementMode,
      homeConfig: state => state.home.config,
      routingStartPoint: state => state.routing.startPoint,
      routingEndPoint: state => state.routing.endPoint,
      editfillColor: state => state.edit.editfillColor,
      editFillColorForAudioAndPhotoIcons: state =>
        state.edit.editFillColorForAudioAndPhotoIcons
    }),
    ...mapGetters({
      isPoiVisible: "layer/isPoiLayerVisible",
      activeOverlayLayers: "layer/getActiveOverlayLayerId",
      isGeorssVisible: "layer/isGeorssLayerVisible"
    })
  },
  created() {
    console.log("...Here map panel created.");
  },
  mounted() {
    this.pushLoginSubscriptions();
    this.pushLayerSubscriptions();
    this.pushEnterModeSubscriptions();
    this.pushSubscriptions();
    this.pushGeolocationSubscriptions();
    this.pushHereToolsSunscription();
  },
  methods: {
    onResize(evt) {
      if (evt && this.hereUtil !== null) {
        this.hereUtil.hereMap.getViewPort().resize();
      }
    },
    pushLoginSubscriptions() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "home/parseConfig") {
          this.initializeMap(mutation.payload);
        }
      });
    },
    pushHereToolsSunscription() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "setting/updateHereToolStatus") {
          let hereToolStatus = state.setting.ishereToolsOn;
          this.hereUtil.ui
            .getControl("zoomRectangle")
            .setVisibility(hereToolStatus);
          this.hereUtil.ui.getControl("zoom").setVisibility(hereToolStatus);
        }
      });
    },
    pushEnterModeSubscriptions() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "commitAppMode") {
          if (mutation.payload === "measurement") {
            this.pushMeasurementSubscriptions();
          } else if (mutation.payload === "routing") {
            this.pushRoutingSubscriptions();
          } else if (mutation.payload === "edit") {
            this.pushEditSubscriptions();
          } else if (mutation.payload === "editsPhotoDialog") {
            this.clearMap();
          } else if (mutation.payload === "audioDialog") {
            this.clearMap();
          }
        } else if (mutation.type === "changeIsPrintState") {
          if (mutation.payload) {
            this.printMapCanvas();
          }
        }
      });
    },
    exitModeSubscriptions() {
      this.unsubscribeAction();
      this.unsubscribe();
    },
    pushLayerSubscriptions() {
      this.$store.subscribe((mutation, state) => {
        if (
          mutation.type === "layer/changeNewLayer" ||
          mutation.type === "layer/changeLayerVisibility"
        ) {
          this.changeLayerVisibility(mutation.payload);
        } else if (mutation.type === "layer/changeLayerWindowState") {
          if (this.isLayerWindow) {
            this.removeHomeEventListners();
          } else {
            this.addHomeEventListners();
          }
        }
      });
    },
    controlGeolocate(evt) {
      this.$store.dispatch("geolocation/handleGeolocation", "not-follow");
    },
    pushGeolocationSubscriptions() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "geolocation/changeGeolocate") {
          if (mutation.payload === "off") {
            this.hereUtil.removeGeoMarker();
            this.hereUtil.hereMap.removeEventListener(
              "dragstart",
              this.controlGeolocate
            );
          } else if (mutation.payload === "follow") {
            this.hereUtil.hereMap.addEventListener(
              "dragstart",
              this.controlGeolocate
            );
          } else if (mutation.payload === "not-follow") {
            this.hereUtil.hereMap.removeEventListener(
              "dragstart",
              this.controlGeolocate
            );
          }
        }

        if (mutation.type === "geolocation/changePosition") {
          this.hereUtil.removeGeoMarker();
          if (mutation.payload && mutation.payload.coords) {
            let gpsPoint = new H.geo.Point(
              mutation.payload.coords.latitude,
              mutation.payload.coords.longitude
            );
            this.hereUtil.addGeoMarkerToMap(gpsPoint);
            if (state.geolocation.geolocate === "follow") {
              this.hereUtil.panMap(gpsPoint);
            }
          }
        }
      });
    },
    printMapCanvas() {
      this.hereUtil.hereMap.capture(canvas => {
        if (canvas) {
          this.$refs.iprint.lastChild.replaceWith(canvas);
          window.print();
          this.$store.dispatch("handleBack");
        } else {
          // For example when map is in Panorama mode;
          window.getApp.$emit("FLASH_MESSAGE", {
            text: "Capturing is not supported.",
            type: "error"
          });
        }
        this.$store.commit("changeIsPrintState", false);
      });
    },
    pushMeasurementSubscriptions() {
      // This is needed @ measurement mode only.
      this.unsubscribeAction = this.$store.subscribeAction({
        after: (action, state) => {
          if (action.type === "measurement/handleEnterMeasurement") {
            this.removeHomeEventListners();
            console.log(
              "Reverse geocoding event handling removed with " +
                action.type +
                "."
            );
            // Add measurement event handlers.
            this.hereUtil.hereMap.addEventListener(
              "tap",
              this.measurementMapTap
            );
          } else if (
            action.type === "measurement/handleChangeMeasurementMode"
          ) {
            this.clearMap();
          } else if (action.type === "measurement/handleMeasurementMapTapped") {
            this.handleMeasurementMapTapped(
              state.measurement.currentMeasurementMode,
              state.measurement.mapTappedPoints
            );
          } else if (action.type === "measurement/handleExitMyMode") {
            this.clearMap();
            this.hereUtil.hereMap.removeEventListener(
              "tap",
              this.measurementMapTap
            );
            this.addHomeEventListners();
            this.exitModeSubscriptions();
          }
        }
      });
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === "home/changeUnitSystemState") {
          this.handleMeasurementMapTapped(
            state.measurement.currentMeasurementMode,
            state.measurement.mapTappedPoints
          );
        } else if (
          mutation.type === "measurement/changeMapTappedPoints" &&
          mutation.payload.action === "assign"
        ) {
          this.handleMeasurementMapTapped(
            state.measurement.currentMeasurementMode,
            state.measurement.mapTappedPoints
          );
        }
      });
    },
    pushRoutingSubscriptions() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        this.hereUtil.hereMap.removeEventListener(
          "tap",
          this.reverseGeocodeTap
        );
        let highlightRoute = false;
        if (mutation.type === "routing/changeRouteArray") {
          this.clearMap();
          this.hereUtil.ui
            .getBubbles()
            .forEach(bubble => this.hereUtil.ui.removeBubble(bubble));
          this.hereUtil.clearStartEndTags();
          if (mutation.payload) {
            this.hereUtil.addStartEndTags(
              this.routingStartPoint.coords,
              this.routingEndPoint
            );
            mutation.payload.forEach((route, index) => {
              this.addRouteToMap(route, index);
            });
            this.$insProgress.finish();
          }
        } else if (mutation.type === "home/changeUnitSystemState") {
          this.$insProgress.start();
          let destination = [
            state.featurePanel.currentFeatureInfo.geometry.coordinates[1],
            state.featurePanel.currentFeatureInfo.geometry.coordinates[0]
          ];
          this.$store.dispatch("routing/handleRouteToDestination", destination);
          this.$insProgress.finish();
        }
      });
      this.unsubscribeAction = this.$store.subscribeAction({
        after: (action, state) => {
          if (action.type === "routing/handleExitMyMode") {
            this.hereUtil.hereMap.addEventListener(
              "tap",
              this.reverseGeocodeTap
            );
            this.hereUtil.ui
              .getBubbles()
              .forEach(bubble => this.hereUtil.ui.removeBubble(bubble));
            this.exitModeSubscriptions();
            this.hereUtil.clearRouteOverviewPolyline();
          }
        }
      });
      // Add the calculated route to map.
      this.routeArray.forEach((route, i) => this.addRouteToMap(route, i));
    },
    pushEditSubscriptions() {
      this.unsubscribe = this.$store.subscribeAction({
        after: (action, state) => {
          if (action.type === "edit/handleEnterEdit") {
            this.removeHomeEventListners();
            console.log(
              "Reverse geocoding event handling removed with " +
                action.type +
                "."
            );
            // Add edit event handlers.
            this.hereUtil.hereMap.addEventListener(
              "mapviewchangeend",
              this.editMapViewChangeEnd
            );
            this.hereUtil.hereMap.addEventListener("tap", this.handleEditTap);
          } else if (action.type === "edit/handleShowEditOnMap") {
            this.addCurrentEditFeaturesToMap(action.payload);
          } else if (action.type === "edit/handleRemoveEditFromMap") {
            this.clearEdit();
          } else if (action.type === "edit/handleExitMyMode") {
            this.clearEdit();
            this.hereUtil.hereMap.removeEventListener(
              "mapviewchangeend",
              this.editMapViewChangeEnd
            );
            this.hereUtil.hereMap.removeEventListener(
              "tap",
              this.handleEditTap
            );
            this.addHomeEventListners();
            this.$store.dispatch("edit/handleRemoveEditFromMap");
            this.exitModeSubscriptions();
          }
        }
      });
    },
    pushSubscriptions() {
      this.$store.subscribeAction({
        before: (action, state) => {
          if (
            action.type === "featurePanel/handleShowFeature" &&
            (action.payload.type === "Feature" ||
              action.payload.type == "editFeature" ||
              action.payload.type == "geoRssFeature")
          ) {
            this.clearMap();
            if (
              action.payload.type === "editFeature" &&
              action.payload.feature.name === "LatLonGORedlineNote"
            ) {
              action.payload.feature.note.style.color = this.homeConfig.highlight_stroke_color;
            } else {
              let highlightObject;
              if (!action.payload.isFeatureList) {
                if (action.payload.type === "Feature") {
                  highlightObject = this.hereUtil.addHighlightFeatureToMap(
                    this.homeConfig,
                    action.payload.geometry,
                    action.payload.zoomToFeature
                  );
                } else if (action.payload.type === "editFeature") {
                  highlightObject = this.hereUtil.addHighlightFeatureToMap(
                    {
                      highlight_fill_color: this.homeConfig
                        .highlight_fill_color,
                      highlight_stroke_color: this.homeConfig
                        .highlight_stroke_color,
                      feature: action.payload.feature.type
                    },
                    {
                      type: action.payload.geometry.type,
                      coordinates: action.payload.geometry.coordinates
                    },
                    false
                  );
                } else if (action.payload.type === "geoRssFeature") {
                  this.clearMap();
                  highlightObject = this.hereUtil.addGeoRssFeatureToMap(
                    {
                      highlight_fill_color: this.homeConfig
                        .highlight_fill_color,
                      highlight_stroke_color: this.homeConfig
                        .highlight_stroke_color
                    },
                    this.geoRssScale,
                    {
                      type: action.payload.feature.polygon
                        ? "Polygon"
                        : "Point",
                      coordinates: this.hereUtil.getCoordsFromString(
                        action.payload.feature.polygon
                          ? action.payload.feature.polygon.__text
                          : action.payload.feature.point.__text
                      )
                    }
                  );
                } else {
                  highlightObject = this.hereUtil.addHighlightAddressToMap(
                    action.payload.feature,
                    action.payload.geometry,
                    action.payload.zoomToFeature
                  );
                }
                this.highLightObj.push(highlightObject);
              }
            }
          } else if (
            action.type === "featurePanel/handleShowFeature" &&
            action.payload.type === "multiEditFeatures"
          ) {
            this.clearMap();
          }
          if (
            action.type === "featurePanel/handleShowFeature" &&
            (action.payload.type === "Address" ||
              action.payload.type === "Mapcode")
          ) {
            this.hereUtil.zoomToAddress(
              action.payload.feature,
              action.payload.geometry,
              true
            );
          }
          if (action.type === "featurePanel/unselectFeatureFromList") {
            let index = state.featurePanel.currentFeatureInfo.feature.findIndex(
              feature => feature.id === action.payload.id
            );
            this.hereUtil.removePinFromMultiFeature(index);
          }
        },
        after: (action, state) => {
          if (
            action.type === "featurePanel/handleExitMyMode" &&
            state.featurePanel.currentFeatureInfo.type !== "multiEditFeatures"
          ) {
            if (
              state.featurePanel.currentFeatureInfo.feature &&
              state.featurePanel.currentFeatureInfo.feature.note
            ) {
              state.featurePanel.currentFeatureInfo.feature.note.style.color = this.editfillColor;
            } else {
              this.clearMap();
            }
            if (
              state.featurePanel.currentFeatureInfo.type === "geoRssFeature"
            ) {
              if (this.hereUtil.featurePopup !== undefined) {
                this.hereUtil.updatePopup();
                this.isGeoRssObjectRemoved = true;
                this.geoRssCurrentFeature = "";
              }
            }
            if (
              state.featurePanel.currentFeatureInfo.type !== "editFeature" &&
              state.featurePanel.currentFeatureInfo.type !== "geoRssFeature"
            ) {
              if (this.hereUtil.multipleFeaturePopup) {
                this.hereUtil.clearMultiplePinMarker();
              }
              this.hereUtil.removeObjectsFromMap({ removeall: true });
            }
          }
          if (
            action.type === "featurePanel/handleShowJoinFeature" ||
            action.type === "featurePanel/handleShowPreviousFeature"
          ) {
            let featureinfo = state.featurePanel.currentFeatureInfo;
            let isEditFeature =
              featureinfo.type &&
              (featureinfo.type === "multiEditFeatures" ||
                featureinfo.type === "editFeature");
            if (!isEditFeature) {
              if (
                action.type === "featurePanel/handleShowJoinFeature" &&
                featureinfo.back.showMultiplePins
              ) {
                //clearing map the as there are multiple pins
                this.hereUtil.clearMultiplePinMarker();
                this.hereUtil.updatePopup(
                  !featureinfo.isFeatureList,
                  this.$store,
                  featureinfo.feature.anchor
                    ? {
                        lng: featureinfo.feature.anchor[0],
                        lat: featureinfo.feature.anchor[1]
                      }
                    : undefined,
                  [featureinfo.feature]
                );
              } else if (
                action.type === "featurePanel/handleShowPreviousFeature" &&
                featureinfo.showMultiplePins
              ) {
                this.hereUtil.updatePopup();
                this.hereUtil.addMultiplePinMarker(
                  this.$store,
                  featureinfo.feature
                );
              } else {
                this.hereUtil.updatePopup(
                  !featureinfo.isFeatureList,
                  this.$store,
                  featureinfo.feature.anchor
                    ? {
                        lng: featureinfo.feature.anchor[0],
                        lat: featureinfo.feature.anchor[1]
                      }
                    : undefined,
                  [featureinfo.feature]
                );
              }
            }
          }
          if (action.type === "featurePanel/handleShowPreviousFeature") {
            let featureinfo = state.featurePanel.currentFeatureInfo;
            let isEditFeature =
              featureinfo.type &&
              (featureinfo.type === "multiEditFeatures" ||
                featureinfo.type === "editFeature");
            if (isEditFeature) {
              this.clearMap();
            }
          }
          if (
            action.type === "featurePanel/handlePopulateFeature" &&
            action.payload.title === "Features"
          ) {
            let featureinfo = state.featurePanel.currentFeatureInfo;
            if (action.payload.showMultiplePins) {
              this.hereUtil.addMultiplePinMarker(
                this.$store,
                action.payload.feature
              );
            } else {
              this.hereUtil.updatePopup(
                true,
                this.$store,
                {
                  lng: featureinfo.feature[0].anchor[0],
                  lat: featureinfo.feature[0].anchor[1]
                },
                featureinfo.feature
              );
            }
          }
          if (
            action.type === "featurePanel/handlePopulateFeature" &&
            action.payload.searchType !== "multiEditFeatures" &&
            action.payload.searchType !== "editFeature" &&
            !action.payload.showMultiplePins &&
            action.payload.searchType !== "geoRssFeature"
          ) {
            // Removing the highlight pin for reversegeocode
            if (action.payload.searchType === "reverseGeocode") {
              this.hereUtil.updatePopup();
            }
            this.hereUtil.addPinMarker(
              this.$store,
              action.payload.tappedCoord,
              action.payload.allFeatures
                ? action.payload.allFeatures
                : action.payload.feature
            );
          }
        }
      });
    },
    initializeMap(config) {
      this.hereUtil = new HereUtil();
      // Remove previous map if exist.
      if (this.$refs.map.childNodes.length > 0) {
        this.$refs.map.removeChild(this.$refs.map.childNodes[0]);
      }

      let serverName = localStorage["server-name"];
      if (serverName && serverName.indexOf("https://") !== 0) {
        serverName = "https://" + serverName;
      }
      this.hereUtil.initializeMap(
        serverName,
        localStorage["latlongo-api-key"],
        config.here_licenses.js_rest.apikey,
        this.$refs.map,
        this.mapCenter
      );

      this.hereUtil.ui.addControl("zoomRectangle", new H.ui.ZoomRectangle());
      let mapSettings = this.hereUtil.ui.getControl("mapsettings");
      let scaleBar = this.hereUtil.ui.getControl("scalebar");
      scaleBar.addEventListener("toggleunitsystem", this.scaleBarUnitsToggeled);

      /* Hiding the unwanted default UI */
      mapSettings.setVisibility(false);
      if (config.home.useGeoLocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.hereUtil.panMap(
              new H.geo.Point(
                position.coords.latitude,
                position.coords.longitude
              )
            );
          },
          err => {
            window.getApp.$emit("FLASH_MESSAGE", {
              text: "Can't get current location. Using home region instead.",
              type: "error"
            });
            this.hereUtil.setNewMapViewBounds(config.home.region);
          },
          { timeout: 6000 }
        );
      } else {
        this.hereUtil.setNewMapViewBounds(config.home.region);
      }

      window.addEventListener("resize", () =>
        this.hereUtil.hereMap.getViewPort().resize()
      );

      this.tileLayers.splice(0, this.tileLayers.length);
      this.hereUtil.ui.setUnitSystem(
        this.configUnitSystem === "metric"
          ? H.ui.UnitSystem.METRIC
          : H.ui.UnitSystem.IMPERIAL
      );
      // /* Adding event listeners */

      this.hereUtil.hereMap.addEventListener("mapviewchangeend", this.mapChangeEnd);
      this.addHomeEventListners();
      // Update map as per permalink center. Else navigate to home bounds.
      if (this.permalink?.center
        && this.permalink.center[0]
        && this.permalink.center[1]
        && !(isNaN(this.permalink.center[1]) || isNaN(this.permalink.center[0]))) {
          this.hereUtil.hereMap.setCenter({
            lng: this.permalink.center[1],
            lat: this.permalink.center[0]
          });
      }
      // Update map as per permalink zoom.
      if (this.permalink?.zoom) {
        this.hereUtil.hereMap.setZoom(this.permalink.zoom);
      }
      console.log("...Here map initialized.");
      this.hereUtil.hereMap.addEventListener("dragstart", this.disableMapDragtoDrawHomeBounds);
      this.hereUtil.hereMap.addEventListener("drag", this.drawHomeBounds);
      this.hereUtil.hereMap.addEventListener("dragend", this.enableMapDragtoDrawHomeBounds);
    },
    changeLayerVisibility(layer) {
      if (layer.isVisible) {
        if (layer.isBaseLayer) {
          this.hereUtil.addBaseLayer(layer, this.hereUtil.defaultLayers);
          if (this.isPoiVisible) {
            this.hereUtil.setPoiLayer();
          }
        } else {
          if (layer.type === "georss" && this.isGeorssVisible) {
            this.geoRssLoadingText = layer.name;
            this.addGeorssLayerToMap(layer);
            layer.geoRssInterval = setInterval(() => {
              this.$store.dispatch("featurePanel/handleExitMyMode");
              this.addGeorssLayerToMap(layer);
            }, layer.refresh * 1000);
          } else {
            let newLayer = this.hereUtil.addOverlayLayer(
              this.hereUtil.defaultLayers,
              layer,
              this.tileLayers[layer.id]
            );
            this.tileLayers[layer.id] = newLayer;
          }
        }
      } else {
        if (
          layer.type === "georss" &&
          Object.keys(this.geoRssLayer).length !== 0
        ) {
          this.removeGeorssLayerFromMap(layer);
        } else {
          this.hereUtil.removeLayerFromMap(
            layer.type,
            this.tileLayers[layer.id],
            this.hereUtil.defaultLayers
          );
        }
      }
    },
    addTileProvider(tileProvider) {
      let tileLayer = new H.map.layer.TileLayer(tileProvider);
      this.hereUtil.hereMap.addLayer(tileLayer);
    },
    addHomeEventListners() {
      this.hereUtil.hereMap.addEventListener("tap", this.reverseGeocodeTap);
    },
    removeHomeEventListners() {
      this.hereUtil.hereMap.removeEventListener("tap", this.reverseGeocodeTap);
    },
    clearRssLayer(layer) {
      clearInterval(layer.geoRssInterval);
      layer.geoRssInterval = undefined;
      this.isGeoRssLoading = false;
    },
    addGeorssLayerToMap(layer) {
      this.geoRssLayer = {};
      this.isGeoRssLoading = true;
      georssApi.checkForRssFeeds(layer.url).then(
        response => {
          if (response === null) {
            this.clearRssLayer(layer);
            window.getApp.$emit("FLASH_MESSAGE", {
              text: "Parse Error",
              type: "error"
            });
          } else if (response.rss || (response.feed && response.feed.entry)) {
            response.symbology = layer.symbology;
            this.geoRssLayer = response;
            if (this.isGeorssVisible) {
              this.processGeoRssLayer(response);
              this.isGeoRssLoading = false;
            } else {
              this.clearRssLayer(layer);
            }
          } else if (response.feed && !response.feed.entry) {
            //case where there are no entries
            this.clearRssLayer(layer);
          } else {
            this.clearRssLayer(layer);
            window.getApp.$emit("FLASH_MESSAGE", {
              text: "Parse Error",
              type: "error"
            });
          }
        },
        error => {
          window.getApp.$emit("FLASH_MESSAGE", {
            text: "Invalid URL." + error,
            type: "error"
          });
          this.clearRssLayer(layer);
        }
      );
    },
    removeGeorssLayerFromMap(layer) {
      this.hereUtil.removeObjectsFromMap({ removeall: true });
      clearInterval(layer.geoRssInterval);
      layer.geoRssInterval = undefined;
    },
    addverticePintToMap(latLon, config) {
      if (this.highLightObj.length !== 0) {
        this.clearMap();
      }
      let firstVerticePoint = this.hereUtil.getWaypointMarker(
        latLon.lat,
        latLon.lng,
        config
      );
      this.hereUtil.hereMap.addObject(firstVerticePoint);
      this.highLightObj.push(firstVerticePoint);
    },
    addCoordinateMarkerToMap(latLon) {
      if (this.highLightObj.length !== 0) {
        this.clearMap();
      }
      let coordinates = [latLon.lng, latLon.lat];
      this.highLightObj.push(
        this.hereUtil.addHighlightFeatureToMap(
          this.homeConfig,
          { coordinates: coordinates, type: "Point" },
          false
        )
      );
    },
    addMeasurementFeatureToMap(mapPoints, mode) {
      this.clearMap();
      let coordinates = [];
      mapPoints.forEach((item, i) => {
        coordinates[i] = [item.lng, item.lat];
      });
      let measurementParams = this.hereUtil.addMeasurementbyMode(
        this.homeConfig,
        coordinates,
        mode
      );
      measurementParams.labelsGroup.setVisibility(true);
      this.highLightObj.push(measurementParams.mainGroup);
      this.hereStoreUtil.updateMeasurementValueOnDrag(
        measurementParams,
        this.$store,
        this.hereUtil.hereMap,
        mode,
        this.homeConfig.units
      );
    },
    addAudioIconToMap(coordinates, parentId, childId, title, id, featureType) {
      let audioSvg = this.hereUtil.getAudioSvg(
        this.editFillColorForAudioAndPhotoIcons
      );
      let audioIcon = this.hereStoreUtil.getEditFeatureDomIcon(
        audioSvg,
        parentId,
        childId,
        title,
        this.$store,
        this.editFillColorForAudioAndPhotoIcons,
        id,
        featureType
      );
      this.hereUtil.addAudioToMap(coordinates, audioIcon);
    },
    addCameraIconToMap(coordinates, parentId, childId, title, id, featureType) {
      let cameraSvg = this.hereUtil.getCameraSvg(
        this.editFillColorForAudioAndPhotoIcons
      );
      let cameraIcon = this.hereStoreUtil.getEditFeatureDomIcon(
        cameraSvg,
        parentId,
        childId,
        title,
        this.$store,
        this.editFillColorForAudioAndPhotoIcons,
        id,
        featureType
      );
      this.hereUtil.addCameraToMap(coordinates, cameraIcon);
    },
    addCurrentEditFeaturesToMap(featuresObject) {
      let editsSpecfile = {};
      if (featuresObject.hasOwnProperty("editsSpecfile")) {
        editsSpecfile = featuresObject.editsSpecfile;
      }
      this.hereUtil.removeObjectsFromMap({ removeall: true });
      let pointArray = [];
      let rectboundingBox = null;
      featuresObject.editsData.edit.features.forEach(response => {
        let self = this;
        let editFeature;
        switch (response.name) {
          case "Scribble":
          case "Polyline":
          case "Polygon": {
            editFeature = this.hereUtil.addHighlightFeatureToMap(
              {
                highlight_fill_color: this.editfillColor,
                highlight_stroke_color: this.editfillColor,
                feature: response
              },
              {
                type: response.geometry.type,
                coordinates: response.geometry.coordinates
              },
              false
            );
            break;
          }
          case "LatLonGORedlineNote": {
            pointArray.push({
              lat: response.geometry.coordinates[1],
              lng: response.geometry.coordinates[0]
            });
            editFeature = this.hereUtil.addNoteToMap(
              response.geometry.coordinates,
              response.properties.label,
              this.editfillColor,
              response
            );
            break;
          }
          case "LatLonGORedlineAudioFeature": {
            let audioPoint = {
              lat: response.geometry.coordinates[1],
              lng: response.geometry.coordinates[0]
            };
            pointArray.push(audioPoint);
            this.addAudioIconToMap(
              audioPoint,
              featuresObject.editsData.id,
              response.properties.audio,
              response.properties.title,
              response.properties.audio,
              response.name,
              this.editFillColorForAudioAndPhotoIcons
            );
            break;
          }
          case "LatLonGORedlinePhoto": {
            let photoPoint = {
              lat: response.geometry.coordinates[1],
              lng: response.geometry.coordinates[0]
            };
            pointArray.push(photoPoint);
            this.addCameraIconToMap(
              photoPoint,
              featuresObject.editsData.id,
              response.properties.picture,
              response.properties.title,
              response.properties.picture,
              response.name,
              this.editFillColorForAudioAndPhotoIcons
            );
            break;
          }
          case "Dimension": {
            editFeature = this.addDimensionToMap(response);
            break;
          }
          default: {
            let specStyle = this.getEditsSpecStyle(
              response.name,
              editsSpecfile
            );
            switch (response.geometry.type) {
              case "Point":
              case "LineString":
              case "Polygon": {
                editFeature = this.hereUtil.addHighlightFeatureToMap(
                  {
                    highlight_fill_color: specStyle.fillColor,
                    highlight_stroke_color: specStyle.strokeColor,
                    line_width: specStyle.lineWidth,
                    feature: response
                  },
                  {
                    type: response.geometry.type,
                    coordinates: response.geometry.coordinates
                  },
                  false
                );
                break;
              }
            }
          }
        }
        if (editFeature) {
          let data = {
            feature: response,
            searchType: "editFeature",
            title:
              response.hasOwnProperty("LlgAdmin") && response.LlgAdmin.isSurvey
                ? response.name
                : response.properties.title
          };
          editFeature.setData(data);
          if (response.geometry.type === "Point") {
            pointArray.push({
              lat: response.geometry.coordinates[1],
              lng: response.geometry.coordinates[0]
            });
          } else {
            let editFeatureRect = editFeature.getBoundingBox();
            rectboundingBox =
              rectboundingBox === null
                ? editFeatureRect
                : rectboundingBox.mergeRect(editFeatureRect);
          }
        }
      });
      //Setting bounds to all the features in the edit
      if (rectboundingBox !== null && pointArray.length) {
        rectboundingBox = rectboundingBox.mergeRect(
          H.geo.Rect.coverPoints(pointArray)
        );
      } else if (pointArray.length) {
        rectboundingBox = H.geo.Rect.coverPoints(pointArray);
      }
      if (rectboundingBox !== null) {
        this.isEditBoundSet = this.hereUtil.hereMap
          .getViewModel()
          .setLookAtData({
            bounds: rectboundingBox.getBoundingBox()
          });
      }
      this.$insProgress.finish();
    },
    addDimensionToMap(feature) {
      //code to add polyline to map
      let dimensionLine = this.hereUtil.getDimensionLineString(feature);
      this.hereUtil.hereMap.addObject(dimensionLine);
      //code to add note to map

      let dimensionLabel = this.hereUtil.getDimensionLabel(feature);
      this.hereUtil.hereMap.addObject(dimensionLabel);

      //Arrows looks good if the map is at polyline boundibox
      this.hereUtil.hereMap.getViewModel().setLookAtData({
        bounds: dimensionLine.getBoundingBox()
      });

      //code to add arrowmarks

      let coordinates = feature.geometry.coordinates;

      let point1 = new H.geo.Point(coordinates[1][1], coordinates[1][0]);
      let point2 = new H.geo.Point(coordinates[0][1], coordinates[0][0]);

      let toPoint = this.hereUtil.hereMap.geoToScreen(point1);
      let fromPoint = this.hereUtil.hereMap.geoToScreen(point2);

      this.hereUtil.hereMap.addObject(
        new H.map.Polyline(
          this.hereUtil.getDimensionArrow(toPoint, fromPoint, point1),
          {
            style: { strokeColor: "red" }
          }
        )
      );

      this.hereUtil.hereMap.addObject(
        new H.map.Polyline(
          this.hereUtil.getDimensionArrow(fromPoint, toPoint, point2),
          {
            style: { strokeColor: "red" }
          }
        )
      );
      return dimensionLine;
    },
    getEditsSpecStyle(featurename, editsSpecfile) {
      if (editsSpecfile) {
        let specStyle = {};
        let featureSpecsFile = editsSpecfile.features.find(
          feature => feature.name === featurename
        ).symbology;
        let specdefaultStyle = {
          strokeColor: "rgba(0, 0, 255, 1)",
          fillColor: "rgba(0, 255, 0, 0.4)",
          lineWidth: 2
        };
        if (featureSpecsFile) {
          specStyle = {
            strokeColor: featureSpecsFile.color
              ? this.hereUtil.getRGBColorFormat(featureSpecsFile.color, 1)
              : specdefaultStyle.strokeColor,
            fillColor: featureSpecsFile.color
              ? this.hereUtil.getRGBColorFormat(featureSpecsFile.color, 0.4)
              : specdefaultStyle.fillColor,
            lineWidth: featureSpecsFile.width
              ? featureSpecsFile.width
              : specdefaultStyle.lineWidth
          };
        }
        return specStyle !== {} ? specStyle : specdefaultStyle;
      }
    },
    handleEditTap(evt) {
      this.hereStoreUtil.handleEditTap(evt, this.$store);
    },
    clearEdit() {
      this.hereUtil.removeObjectsFromMap({ removeall: true });
      this.hereUtil.clearAudioMarker();
      this.hereUtil.clearEditsPhotoMarker();
    },
    processGeoRssLayer(layerDetails) {
      this.clearMap();
      let geoRssFeatureDetails = this.hereUtil.processGeoRssLayerDetails(
        layerDetails
      );
      geoRssFeatureDetails.forEach(response => {
        let self = this;
        let geoRssFeature = this.hereUtil.addGeoRssFeatureToMap(
          layerDetails.symbology,
          this.geoRssScale,
          {
            type: response.geoRssFeatureType,
            coordinates: response.geoRssGeometry
          }
        );
        geoRssFeature.addEventListener("tap", function(evt) {
          if (self.appMode !== "layer") {
            self.geoRssCurrentFeature = response;
            self.geoRssCurrentFeature.isGeoRssLayerTap = true;
            if (self.isGeoRssObjectRemoved) {
              self.hereUtil.addPinMarker(
                self.$store,
                self.geoRssLoadingText,
                response,
                true
              );
              self.isGeoRssObjectRemoved = false;
            } else {
              self.hereUtil.updatePopup(
                true,
                self.$store,
                self.geoRssLoadingText,
                response,
                true
              );
              self.hereUtil.removeObjectsFromMap({
                mapObjList: this.highLightObj
              });
            }
            self.$store.dispatch("handleSwitchFromHome", {
              mode: "featurePanel",
              value: {
                searchType: "geoRssFeature",
                feature: response,
                title: response.title,
                hasOtherFeatures: response.length > 1,
                back: response.length > 1 ? { title: "Features" } : null
              }
            });
          }
        });
      });
      if (this.isGeoRssObjectRemoved && this.geoRssCurrentFeature !== "") {
        this.hereUtil.addPinMarker(
          this.$store,
          this.geoRssLoadingText,
          this.geoRssCurrentFeature,
          true
        );
        let highlightObject = this.hereUtil.addGeoRssFeatureToMap(
          {
            highlight_fill_color: this.homeConfig.highlight_fill_color,
            highlight_stroke_color: this.homeConfig.highlight_stroke_color
          },
          this.geoRssScale,
          {
            type: this.geoRssCurrentFeature.point ? "Point" : "Polygon",
            coordinates: this.hereUtil.getCoordsFromString(
              this.geoRssCurrentFeature.point
                ? this.geoRssCurrentFeature.point.__text
                : this.geoRssCurrentFeature.polygon.__text
            )
          }
        );
        this.highLightObj.push(highlightObject);
        this.isGeoRssObjectRemoved = false;
      }
    },
    scaleBarUnitsToggeled(evt) {
      this.$store.commit(
        "home/changeUnitSystemState",
        this.hereUtil.ui.getUnitSystem() === "metric" ? "imperial" : "metric"
      );
    },
    mapChangeEnd(evt) {
      this.$store.commit("home/updateMapPosition", {
        center: this.hereUtil.hereMap.getCenter(),
        bounds: this.hereUtil.getCurrentMapBounds()
      });
      let screen_dpi = 96;
      let widthMetres;
      let widthPixel;
      let lcdPixelDensity;
      // Scale is calculated based on the formulae available for slippy map
      // Check the link http://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Resolution_and_Scale
      widthMetres = evt.target.getViewModel().getLookAtData().bounds.getBoundingBox().getWidth() * 111.32 * 1000;
      widthPixel = window.innerWidth;
      lcdPixelDensity = screen_dpi * 39.3700787;
      this.geoRssScale = (widthMetres / (widthPixel / lcdPixelDensity)).toFixed(0);
      let Scale = (widthMetres / (widthPixel / lcdPixelDensity)).toFixed(0);
      if (Scale >= 10000 && Scale <= 100000) {
        Scale = (Scale / 1000).toFixed(0) + "K";
      } else if (Scale >= 100000) {
        Scale = (Scale / 10000).toFixed(0) + "M";
      }
      this.scale = "1:" + Scale;
      this.zoom = evt.target.getZoom().toFixed(0);
      if (this.isGeorssVisible && Object.keys(this.geoRssLayer).length !== 0) {
        this.isGeoRssObjectRemoved = true;
        this.processGeoRssLayer(this.geoRssLayer);
        if (this.isFeatureClicked) {
          this.hereUtil.addPinMarker(
            this.$store,
            this.featureTappedCoord,
            this.featureResponse
          );
          this.$store.dispatch("handleSwitchFromHome", {
            mode: "featurePanel",
            value: {
              searchType: "Feature",
              feature: this.featureResponse[0],
              hasOtherFeatures: this.featureResponse.length > 1,
              back:
                this.featureResponse.length > 1 ? { title: "Features" } : null
            }
          });
        }
        this.isFeatureClicked = false;
      }
    },
    reverseGeocodeTap(evt) {
      if (evt.pointers[0].button === 2) {
        let coords = this.hereUtil.hereMap.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );
        this.$insProgress.start();
        this.hereUtil.getReverseGeocodeAddress(coords).then(
          response => {
            this.$insProgress.finish();
            if (response.items[0] !== null) {
              this.$store.dispatch("handleSwitchFromHome", {
                mode: "featurePanel",
                value: {
                  searchType: "reverseGeocode",
                  feature: response.items[0],
                  tappedCoord: coords,
                  label: (
                    response.items[0]["title"] +
                    " , " +
                    response.items[0]["vicinity"]
                  ).replace("<br/>", " , ")
                }
              });
            }
          },
          error => {
            this.$insProgress.finish();
            window.getApp.$emit("FLASH_MESSAGE", {
              text: "Cannot load feature details." + error,
              type: "error"
            });
          }
        );
      }
      if (
        this.activeOverlayLayers.length > 0 &&
        evt.pointers[0].button === 0 &&
        !this.hereUtil.markerTapped
      ) {
        let point = {
          x: evt.currentPointer.viewportX,
          y: evt.currentPointer.viewportY
        };
        let tappedCoord = this.hereUtil.hereMap.screenToGeo(point.x, point.y);
        let tolerance = this.hereUtil.getTolerance();
        let tapInfo = {
          activeOverlayLayers: this.activeOverlayLayers,
          zoom: this.hereUtil.hereMap.getZoom(),
          lat: tappedCoord.lat.toString(),
          lon: tappedCoord.lng.toString(),
          tol: tolerance
        };
        if (!this.geoRssCurrentFeature.isGeoRssLayerTap) {
          this.$insProgress.start();
          featuresApi.fecthFeatureInfoAtTappedLocation(tapInfo).then(
            response => {
              this.hereUtil.removeObjectsFromMap({ removeall: true });
              this.highLightObj = [];
              this.$insProgress.finish();
              if (response.features.length > 0) {
                this.isFeatureClicked = true;
                this.featureTappedCoord = tappedCoord;
                this.featureResponse = response.features;
                this.$store.dispatch("handleSwitchFromHome", {
                  mode: "featurePanel",
                  value: {
                    searchType: "Feature",
                    feature: response.features[0],
                    hasOtherFeatures: response.features.length > 1,
                    allFeatures: response.features,
                    tappedCoord: tappedCoord,
                    back:
                      response.features.length > 1
                        ? { title: "Features" }
                        : null
                  }
                });
              } else {
                this.$store.dispatch("handleBack");
              }
            },
            error => {
              this.$insProgress.finish();
              window.getApp.$emit("FLASH_MESSAGE", {
                text: "Error in fetching features at your tapped location.",
                type: "error"
              });
            }
          );
        }
      }
    },
    measurementMapTap(evt) {
      if (evt.pointers[0].button === 0) {
        let latLon = this.hereUtil.hereMap.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );
        this.$store.dispatch("measurement/handleValidatePoint", latLon);
        if (
          this.currentMeasurementMode !== "area" ||
          (this.currentMeasurementMode === "area" && this.isPolygonValid)
        ) {
          this.$store.dispatch(
            "measurement/handleMeasurementMapTapped",
            latLon
          );
        }
      }
    },

    clearMap() {
      this.hereUtil.removeObjectsFromMap({ mapObjList: this.highLightObj });
      this.highLightObj.splice(0);
    },
    handleMeasurementMapTapped(mode, points) {
      if (points.length === 0) {
        this.clearMap();
      } else if (mode === "coordinate") {
        this.addCoordinateMarkerToMap(points[points.length - 1]);
      } else {
        if (points.length === 1) {
          this.addverticePintToMap(points[0], this.homeConfig);
        } else if (points.length === 2 || mode == "distance") {
          this.addMeasurementFeatureToMap(points);
        } else {
          this.addMeasurementFeatureToMap(points, mode);
        }
      }
    },
    onOverlayClick(evt) {
      this.$store.dispatch("handleBack");
    },
    addRouteToMap(route, index) {
      let self = this;
      //This z-index values are taken to keep the higher z-index for highlighted route.
      let referenceZindex = 50;
      let updateReferenceZindex = 200;
      let highlightRoute = index === 0;
      let routePolyline = this.hereUtil.addRouteShapeToMap(
        this.homeConfig,
        route,
        highlightRoute
      );
      let routes = self.highLightObj;
      routePolyline[0].setData(index);
      routePolyline[0].addEventListener("tap", evt => {
        //Variables for route color, previous selected route, current selected route
        let previousRouteIndex = self.$store.state.routing.selectedRouteIndex;
        let strokeColor = self.$store.state.home.config.highlight_stroke_color;
        let fillColor = self.$store.state.home.config.highlight_fill_color;
        let currentRouteIndex = evt.currentTarget.getData();

        //Updating the highlight color and z-index for current and previous routes
        self.hereUtil.updateRouteStyle(
          routes[2 * previousRouteIndex],
          routes[2 * previousRouteIndex + 1],
          strokeColor,
          3,
          previousRouteIndex
        );
        self.$store.commit(
          "routing/changeSelectedRouteIndex",
          currentRouteIndex
        );
        self.hereUtil.updateRouteStyle(
          routes[2 * currentRouteIndex],
          routes[2 * currentRouteIndex + 1],
          fillColor,
          5,
          currentRouteIndex + updateReferenceZindex
        );
      });

      let manueversGroup = this.hereUtil.addManueversToMap(
        this.homeConfig,
        route,
        highlightRoute
      );
      this.highLightObj.push(manueversGroup);
      routePolyline.forEach(response => {
        this.highLightObj.push(response);
      });

      //Setting initial z-index for route and manuers. zero-index has higher z-index
      routePolyline[0].setZIndex(referenceZindex - index);
      manueversGroup.setZIndex(referenceZindex - index);
    },
    editMapViewChangeEnd(evt) {
      if (this.isEditBoundSet) {
        this.isEditBoundSet = false;
      } else {
        setTimeout(() => {
          this.$store.dispatch("edit/handleFetchEditsInBoundingBox");
        }, 3000);
      }
    },
    disableMapDragtoDrawHomeBounds(evt) {
      this.homeBoundsRect = null;
      if (this.isShiftKeyPressed) {
        this.hereUtil.behavior.disable(H.mapevents.Behavior.DRAGGING);
        this.lineString
          .getLatLngAltArray()
          .splice(0, this.lineString.getPointCount() * 3);
        // Not missing the first point as well.
        this.lineString.pushPoint(
          this.hereUtil.hereMap.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY
          )
        );
        let bounds = this.lineString.getBoundingBox();
        let geoRect = new H.geo.Rect(
          bounds.getTop(),
          bounds.getLeft(),
          bounds.getBottom(),
          bounds.getRight()
        );
        this.homeBoundsRect = new H.map.Rect(geoRect);
        this.homeBoundsRect.setStyle({
          fillColor: "rgba(128, 255, 255, 0.5)",
          strokeColor: "rgba(0, 230, 230, 1)",
          lineWidth: "1"
        });
        this.group = new H.map.Group({
          volatility: true,
          objects: [this.homeBoundsRect]
        });
        this.hereUtil.hereMap.addObject(this.group);
      }
    },
    drawHomeBounds(evt) {
      if (this.isShiftKeyPressed) {
        let coords = this.hereUtil.hereMap.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );
        this.latLonValues = `${coords.lat.toFixed(5)} ,  ${coords.lng.toFixed(
          5
        )}`;
        this.lineString.pushPoint(coords);
        this.homeBoundsRect.setBoundingBox(this.lineString.getBoundingBox());
        this.lineString.getLatLngAltArray().splice(2, 3);
      }
    },
    enableMapDragtoDrawHomeBounds(evt) {
      if (!this.hereUtil.behavior.isEnabled(H.mapevents.Behavior.DRAGGING)) {
        this.hereUtil.behavior.enable(H.mapevents.Behavior.DRAGGING);
      }
      if (this.homeBoundsRect) {
        this.homeBoundsRect.setStyle({
          fillColor: "rgba(255, 194, 102, 0.4)",
          strokeColor: "rgba(255, 153, 0, 1)"
        });
        let boundingBox = this.homeBoundsRect.getBoundingBox();
        let tolerance = this.hereUtil.getTolerance();
        let featureDetails = {
          activeOverlayLayers: this.activeOverlayLayers,
          zoom: this.hereUtil.hereMap.getZoom(),
          tol: tolerance,
          bounds:
            boundingBox.getLeft() +
            "," +
            boundingBox.getBottom() +
            "," +
            boundingBox.getRight() +
            "," +
            boundingBox.getTop()
        };
        this.$insProgress.start();
        featuresApi
          .findFeatureByBoundingBox(featureDetails)
          .then(response => {
            if (response.features.length > 0) {
              this.isFeatureClicked = true;
              this.featureResponse = response.features;
              this.$store.dispatch("handleSwitchFromHome", {
                mode: "featurePanel",
                value: {
                  searchType: "Feature",
                  feature: response.features,
                  showMultiplePins: true,
                  title: "Features"
                }
              });
            } else {
              this.$store.dispatch("handleBack");
            }
          })
          .finally(() => {
            this.group.removeAll();
            this.$insProgress.finish();
          });
      }
    },
    handleMouseDown(evt) {
      this.isShiftKeyPressed = evt.shiftKey;
    }
  }
};
</script>

<style>
#mapDiv {
  display: flex;
}
#baseLayerbtn {
  position: absolute;
  z-index: 1;
  margin: 32px 0px 0px 10px;
}
#rssLayersLoadingMsg {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  margin: 32px 0px 0px 10px;
  bottom: 10%;
  font-size: 15px;
  color: grey;
}
.v-menu__content {
  background-color: white;
}
.radio-set-margin {
  margin-left: 12px !important;
}
.container {
  padding: 0px;
  max-width: 100%;
  z-index: 0;
}
.map-row {
  height: 100%;
  /* margin-left: 0px !important;
  margin-right: 0px !important; */
}
.mapDivClass {
  width: 100%;
  height: 100%;
  position: absolute;
}
.v-progress-circular {
  margin: 1rem;
}
#map-zoom-and-scale {
  position: absolute;
  bottom: 60px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
}
.geoRssLoadingColor {
  color: black !important;
}
.printMe {
  display: none;
  z-index: 0;
}
@media print {
  .printMe {
    display: none;
  }
}
@-moz-document url-prefix() {
  @media print {
    .printMe {
      position: relative;
      display: block;
      overflow: hidden;
    }
  }
}
</style>
