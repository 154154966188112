import apiLayer from "@/api/layer";
import HereUtil from "@/util/here";

const layer = {
  namespaced: true,
  state: {
    isLayerWindow: false,
    baseLayers: [],
    overlayLayers: [],
    OVERLAY_LAYER_TYPES: [
      "georss",
      "here.traffic",
      "here.congestion",
      "here.traffic.historical",
      "wmts.overlay",
      "tpi.overlay",
      "here.poi",
      "here.street.coverage",
      "latlongo.mvt",
      "arcgis.mvt",
      "offline.overlay",
      "hybrid.overlay"
    ],
    OFFLINE_LAYER_TYPES: [
      "offline.overlay",
      "offline.base",
      "hybrid.overlay",
      "hybrid.base",
      "ecw.base",
      "jpeg2000",
      "bing.maps"
    ]
  },

  mutations: {
    changeLayerVisibility(state, layer) {
      if (layer.isBaseLayer) {
        state.baseLayers.forEach((aLayer, i) => {
          state.baseLayers[i].isVisible = false;
          if (aLayer.id === layer.id) {
            state.baseLayers[i].isVisible = true;
          }
        });
      }
    },
    changeLayerWindowState(state) {
      state.isLayerWindow = !state.isLayerWindow;
    },
    changeNewLayers(state) {
      state.baseLayers.splice(0, state.baseLayers.length);
      state.overlayLayers.splice(0, state.overlayLayers.length);
    },
    changeNewLayer(state, layerDetail) {
      if (layerDetail.isBaseLayer) {
        state.baseLayers.push(layerDetail);
      } else {
        state.overlayLayers.push(layerDetail);
      }
    }
  },

  getters: {
    getOverlayLayerId: state => {
      return state.overlayLayers
        .reduce((idList, layer) => {
          idList.push(layer.id);
          return idList;
        }, [])
        .join(",");
    },
    isPoiLayerVisible: state => {
      return (
        state.overlayLayers.find(
          layer => layer.type.includes("poi") && layer.isVisible
        ) !== undefined
      );
    },
    isGeorssLayerVisible: state => {
      return (
        state.overlayLayers.find(
          layer => layer.type.includes("georss") && layer.isVisible
        ) !== undefined
      );
    },
    getActiveOverlayLayerId: state => {
      return state.overlayLayers.reduce((idList, layer) => {
        if (layer.isVisible) {
          idList.push(layer.id);
        }
        return idList;
      }, []);
    }
  },

  actions: {
    handleGoToLayerWindow({ commit }) {
      commit("changeLayerWindowState");
    },
    handleExitMyMode({ commit }) {
      commit("changeLayerWindowState");
    },
    handleReloadLayers({ state, commit, rootState }, payload) {
      commit("changeNewLayers");
      let configLayers = rootState.home.config.layers;
      configLayers.forEach(layer => {
        let configLayer = configLayers.find(configLayer => {
          return configLayer.id === layer.id;
        });
        layer["isVisible"] = configLayer.isVisible;
        layer["isBaseLayer"] =
          state.OVERLAY_LAYER_TYPES.indexOf(layer.type) === -1;
        layer["isHereLayer"] = layer.type.indexOf("here") > -1;
        layer["iswmts"] = layer.type.indexOf("wmts") > -1;
        layer["isOfflineLayer"] =
          state.OFFLINE_LAYER_TYPES.indexOf(layer.type) > -1;
        if (layer.isBaseLayer) {
          layer["maxZoomLevel"] = layer["max_zoom_level"];
        }
        layer["layerInfo"] = Object.assign([], configLayer);

        //process symbology for georss layer if not present
        if (layer.type === "georss") {
          let hereUtil = new HereUtil();
          layer.symbology = hereUtil.getSymbology(layer);
        }

        // Consider Permalink for overlay layer
        if (
          !layer.isBaseLayer &&
          rootState.home.permalink &&
          Array.isArray(rootState.home.permalink.overlay)
        ) {
          // Check if permalink overlay value is present and if yes then adhere to that.
          if (Array.isArray(rootState.home.permalink.overlay)) {
            layer.isVisible =
              rootState.home.permalink.overlay.indexOf(layer.id) >= 0;
          }
        } else if (
          !layer.isBaseLayer &&
          rootState.home.permalink &&
          !rootState.home.permalink.overlay
        ) {
          layer.isVisible = false;
        }
        commit("changeNewLayer", layer);
      });

      // Consider Permalink base layer
      if (rootState.home.permalink && rootState.home.permalink.base) {
        let permalinkBaseLayer = state.baseLayers.find(layer => {
          return layer.id === rootState.home.permalink.base;
        });
        if (permalinkBaseLayer) {
          commit("changeLayerVisibility", permalinkBaseLayer);
        } else {
          // raise a warning that base layer don't exist.
          window.getApp.$emit("FLASH_MESSAGE", {
            text:
              "Base layer included in permalink is not present in current user's configuration.",
            type: "warning"
          });
        }
      }
      if (payload.useGeoLocation) {
        let hereUtil = new HereUtil();
        hereUtil.setMapToMaxZoom(state.baseLayers);
      }
      // });
    },
    toggleLayerIfOff({ state, commit }, layerId) {
      let layer = state.overlayLayers.find(layer => layer.id === layerId);
      if (!layer.isVisible) {
        layer.isVisible = !layer.isVisible;
        commit("changeLayerVisibility", layer);
      }
    }
  }
};
export default layer;
