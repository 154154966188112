// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }
const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const kebab = str => {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

const toggleFullScreen = () => {
  let doc = window.document;
  let docEl = doc.documentElement;

  let requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  let cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

// Changes XML to JSON
const xmlToJson = xml => {
  // Create the return object
  let obj = {};

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      // obj["attributes"] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        let attribute = xml.attributes.item(j);
        // obj["attributes"][attribute.nodeName] = attribute.nodeValue;
        obj[attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue.trim(); // add trim here
  }

  // do children
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      let item = xml.childNodes.item(i);
      let nodeName = item.nodeName;
      if (typeof obj[nodeName] === "undefined") {
        let tmp = xmlToJson(item);
        if (tmp !== "") {
          // if not empty string
          obj[nodeName] = tmp;
        }
      } else {
        if (typeof obj[nodeName].push === "undefined") {
          let old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        let tmp = xmlToJson(item);
        if (tmp !== "") {
          // if not empty string
          obj[nodeName].push(tmp);
        }
      }
    }
  }
  return obj;
};

const jsonToWmts = jsonWmts => {
  let foundLayers = [];
  if (
    jsonWmts &&
    jsonWmts.Capabilities &&
    jsonWmts.Capabilities.Contents.Layer
  ) {
    let layersArray = Array.isArray(jsonWmts.Capabilities.Contents.Layer)
      ? jsonWmts.Capabilities.Contents.Layer
      : [jsonWmts.Capabilities.Contents.Layer];
    layersArray.forEach(wmtsLayer => {
      let newLayer = {};

      // Fetching Title.
      if (wmtsLayer["ows:Title"] && wmtsLayer["ows:Title"]["#text"]) {
        newLayer["Title"] = wmtsLayer["ows:Title"]["#text"];
      }

      // Fetching Identifier.
      if (wmtsLayer["ows:Identifier"] && wmtsLayer["ows:Identifier"]["#text"]) {
        newLayer["Identifier"] = wmtsLayer["ows:Identifier"]["#text"];
      }

      // Fetching ResourceURL.
      if (wmtsLayer["ResourceURL"] && wmtsLayer["ResourceURL"]["template"]) {
        newLayer["ResourceURL"] = wmtsLayer["ResourceURL"]["template"];
      }

      // Fetching Format as array.
      if (wmtsLayer["Format"]) {
        if (!Array.isArray(wmtsLayer["Format"])) {
          newLayer["Format"] = [];
          newLayer["Format"].push(wmtsLayer["Format"]["#text"]);
        }
      }

      // Fetching Style as array.
      if (
        wmtsLayer["Style"] &&
        wmtsLayer["Style"]["ows:Identifier"] &&
        wmtsLayer["Style"]["ows:Identifier"]["#text"]
      ) {
        newLayer["Style"] = [];
        newLayer["Style"].push(wmtsLayer["Style"]["ows:Identifier"]["#text"]);
      }

      // Fetching TileMatrixSet as array.
      if (
        wmtsLayer["TileMatrixSetLink"] &&
        wmtsLayer["TileMatrixSetLink"]["TileMatrixSet"] &&
        wmtsLayer["TileMatrixSetLink"]["TileMatrixSet"]["#text"]
      ) {
        newLayer["TileMatrixSet"] = [];
        newLayer["TileMatrixSet"].push(
          wmtsLayer["TileMatrixSetLink"]["TileMatrixSet"]["#text"]
        );
      }

      if (
        wmtsLayer["TileMatrixSetLink"] &&
        wmtsLayer["TileMatrixSetLink"].length
      ) {
        newLayer["TileMatrixSet"] = [];
        newLayer["TileMatrixSet"] = wmtsLayer["TileMatrixSetLink"]
          .filter(matrixset => {
            return (
              matrixset["TileMatrixSet"] && matrixset["TileMatrixSet"]["#text"]
            );
          })
          .map(matrixset => {
            return matrixset["TileMatrixSet"]["#text"];
          });
      }
      foundLayers.push(newLayer);
    });
  }

  return foundLayers;
};

const jsonToRss = jsonRss => {
  return jsonRss && (jsonRss.rss || jsonRss.feed) ? jsonRss : null;
};

const formatDate = date => {
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear()
  );
};

export default {
  randomElement,
  toggleFullScreen,
  kebab,
  xmlToJson,
  jsonToWmts,
  jsonToRss,
  formatDate
};
