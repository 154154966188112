export default [
  {
    name: "FLASH_MESSAGE",
    callback: function(e) {
      e.timeout = 0;
      e.show = true;
      if (e.type) {
        switch (e.type) {
          case "error":
            e.color = "#F44336"; //red
            e.icon = "mdi-alert";
            break;
          case "warning":
            e.color = "#FF9800"; //orange
            e.icon = "mdi-exclamation-thick";
            break;
          case "info":
            e.color = "#2196F3"; //blue
            e.icon = "mdi-information";
            e.timeout = 6000;
            break;
          case "success":
            e.color = "#4CAF50"; //green
            e.icon = "mdi-checkbox-marked-circle";
            e.timeout = 3000;
            break;
          default:
            e.color = "#F44336"; //red
            e.icon = "mdi-alert";
        }
      }
      window.getApp.snackbar = e;
    }
  },
  {
    name: "CLOSE_FLASH_MESSAGE",
    callback: function(e) {
      if (window.getApp.snackbar.show) {
        window.getApp.snackbar.show = false;
      }
    }
  }
];
