import apiLogin from "@/api/login";

const home = {
  namespaced: true,
  state: {
    config: {
      blackline_specifications: [],
      buttons: [],
      device_location_update_frequency: 0,
      forms: [],
      here_licenses: {
        js_rest: {
          apikey: ""
        }
      },
      highlight_fill_color: "#FFE400",
      highlight_stroke_color: "#FFE400",
      home: {
        layers: [],
        region: [],
        useGeoLocation: false
      },
      id: 1,
      layers: [],
      name: "",
      sync: {
        cellularDownloadLimit: 0,
        network: "wifi",
        period: 60
      },
      units: "imperial"
    },
    mapCenter: { lng: 5, lat: 50 },
    permalink: undefined,
    bounds: {}
  },

  mutations: {
    parseConfig(state, config) {
      state.config = config;
    },
    changeUnitSystemState(state, unit) {
      state.config.units = unit;
    },
    updateMapPosition(state, { center, bounds }) {
      state.mapCenter = center;
      state.bounds = bounds;
    },
    changePermalink(state, permalink) {
      state.permalink = permalink;
    }
  },

  actions: {
    handlePermalinkDetails({ rootState, dispatch }, linkInfo) {
      // Fetch each information of permalink and overwrite state.config
      let permalink = {};
      permalink["base"] = linkInfo.get("base");
      // Set overlay layer ids.
      let overlayLayers = linkInfo.get("overlay");
      if (overlayLayers) {
        permalink["overlay"] = overlayLayers.split(",");
      }
      // Set zoom value.
      let zoom = linkInfo.get("zoom");
      if (zoom) {
        permalink["zoom"] = parseFloat(zoom).toFixed(2);
      }
      // Set center value.
      let coordinates = linkInfo.get("center");
      // string should contain numbers and special characters .,-
      let regex = /^[0-9,.-]+$/;
      if (coordinates === "location") {
        if (rootState.setting.isMockGpsSwitchOn) {
          dispatch("handlePermalinkCenter", {
            permalink: permalink,
            floatCoords: rootState.setting.mockLocation
          });
        } else {
          navigator.geolocation.getCurrentPosition(
            position => {
              if (position.coords.accuracy < 5000) {
                dispatch("handlePermalinkCenter", {
                  permalink: permalink,
                  floatCoords: [
                    position.coords.latitude,
                    position.coords.longitude
                  ]
                });
              } else {
                dispatch("handlePermalinkCenter", {
                  permalink: permalink,
                  floatCoords: undefined
                });
                window.getApp.$emit("FLASH_MESSAGE", {
                  text: "Unable to retrive information.",
                  type: "error"
                });
              }
            },
            error => {
              console.log(error);
              dispatch("handlePermalinkCenter", {
                permalink: permalink,
                floatCoords: undefined
              });
              let locationErrorMessage =
                error.code === error.TIMEOUT
                  ? "Location timeout error."
                  : error.code === error.POSITION_UNAVAILABLE
                  ? "Location unavailable."
                  : error.code === error.PERMISSION_DENIED
                  ? error.message.startsWith("Only secure origins are allowed")
                    ? "Only secure origins are allowed."
                    : "Location permission denied!"
                  : "Unknown location error.";
              window.getApp.$emit("FLASH_MESSAGE", {
                text: locationErrorMessage,
                type: "error"
              });
            },
            {
              maximumAge: 0,
              timeout: 1000,
              enableHighAccuracy: false
            }
          );
        }
      } else if (coordinates && regex.test(coordinates)) {
        let floatCoords = coordinates.split(",");
        floatCoords = floatCoords.reduce((coords, coord) => {
          coords.push(parseFloat(coord));
          return coords;
        }, []);
        dispatch("handlePermalinkCenter", {
          permalink: permalink,
          floatCoords: floatCoords
        });
      } else {
        dispatch("handlePermalinkCenter", {
          permalink: permalink,
          floatCoords: undefined
        });
      }
    },
    handlePermalinkCenter({ commit }, payload) {
      payload.permalink["center"] = payload.floatCoords;
      commit("changePermalink", payload.permalink);
    },
    handleGoToHomeMode({ state, commit, dispatch, rootState }) {
      // Fetch the configuration
      apiLogin.getConfigDetails(rootState.configId).then(response => {
        // Filtering the featurelinks
        response.data.buttons = response.data.buttons.filter(
          button => button.url_web.length > 0
        );

        // TODO: Validate data.
        commit("parseConfig", response.data);
        dispatch(
          "layer/handleReloadLayers",
          { useGeoLocation: response.data.home.useGeoLocation },
          { root: true }
        );
      });
      // Geolocate must be turned off when clicked on home
      if (rootState.geolocation.geolocate !== "off") {
        dispatch("geolocation/handleGeolocation", "off", { root: true });
      }
      // Prepare the home module & switch to home mode.
      dispatch("handleBack", null, { root: true });
    }
  }
};
export default home;
