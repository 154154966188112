const search = {
  namespaced: true,
  state: {
    mapcodeResults: [],
    featureResults: [],
    addressResults: [],
    searchQuery: null
  },
  mutations: {
    updateAddressResults(state, addressResults) {
      state.addressResults = addressResults;
    },
    updateMapcodeResults(state, mapcodeResults) {
      state.mapcodeResults = mapcodeResults;
    },
    updateFeatureResults(state, featureResults) {
      state.featureResults = featureResults;
    },
    updateSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
    clearSearchResults(state) {
      state.mapcodeResults = [];
      state.featureResults = [];
      state.addressResults = [];
    }
  },
  getters: {
    searchResults(state) {
      return [
        ...state.mapcodeResults,
        ...state.featureResults,
        ...state.addressResults
      ];
    }
  },
  actions: {
    handleExitMyMode({ commit }) {
      commit("clearSearchResults");
      commit("updateSearchQuery", null);
    }
  }
};
export default search;
