<template>
  <div app :dark="$vuetify.dark">
    <v-container>
      <v-row dense align-content="stretch">
        <v-col  cols="7" sm="12">
          <v-card>
            <v-toolbar flat class="reduceHeight">
              <v-toolbar-title>{{ $t("Overlay Layers") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-switch
                class="inLineWithTitle"
                v-model="isOverlayLayerInfoList"
                label="Info"
              >
              </v-switch>
            </v-toolbar>
            <vue-perfect-scrollbar
              class="chat-room--scrollbar"
              v-bind:style="{
                'min-height': '1vh',
                'max-height': maxHeightOverlay
              }"
            >
              <v-list v-if="!isOverlayLayerInfoList" class="noTopMarginPadding">
                <v-list-item-group>
                  <template v-for="item in overlay">
                    <v-list-item class="v.list-item-height" :key="item.name">
                      <v-list-item-avatar size="24px" class="iconRadius">
                        <v-img
                          v-if="item.isHereLayer"
                          src="@/assets/icon_here.png"
                        />
                        <v-img
                          v-else-if="item.iswmts"
                          src="@/assets/icon_wmts.png"
                        />
                        <v-img v-else src="@/assets/icon_latlongo.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          v-model="item.isVisible"
                          @change="toggleLayerVisibility(item)"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-list v-else>
                <v-list-item-group>
                  <template v-for="item in overlay">
                    <v-list-item
                      class="v.list-item-height"
                      :key="item.id"
                      v-if="
                        item.layerInfo.extractor ||
                          item.isOfflineLayer === false
                      "
                    >
                      <td>
                        <v-avatar size="24px" class="iconRadius">
                          <v-img
                            v-if="item.isHereLayer"
                            src="@/assets/icon_here.png"
                          />
                          <v-img
                            v-else-if="item.iswmts"
                            src="@/assets/icon_wmts.png"
                          />
                          <v-img v-else src="@/assets/icon_latlongo.png" />
                        </v-avatar>
                      </td>
                      <td
                        class="padding-item-name"
                        v-if="item.layerInfo.extractor"
                      >
                        <p class="property-layer-name">
                          <strong>{{ item.name }}</strong>
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Version") + ":" }}
                          {{ item.layerInfo.version || 0 }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Source") + ":" }}
                          {{ item.layerInfo.source || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Extractor") + ":" }}
                          {{ item.layerInfo.extractor || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Extracted") + ":" }}
                          {{ item.layerInfo.extracted || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Updated") + ":" }}
                          {{ item.layerInfo.updated || "Never Updated" }}
                        </p>
                      </td>
                      <td v-else class="padding-item-name">
                        <p class="property-layer-name">
                          <strong>{{ item.name }}</strong>
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Online Layer") }}
                        </p>
                      </td>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </vue-perfect-scrollbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="7" sm="12">
          <v-card>
            <v-toolbar flat class="reduceHeight">
              <v-toolbar-title>{{ $t("Base Layers") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-switch
                class="inLineWithTitle"
                v-model="isBaseLayerInfoList"
                label="Info"
              ></v-switch>
            </v-toolbar>
            <vue-perfect-scrollbar
              class="chat-room--scrollbar"
              v-bind:style="{
                'min-height': '1vh',
                'max-height': maxHeightBase
              }"
            >
              <v-list v-if="!isBaseLayerInfoList" class="noTopMarginPadding">
                <v-radio-group
                  v-model="selectedItem"
                  class="noTopMarginPadding"
                >
                  <template v-for="item in base">
                    <v-list-item class="v.list-item-height" :key="item.id">
                      <v-list-item-avatar size="24px" class="iconRadius">
                        <v-img
                          v-if="item.isHereLayer"
                          src="@/assets/icon_here.png"
                        />
                        <v-img
                          v-else-if="item.iswmts"
                          src="@/assets/icon_wmts.png"
                        />
                        <v-img v-else src="@/assets/icon_latlongo.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-radio
                          color="primary"
                          :value="item.name"
                          @change="toggleLayerVisibility(item)"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-radio-group>
              </v-list>
              <v-list v-else>
                <v-list-item-group>
                  <template v-for="item in base">
                    <v-list-item
                      class="v.list-item-height"
                      :key="item.id"
                      v-if="
                        item.layerInfo.extractor ||
                          item.isOfflineLayer === false
                      "
                    >
                      <td>
                        <v-avatar size="24px" class="iconRadius">
                          <v-img
                            v-if="item.isHereLayer"
                            src="@/assets/icon_here.png"
                          />
                          <v-img
                            v-else-if="item.iswmts"
                            src="@/assets/icon_wmts.png"
                          />
                          <v-img v-else src="@/assets/icon_latlongo.png" />
                        </v-avatar>
                      </td>
                      <td
                        class="padding-item-name"
                        v-if="item.layerInfo.extractor"
                      >
                        <p class="property-layer-name">
                          <strong>{{ item.name }}</strong>
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Version") + ":" }}
                          {{ item.layerInfo.version || 0 }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Source") + ":" }}
                          {{ item.layerInfo.source || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Extractor") + ":" }}
                          {{ item.layerInfo.extractor || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Extracted") + ":" }}
                          {{ item.layerInfo.extracted || "Unknown" }}
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Updated") + ":" }}
                          {{ item.layerInfo.updated || "Never Updated" }}
                        </p>
                      </td>
                      <td v-else class="padding-item-name">
                        <p class="property-layer-name">
                          <strong>{{ item.name }}</strong>
                        </p>
                        <p class="property-layer-attribute">
                          {{ $t("Online Layer") }}
                        </p>
                      </td>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </vue-perfect-scrollbar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "layer",
  data() {
    return {
      isBaseLayerInfoList: false,
      isOverlayLayerInfoList: false,
      maxHeightBase: "30vh",
      maxHeightOverlay: "30vh"
    };
  },
  computed: {
    ...mapState({
      base: state => state.layer.baseLayers,
      overlay: state => state.layer.overlayLayers
    }),

    selectedItem: {
      get() {
        let item = "";
        for (let i = 0; i < this.base.length; i++) {
          if (this.base[i].isVisible) {
            item = this.base[i].name;
            break;
          }
        }
        return item;
      },
      set() {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  created() {
    console.log("...Layer panel created.");
  },
  methods: {
    toggleLayerVisibility(layer) {
      this.$store.commit("layer/changeLayerVisibility", layer);
    }
  }
};
</script>

<style>
p.property-layer-attribute,
p.property-layer-name {
  margin-bottom: 0px;
}
p.property-layer-attribute {
  font-size: 10pt;
}
.v-application p {
  margin-bottom: 0px;
}
.no-info-padding {
  padding-left: 130px;
  padding-bottom: 0px;
}
.padding-item-name {
  padding: 0px 10px 10px 25px;
}
.v.list-item-height {
  min-height: 0px;
}
.iconRadius {
  border-radius: 0px;
}
.inLineWithTitle {
  padding-top: 15px !important;
}
.reduceHeight {
  height: 50px !important;
}
.noTopMarginPadding {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
