import { api, basePath } from "@/api/index";
const featuresLimit = 25;
const findMapcodeFromQuery = function(queryString) {
  return api
    .get(basePath + "services/mapcodes/" + queryString)
    .then(response => {
      return response.data;
    });
};

const findFeatureFromQuery = function(queryString, ids) {
  return api
    .get(basePath + "layers/" + ids + "/features?q=" + queryString)
    .then(response => {
      response.data.features = response.data.features.splice(0, featuresLimit);
      return response.data;
    });
};

const findFeatureByBoundingBox = function(urlInfo) {
  let requestUrl =
    basePath +
    "layers/" +
    urlInfo.activeOverlayLayers +
    "/features?" +
    "bounds=" +
    urlInfo.bounds;
  return api.get(requestUrl).then(response => response.data);
};

const findAddressFromQuery = function(queryString, hereApiKey, center) {
  let url =
    "https://places.ls.hereapi.com/places/v1/discover/search?at=" +
    center.lat +
    "," +
    center.lng +
    "&q=" +
    queryString +
    "&apiKey=" +
    hereApiKey;
  return api
    .get(url, {
      transformRequest: (data, headers) => {
        delete headers["LatLonGO-API-Key"];
      }
    })
    .then(response => {
      return response.data.results.items;
    });
};

const fetchAddressInfoForAddress = function(url) {
  return api
    .get(url, {
      transformRequest: (data, headers) => {
        delete headers["LatLonGO-API-Key"];
      }
    })
    .then(response => {
      return response.data;
    });
};

const fetchJoinFeature = function(
  currentFeature,
  joinFeaturesMetadata,
  currentTitle
) {
  let featurePromises = [];
  joinFeaturesMetadata.features.forEach(feature =>
    featurePromises.push(
      fetchJointFeatureData(currentFeature.layer, feature.id)
    )
  );
  return Promise.all(featurePromises);
};

const fetchJointFeatureData = function(layerId, featureId) {
  let requestUrl = basePath + "layers/" + layerId + "/features/" + featureId;
  return api.get(requestUrl).then(response => {
    return response.data;
  });
};

const fecthFeatureInfoAtTappedLocation = function(urlInfo) {
  let requestUrl =
    basePath +
    "layers/" +
    urlInfo.activeOverlayLayers +
    "/features?z=" +
    urlInfo.zoom +
    "&lat=" +
    urlInfo.lat +
    "&lon=" +
    urlInfo.lon +
    "&tol=" +
    urlInfo.tol;
  return api.get(requestUrl).then(
    response => {
      return response.data;
    },
    error => {
      throw error;
    }
  );
};

export default {
  fetchAddressInfoForAddress,
  findMapcodeFromQuery,
  findFeatureFromQuery,
  findAddressFromQuery,
  fetchJoinFeature,
  fecthFeatureInfoAtTappedLocation,
  findFeatureByBoundingBox
};
