const geolocation = {
  namespaced: true,
  state: {
    // Possible values are off / follow / not-follow
    geolocate: "off",
    loading: false,
    clearWatchId: -1,
    position: {
      coords: {
        accuracy: null,
        latitude: null,
        longitude: null
      }
    },
    retryLimit: 5,
    retryCount: 0,
    isLocationAccurate: true
  },
  getters: {
    color(state) {
      return state.geolocate === "off"
        ? "white"
        : state.geolocate === "follow"
        ? "yellow"
        : "springGreen";
    },
    animation(state) {
      return state.isLocationAccurate === false && state.geolocate !== "off"
        ? "blink 0.5s steps(5, start) infinite"
        : "none";
    }
  },
  mutations: {
    changeGeolocate(state, payload) {
      state.geolocate = payload;
    },
    changeLoadingStatus(state, payload) {
      state.loading = payload;
    },
    changeClearWatchId(state, payload) {
      state.clearWatchId = payload;
    },
    changePosition(state, payload) {
      state.position = payload;
    },
    changeRetryCount(state, payload) {
      state.retryCount = payload;
    },
    changeIsLocationAccurate(state, payload) {
      state.isLocationAccurate = payload;
    }
  },
  actions: {
    handleGeolocation({ state, commit, rootState, dispatch }, payLoad) {
      if (rootState.appMode === "featurePanel") {
        dispatch ("handleBack", null, {root:true});
      }
      commit("changeGeolocate", payLoad);
      if (payLoad === "off") {
        commit("changeLoadingStatus", false);
        commit("changePosition");
        clearInterval(state.clearWatchId);
        commit("changeClearWatchId", -1);
      } else if (["follow", "not-follow"].indexOf(payLoad) >= 0) {
        if (state.clearWatchId < 0) {
          commit("changeLoadingStatus", true);
          commit("changeClearWatchId", setInterval(() => { 
            dispatch("handleGetLocation");
          }, 3000));
        }
      }
    },
    handleGetLocation({ rootState, state, commit }) {
      if (rootState.setting.isMockGpsSwitchOn) {
        commit("changePosition", rootState.setting.mockLocation);
        commit("changeLoadingStatus", false);
      } else {
        navigator.geolocation.getCurrentPosition(position => {
          commit("changeLoadingStatus", false);
          commit("changePosition", position);
          commit("changeRetryCount", 0);
        }, error => {
          commit("changeLoadingStatus", false);
          commit("changeRetryCount",
            error.code === error.PERMISSION_DENIED
              ? state.retryLimit
              : state.retryCount + 1
          );
          if (state.retryCount >= state.retryLimit) {
            clearInterval(state.clearWatchId);
            commit("changeClearWatchId", -1);
            commit("changeGeolocate", "off");
            commit("changePosition");
            let locationErrorMessage = error.code === error.TIMEOUT ? "Location timeout error." : 
              error.code === error.POSITION_UNAVAILABLE ? "Location unavailable." : 
                error.code !== error.PERMISSION_DENIED ? "Unknown location error." :
                  error.message.startsWith("Only secure origins are allowed") ? "Only secure origins are allowed." : 
                    "Location permission denied!";
            window.getApp.$emit("FLASH_MESSAGE", {
              text: locationErrorMessage,
              type: "error"
            });
          }
        }, { maximumAge: 1000, timeout: 2000, enableHighAccuracy: false } );
      }
    }
  }
};
export default geolocation;
