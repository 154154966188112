<template>
  <v-app-bar app dense clipped-left color="primary">
    <v-container>
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-tooltip :disabled="isShowTooltip" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                dark
                name="NavigationMenu"
                v-on="on"
                @click.stop="handleMenu"
              >
                <v-icon>{{ menuIcon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(menuTooltip) }}</span>
          </v-tooltip>
        </v-col>
        <v-col class="app-title">
          <v-subheader dark>LatLonGO</v-subheader>
        </v-col>
        <v-col cols="auto" class="infoText">
          <v-subheader dark v-html="userName" />
          <v-subheader dark v-html="latlongoServer" />
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-tooltip :disabled="isShowTooltip" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                dark
                name="Login"
                v-on="on"
                @click="goToLogin"
                v-if="!isLoginViaApiKey"
              >
                <v-icon size="35">mdi-account</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Login") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import Util from "@/util";
import { mapState } from "vuex";

export default {
  name: "app-toolbar",
  computed: {
    isLoginViaApiKey() {
      return new URL(document.location).searchParams.get("LatLonGO-API-Key");
    },
    ...mapState({
      isMenuBackButton: state => state.appMode !== "home",
      isMenuDialog: state => state.isMenuDialog,
      isLayerWindow: state => state.layer.isLayerWindow,
      menuIcon: state =>
        state.isMenuDialog || state.appMode !== "home"
          ? "mdi-backburger"
          : "mdi-menu",
      menuTooltip: state =>
        state.isMenuDialog || state.appMode !== "home" ? "Back" : "Menu",
      latlongoServer: state => state.userDefaults.latlongoServer,
      userName: state => state.userName,
      appMode: state => state.appMode,
      isShowTooltip: state => !state.setting.isToolTipsOn
    })
  },
  methods: {
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    goToLogin() {
      this.actionBack();
      this.$store.commit("commitAppMode", "login");
    },
    handleMenu() {
      if (this.isMenuDialog || this.isMenuBackButton) {
        this.$store.dispatch("handleBack");
      } else {
        this.$store.commit("changeMenuDialogState", true);
      }
    },
    actionBack() {
      let currentAppMode = this.appMode;
      while (currentAppMode !== "home") {
        this.$store.dispatch("handleBack");
        currentAppMode = this.appMode;
      }
    }
  }
};
</script>

<style scoped>
.v-subheader {
  font-size: 1.4rem;
}
.infoText {
  margin: 6px 10px 0px 0px;
}
.infoText .v-subheader {
  font-size: 0.75rem;
  height: 17px;
  padding: 0px;
}
.container {
  max-width: 100%;
  padding: 0px;
}
.col {
  padding: 0px;
}
</style>
