import Vue from "vue";
import App from "./components/App.vue";
import "./registerServiceWorker";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import VueInsProgressBar from "vue-ins-progress-bar";

Vue.use(VueInsProgressBar, {
  options: {
    position: "fixed",
    show: true
  }
});

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
