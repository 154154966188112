const frTranslations = {
  Login: "connectez-vous",
  Server: "serveur",
  Username: "nom d'utilisateur",
  Password: "mot de passe",
  Cancel: "Annuler",
  Logging: "exploitation forestière",
  "Unable to connect to this server.":
    "Impossible de se connecter à ce serveur.",
  "Username or password is incorrect (401)":
    "Le nom d’utilisateur ou le mot de passe est incorrect (401)",
  "No more licenses available OR License already expired (403)":
    "Keine weiteren Lizenzen verfügbar ODER Lizenz bereits abgelaufen (403)",
  "Kerberos single sign on attempted, but a NTLM ticket was sent (426)":
    "Tentative d’authentification unique Kerberos, mais un ticket NTLM a été envoyé (426)",
  "An unknown error occurred on Server (500)":
    "Une erreur inconnue s’est produite sur le serveur (500)",
  "Installed Package": "Package installé",
  "Fetching installed package version":
    "Extraction de la version du package installée",
  "LatLonGO provides the end-user with a system for distributing ":
    "LatLonGO fournit à l’utilisateur final un système de distribution ",
  "Enterprise Resource Planning (ERP) information seamlessly to mobile ":
    "Informations de planification des ressources d’entreprise (ERP) de manière transparente vers le mobile ",
  "offline sometimes connected devices (Android, iOS, Windows native ":
    "appareils hors ligne parfois connectés (Android, iOS, Windows natif ",
  "tablet and phone UI) as well as web clients. It comes bundled with ":
    "interface utilisateur de la tablette et du téléphone) ainsi que les clients Web. Il est livré avec ",
  "HERE Maps.": "ICI Cartes.",
  Close: "fermer",
  "Error in fetching the installed package version.":
    "Erreur lors de l’extraction de la version du package installé.",
  Geolocate: "Géolocaliser",
  Undo: "défaire",
  Redo: "refaire",
  Delete: "supprimer",
  More: "plus",
  Home: "domicile",
  Layers: "Couches",
  Edits: "Modifications",
  Measurements: "mensurations",
  Back: "Précédent",
  Menu: "menu",
  Print: "imprimer",
  Mail: "courrier",
  Settings: "Paramètres",
  Help: "Aide",
  Permalink: "Permalien",
  Save: "sauvegarder",
  Scribble: "gribouiller",
  Line: "ligne",
  Polygon: "polygone",
  Select: "choisir",
  Text: "SMS",
  Photo: "photo",
  Audio: "audio",
  Routing: "routage",
  Internals: "Internes",
  Schematics: "Schémas",
  Featurelinks: "Featurelinks",
  About: "Environ",
  Done: "fait",
  "topic-name": "nom_sujet",
  "topic-parent-name": "nom_parent_sujet",
  "topic-description": "description_rubrique",
  Features: "fonctionnalités",
  "The Home button resets the map view to a pre-determined view port and layers defined by the server administrator.The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator. The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator.":"Le bouton Accueil réinitialise la vue cartographique sur un port de vue et des couches prédéterminés définis par l’administrateur du serveur. Le bouton Accueil ferme également n’importe quel mode (lignes rouges, mesures, routage, internes, schémas, etc.) s’il est affiché sur la carte, supprime la sélection le cas échéant sur la carte et réinitialise les unités de la barre d’échelle aux unités d’origine définies par l’administrateur du serveur. Le bouton Accueil ferme également n’importe quel mode (lignes rouges, mesures, routage, internes, schémas, etc.) s’il est affiché sur la carte, supprime la sélection le cas échéant sur la carte et réinitialise les unités de la barre d’échelle aux unités d’origine définies par l’administrateur du serveur.",
  "This calculates a route from your current location to the selected feature.":
    "Cela calcule un itinéraire entre votre emplacement actuel et l’entité sélectionnée.",
  "Start navigation": "Démarrer la navigation",
  "This starts the turn-by-turn navigation.":
    "Cela démarre la navigation tour par tour.",
  Directions: "Itinéraire",
  "The last measurement action can be undone by clicking on the Undo button on the measurement toolbar.": "La dernière action de mesure peut être annulée en cliquant sur le bouton Annuler de la barre d’outils de mesure.",
  "The last measurement action, which was undone using Undo button can be redone by clicking on the Redo button on the measurement toolbar.": "La dernière action de mesure, qui a été annulée à l’aide du bouton Annuler, peut être refaite en cliquant sur le bouton Rétablir de la barre d’outils de mesure.",
  "User can measure the coordinates at a clicked point by selecting the Coordinate option on the measurement toolbar and clicking at a point on the map.A popover is displayed at the clicked point on the map displaying the Lat/Long values.The Lat, Long values are also populated in the Lat, Long labels on the measurement toolbar.":"L’utilisateur peut mesurer les coordonnées à un point cliqué en sélectionnant l’option Coordonnées dans la barre d’outils de mesure et en cliquant sur un point de la carte. Une fenêtre contextuelle s’affiche au point cliqué sur la carte affichant les valeurs Lat/Long. Les valeurs Lat, Long sont également renseignées dans les étiquettes Lat, Long de la barre d’outils de mesure.",
  "User can measure the distance between two or more points by selecting the Distance option on the measurement toolbar and clicking on two or more points on the map.A line is drawn between consective points on the map and the individual distance value is displayed at each segment on mouse over. The accumulated distance value is displayed at each vertex on mouse over. The total distance value is populated in the Distance label on the measurement toolbar.":"L’utilisateur peut mesurer la distance entre deux points ou plus en sélectionnant l’option Distance dans la barre d’outils de mesure et en cliquant sur deux points ou plus sur la carte. Une ligne est tracée entre les points de consection sur la carte et la valeur de distance individuelle est affichée à chaque segment avec la souris. La valeur de distance cumulée est affichée à chaque sommet de la souris. La valeur de la distance totale est renseignée dans l’étiquette Distance de la barre d’outils de mesure.",
  "User can measure the area between three or more points by selecting the Area option on the measurement toolbar and clicking on three or more points on the map.A polygon is drawn between the points on the map and accumulated distance value is displayed at each vertex on mouse over.The total area value is populated in the Area label on the measurement toolbar.":"L’utilisateur peut mesurer la zone entre trois points ou plus en sélectionnant l’option Zone dans la barre d’outils de mesure et en cliquant sur trois points ou plus sur la carte. Un polygone est dessiné entre les points de la carte et la valeur de distance accumulée est affichée à chaque sommet avec la souris. La valeur de la surface totale est renseignée dans l’étiquette Zone de la barre d’outils de mesure.",
 " Clear all": "Effacer tout",
  "This opens a list of turn-by-turn instructions. Clicking on an instruction will take the map to the corresponding location.":
    "Cela ouvre une liste d’instructions tour par tour. En cliquant sur une instruction, la carte sera prise à l’emplacement correspondant.",
  "Current Location": "Emplacement actuel",
  "On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.":
    "Lors de l’activation de la géolocalisation, fermez l’appareil au verrou GPS en indiquant que votre position actuelle est affichée sur la carte et que la carte suivra votre position lorsque vous vous déplacez.",
  "Route Map": "Carte de route",
  "This zooms the map back to an extent that shows the complete route.":
    "Cela permet d’effectuer un zoom arrière sur la carte dans une étendue qui affiche l’itinéraire complet.",
  Guidance: "direction",
  "On activating Geolocate, the button turns green and your current position is shown on the map. In case, current position is not found or the GPS signal is lost, user is informed by displaying appropriate message.":
    "Lors de l’activation de Geolocate, le bouton devient vert et votre position actuelle est affichée sur la carte. Dans le cas où la position actuelle n’est pas trouvée ou si le signal GPS est perdu, l’utilisateur en est informé en affichant le message approprié.",
  "Enables user to view the schematic diagram of a network the selected feature belongs to.":
    "Permet à l’utilisateur d’afficher le diagramme schématique d’un réseau auquel appartient l’entité sélectionnée.",
    "Layer Control shows all on-line and off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap or WMTS layers.In off-line mode, those layers extracted from the GIS (Land Base, Electricity, Gas, Water, Telco etc…) are available.": "Layer Control affiche tous les ensembles de données disponibles en ligne et hors ligne. En mode en ligne, il peut s’agir de cartes HERE telles que HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap ou les couches WMTS. En mode hors ligne, les couches extraites du SIG (Base terrestre, Électricité, Gaz, Eau, Télécom etc...) sont disponibles.",
    "To get more information about a feature, click on the feature on the map. A map marker showing the feature type will be displayed.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.":"Pour obtenir plus d’informations sur une entité, cliquez sur la fonctionnalité sur la carte. Un marqueur de carte indiquant le type d’entité s’affiche. Cliquez sur le marqueur de carte pour ouvrir une fenêtre qui répertorie les attributs sortant du système SIG. S’il y a plusieurs entités dans la zone cliquée, vous pouvez y accéder via le bouton Autres affiché avec le marqueur de carte de la fonction cliquée ou le bouton Fonctionnalités affiché en haut à gauche de la fenêtre des attributs. Cliquez sur le marqueur de carte pour ouvrir une fenêtre qui répertorie les attributs sortant du système SIG. S’il y a plusieurs entités dans la zone cliquée, vous pouvez y accéder via le bouton Autres affiché avec le marqueur de carte de l’entité cliquée ou le bouton Fonctionnalités affiché en haut à gauche de la fenêtre des attributs.",
  Tracing: "traçage",
  "Typically available for Service features, this function presents the user with a trace of the signal path.\nWhen a schematic is on screen, and a particular service is selected, the Trace view is a schematic representation of the signal path, including only the components, ports and connections relevant to the selected service.\nWhen the geographical map is on screen, and a particular service is selected, the Trace view highlights the signal path along the cables which carry the selected service.":
    "Généralement disponible pour les fonctions de service, cette fonction présente à l’utilisateur une trace du chemin du signal.nLorsqu’un schéma est à l’écran et qu’un service particulier est sélectionné, la vue Traçage est une représentation schématique du chemin du signal, y compris uniquement les composants, les ports et les connexions pertinents pour le service sélectionné.nLorsque la carte géographique est à l’écran , et un service particulier est sélectionné, la vue Trace met en évidence le chemin du signal le long des câbles qui transportent le service sélectionné.",
  "Map interaction": "Interaction cartographique",
  "For interacting with map there are several gestures available as explained below. Furthermore, you can change the units used in the scale legend in the lower left corner of the map by tapping onto it with one finger":
    "Pour interagir avec la carte, plusieurs gestes sont disponibles comme expliqué ci-dessous. En outre, vous pouvez modifier les unités utilisées dans la légende de l’échelle dans le coin inférieur gauche de la carte en appuyant dessus avec un doigt",
  "Feature selection": "Sélection des fonctionnalités",
  "To select a feature, tap the feature with one finger.":
    "Pour sélectionner une fonction, appuyez sur la fonction d’un doigt.",
  "Continuous zoom": "Zoom continu",
  "To continuously zoom in or out, press and hold two fingers to the screen, and increase or decrease the distance between them.":
    "Pour effectuer un zoom avant ou arrière continu, maintenez enfoncés deux doigts sur l’écran, puis augmentez ou diminuez la distance entre eux.",
  "Fixed zoom": "Zoom fixe",
  "To zoom the map in a fixed amount, tap the screen twice with one finger.":
    "Pour zoomer la carte dans une quantité fixe, appuyez deux fois sur l’écran avec un doigt.",
  Pan: "Pan",
  "Layer control": "Contrôle de couche",
  "Layer control shows all on- and -off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap or WMTS layers. In off-line mode, those layers extracted from the Mobile Server (Land Base, Electricity, Gas, Water, Telco, ...) are available as well as HERE offline layers. \n\nClick on the HERE offline button to download maps for states or whole continents.\n\nIf the metadata about any of the layers is available, a Layer Info button is available to display the layer information.":
    "Le contrôle de couche affiche tous les ensembles de données disponibles en ligne et hors ligne. En mode en ligne, ceux-ci pourraient inclure des cartes HERE telles que HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap ou WMTS couches. En mode off-line, les couches extraites du Serveur Mobile (Land Base, Electricité, Gaz, Eau, Telco, ...) sont disponibles ainsi que les couches HORS LIGNE HERE. nnCliquez sur le bouton HERE hors connexion pour télécharger des cartes pour des états ou des continents entiers.nnSi les métadonnées relatives à l’une des couches sont disponibles, un bouton Informations sur la couche est disponible pour afficher les informations de la couche.",
    Edits: "Modifications",
    "The edits synced by field users can be viewed by clicking on the Redlines button. It displays the redlines toolbar. On clicking the Redlines button on the redline toolbar displays the list of redlines in the area, along with the users.Clicking on a redline in the list displays all the contained redline objects on the map. If a photo/audio is there as part of redlines, the corresponding icons (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) are displayed on the map. User can click on the icon and see the photo, listen to the audio or view the form.":
    "Les modifications synchronisées par les utilisateurs de champs peuvent être consultées en cliquant sur le bouton Redlines. Il affiche la barre d’outils des lignes rouges. En cliquant sur le bouton Lignes rouges de la barre d’outils Lignes rouges, la liste des lignes rouges de la zone s’affiche, ainsi que les utilisateurs. Cliquez sur une ligne rouge dans la liste pour afficher tous les objets de ligne rouge contenus sur la carte. Si une photo/audio est présente dans le cadre des lignes rouges, les icônes correspondantes (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) sont affichées sur la carte. L’utilisateur peut cliquer sur l’icône et voir la photo, écouter l’audio ou afficher le formulaire.",
  "Returns to main menu.": "Retourne au menu principal.",
  "Reverts the previous step.": "Rétablit l’étape précédente.",
  "For free-hand drawing.": "Pour le dessin à main levée.",
  "Map Gestures": "Mapper les gestes",
  "To manipulate the map gestures like pan, zoom...":
    "Pour manipuler les gestes de la carte comme le panoramique, le zoom...",
  "Stores photos taken at the user's location from the device camera.":
    "Stocke les photos prises à l’emplacement de l’utilisateur à partir de l’appareil photo de l’appareil.",
  "The Geolocate (compass) button puts the device into GPS lock for a moving map display.":
    "Le bouton Géolocaliser (boussole) place l’appareil dans le verrouillage GPS pour un affichage cartographique en mouvement.",
    "Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.": "Cliquez sur l’outil Mesure de la barre d’outils de navigation principale pour afficher la barre d’outils Mesure. Cliquez sur l’outil Mesure de la barre d’outils de navigation principale pour afficher la barre d’outils Mesure.",
    "Units Toggle: Toggles the units from Metric to Imperial and vice versa. Affects scale bar units, distance/area units in measurements and the distance units in route calculations and manoeuvres.": " Units Toggle: Bascule les unités de Metric à Imperial et vice versa. Affecte les unités de barre d’échelle, les unités de distance/surface dans les mesures et les unités de distance dans les calculs d’itinéraire et les manœuvres.",
  "Save all edits in the current session.":
    "Enregistrez toutes les modifications dans la session active.",
  "Measurements include coordinates, running distance and area.":
    "Les mesures incluent les coordonnées, la distance de course et la surface.",
    "Search bar searches for features and street addresses. For features, all layers, no matter whether currently visible or not, are searched. If you select from the result list a feature which belongs to a layer which is currently switched off, that layer will automatically be switched on before highlighting the location of the feature.User can also search for mapcodes by providing valid mapcode in the search bar. See the Mapcodes for more information.": " La barre de recherche recherche des caractéristiques et des adresses postales. Pour les entités, tous les calques, qu’ils soient actuellement visibles ou non, sont recherchés. Si vous sélectionnez dans la liste des résultats une fonction qui appartient à une couche qui est actuellement désactivée, cette couche sera automatiquement activée avant de mettre en surbrillance l’emplacement de la fonction. L’utilisateur peut également rechercher des codes de carte en fournissant un code de carte valide dans la barre de recherche. Consultez les Mapcodes pour plus d’informations.",
    "This calculates a route from your current location to the selected feature/address.Car Routing: A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.Truck Routing This calculates the truck route from current location to the location of selected feature/address.Public Transit RoutingThis calculates the public transit route from current location to the location of selected feature/address. Pedestrian Routing This calculates the pedestrian route from current location to the location of selected feature/address.": "Cela calcule un itinéraire de votre emplacement actuel à la fonction/ adresse sélectionnée. Routage de voiture: Un itinéraire de voiture est calculé lorsque l’utilisateur clique sur le bouton Routage dans la fenêtre des attributs. Le routage de voiture est le mode de routage par défaut. Routage du camion Cela calcule l’itinéraire du camion de l’emplacement actuel à l’emplacement de la fonction / adresse sélectionnée. Itinéraire de transport en communCela calcule l’itinéraire de transport en commun de l’emplacement actuel à l’emplacement de l’entité / adresse sélectionnée. Itinéraire piétonnier Cela calcule l’itinéraire piétonnier de l’emplacement actuel à l’emplacement de la caractéristique /adresse sélectionnée.",
    "Feature links are displayed as text buttons at the bottom of the attributes window. Feature links allow open an external application and to send data from LatLonGO to an external application. Feature links can be defined to automatically send data, e.g. attributes and location associated with selected feature.Feature links are defined on the LatLonGO mobile server.":"Les liens de fonctionnalité sont affichés sous forme de boutons de texte en bas de la fenêtre des attributs. Les liens de fonctionnalité permettent d’ouvrir une application externe et d’envoyer des données de LatLonGO à une application externe. Les liens de fonctionnalité peuvent être définis pour envoyer automatiquement des données, par exemple les attributs et l’emplacement associés à l’entité sélectionnée. Les liens de fonctionnalités sont définis sur le serveur mobile LatLonGO.",
    "This shows the Street Level view at the location of the selected feature/address. This feature is not yet available for all countries.": "Cela affiche la vue Street Level à l’emplacement de la fonction/adresse sélectionnée. Cette fonctionnalité n’est pas encore disponible pour tous les pays.",
    "Prints the screenshot of the current map view.":"Imprime la capture d’écran de la vue cartographique actuelle.",
    "Shares the permalink to the current view in an email.":"Partage le permalien avec la vue actuelle dans un e-mail.",
    "Generates the permalink to the current map view, which can be copied and shared.":"Génère le permalien vers la vue cartographique actuelle, qui peut être copiée et partagée.",
  cancel: "Annuler",
  undo: "défaire",
  Coordinate: "coordonnée",
  "Lat/Lon read-out (configurable)": "Lecture lat/lon (configurable)",
  Distance: "distance",
  "Accumulated length measurement": "Mesure de la longueur accumulée",
  Area: "aire",
  "Area measurement": "Mesure de surface",
  geolocate_: "geolocate_",
  "The guidance feature enables the user to specify a feature (or just a desired location), that the device will guide them to by means of an on screen pointer - they just follow the arrow which points from their current location to the guide target.":
    "The guidance feature enables the user to specify a feature (or just a desired location), that the device will guide them to by means of an on screen pointer - they just follow the arrow which points from their current location to the guide target.",
  share: "Partager",
  Search: "rechercher",
  "Searches for feature attributes and street addresses. You can also type in geo coordinates (e.g. -37.813824, 144.960397) to center the map at that location.":
    "Recherche des attributs d’entité et des adresses postales. Vous pouvez également saisir des coordonnées géographiques (par exemple -37.813824, 144.960397) pour centrer la carte à cet emplacement.",
  Sync: "Synchronisation",
  "Synchronises device with server or allows for input of server connection detail if not connected.":
    "Synchronise l’appareil avec le serveur ou permet d’entrer les détails de la connexion au serveur s’il n’est pas connecté.",
  help: "Aide",
  "Invokes this Help System.": "Appelle ce système d’aide.",
  "Allows for printing the current map view screenshot":
    "Permet d’imprimer la capture d’écran de la vue cartographique actuelle",
  Scale: "écaille",
  Zoom: "zoom",
  "Capturing is not supported.": "La capture n’est pas prise en charge.",
  "Overlay Layers": "Calques de superposition",
  Version: "Version",
  Source: "source",
  Extractor: "extracteur",
  Extracted: "extrait",
  Updated: "Actualisé",
  "No Information Available": "Aucune information disponible",
  "Base Layers": "Couches de base",
  Mapcode: "Code de carte",
  coordinate: "coordonnée",
  Car: "voiture",
  Public: "public",
  Truck: "camion",
  Pedestrian: "piéton",
  "Route Directions": "Itinéraire",
  "Route overview": "Vue d’ensemble de l’itinéraire",
  Time: "Heure",
  "Unable to retrive information.": "Impossible de retrive informations.",
  "Location timeout error.": "Erreur de délai d’attente d’emplacement.",
  "Location unavailable.": "Emplacement indisponible.",
  "Only secure origins are allowed.":
    "Seules les origines sécurisées sont autorisées.",
  "Location permission denied!": "Autorisation de localisation refusée!",
  "Unknown location error.": "Erreur d’emplacement inconnu.",
  "Base layer included in permalink is not present in current user's configuration.":
    "La couche de base incluse dans le permalien n’est pas présente dans la configuration de l’utilisateur actuel.",
  "Checking for current position...": "Vérification de la position actuelle...",
  "Routing lost position. Try again": "Routage de position perdue. Réessayez",
  "Calculating route...": "Calcul de l’itinéraire...",
  "Route calculation failed. The path between your position and the specified destination could not be determined.":
    "Échec du calcul de l’itinéraire. Le chemin d’accès entre votre position et la destination spécifiée n’a pas pu être déterminé.",
  "The input is required.": "L’entrée est requise.",
  "The input allows decimal values only.": "L’entrée autorise uniquement les valeurs décimales.",
  "This field doesn't allow special characters.":
    "Dieses Feld lässt keine Sonderzeichen zu.",
  "Username should not contain special characters / spaces":
    "Le nom d’utilisateur ne doit pas contenir de caractères spéciaux / espaces",
  "This field doesn't allow starting and trailing whitespaces.":
    "Ce champ n’autorise pas les espaces de début et de fin.",
  "Number of digits should not exceed 15":
    "Le nombre de chiffres ne doit pas dépasser 15",
  "Error: Network Error": "Erreur : Erreur réseau",
  Others: "autrui",
  "Clear All": "Effacer tout",
  "Error in fetching features at your tapped location.": "Erreur lors de l’extraction des entités à votre emplacement tapé.",
  Download: "Télécharger",
  "Online Layer": "Couche en ligne",
  "Can't get current location. Using home region instead.":"mpossible d'obtenir l'emplacement actuel. Utilisation de la région d'accueil à la place..",
  "Parse Error": "Erreur d’analyse",
  "Invalid URL": "URL non valide",
  "Measure Area":"Zone de mesure",
  "Measure distance":"Mesurer la distance",
  "Measure coordinates":"Coordonnées de mesure",
  "Street Level":"Niveau de la rue",
  "Feature Links":"Liens vers les fonctionnalités",
  "Gives the information about the installed web app package.":"Donne les informations sur le package d’application web installé.",
  "Car Routing": "Routage de voiture",
  "A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.": "Un itinéraire de voiture est calculé lorsque l’utilisateur clique sur le bouton Routage dans la fenêtre des attributs. Le routage de voiture est le mode de routage par défaut.",
  "Truck Routing":"Routage des camions",
  "This calculates the truck route from current location to the location of selected feature/address.":"Cela calcule l’itinéraire du camion de l’emplacement actuel à l’emplacement de la fonction / adresse sélectionnée.",
  "Public Transit Routing":"Itinéraire de transport en commun",  
  "This calculates the public transit route from current location to the location of selected feature/address.":"Cela calcule l’itinéraire de transport en commun de l’emplacement actuel à l’emplacement de l’entité / adresse sélectionnée.",
  "Pedestrian Routing":"Itinéraire piétonnier",
  "This calculates the pedestrian route from current location to the location of selected feature/address.":"Cela calcule l’itinéraire piétonnier de l’emplacement actuel à l’emplacement de la fonction / adresse sélectionnée."
};
export default frTranslations;
