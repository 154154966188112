import apiMeasurement from "@/api/measurement";
import HereUtil from "@/util/here";

const measurement = {
  namespaced: true,
  state: {
    currentMeasurementMode: "coordinate",
    measurementValue: "",
    isDecimalCoordinate: true,
    mapTappedPoints: [],
    undoStack: [],
    redoStack: [],
    mapCode: "",
    isPolygonValid: false
  },
  mutations: {
    /* Valid values are coordinate, distance, area */
    changeCurrentMeasurementMode(state, mode) {
      state.currentMeasurementMode = mode;
      state.mapTappedPoints.splice(0);
      state.measurementValue = "";
      state.undoStack.splice(0);
      state.mapCode = "";
    },
    changeMapTappedPoints(state, payload) {
      if (payload.action === "push") {
        state.mapTappedPoints.push(payload.value);
        state.undoStack.push([...state.mapTappedPoints]);
      } else if (payload.action === "pop") {
        state.mapTappedPoints.splice(state.mapTappedPoints.length - 1, 1);
      } else if (payload.action === "clear") {
        state.mapTappedPoints.splice(0);
      } else if (payload.action === "pointUpdate") {
        state.mapTappedPoints[payload.index] = payload.value;
      } else if (payload.action === "assign") {
        state.mapTappedPoints = [...payload.value];
      }
    },
    changeUndoStack(state, payload) {
      if (payload.action === "push") {
        state.undoStack.push([...state.mapTappedPoints]);
      } else if (payload.action === "pop") {
        let popedState = state.undoStack.pop();
        state.redoStack.push(popedState);
      } else if (payload.action === "clear") {
        state.undoStack.splice(0);
      }
    },
    changeRedoStack(state, payload) {
      if (payload.action === "push") {
        state.redoStack.push([...state.mapTappedPoints]);
      } else if (payload.action === "pop") {
        let popedState = state.redoStack.pop();
        state.undoStack.push(popedState);
      } else if (payload.action === "clear") {
        state.redoStack.splice(0);
      }
    },
    changeIsDecimalCoordinate(state, value) {
      state.isDecimalCoordinate = value;
    },
    changeMeasurementValue(state, value) {
      state.measurementValue = value;
    },
    changeMapCode(state, value) {
      state.mapCode = value;
    },
    changeIsPolygonValid(state, value) {
      state.isPolygonValid = value;
    }
  },
  actions: {
    handleEnterMeasurement({ state, commit, dispatch }) {
      // This call is an alternative to dynamic loading.
      // Once hotreloading is working with vuex this may be removed.
      commit("changeCurrentMeasurementMode", "coordinate");
    },
    handleChangeMeasurementMode({ commit }, mode) {
      commit("changeCurrentMeasurementMode", mode);
    },
    handleMeasurementMapTapped({ state, commit, dispatch }, latLon) {
      if (latLon) {
        commit("changeMapTappedPoints", { action: "push", value: latLon });
      }
      dispatch("handleMeasurementCalculations");
    },
    handleMeasurementUndo({ state, commit, dispatch }) {
      if (state.mapTappedPoints.length >= 1) {
        commit("changeUndoStack", { action: "pop" });
        commit("changeMapTappedPoints", {
          action: "assign",
          value: state.undoStack.length
            ? state.undoStack[state.undoStack.length - 1]
            : []
        });
        dispatch("handleMeasurementCalculations");
      }
    },
    handleMeasurementRedo({ state, commit, dispatch }) {
      if (state.redoStack.length >= 1) {
        commit("changeMapTappedPoints", {
          action: "assign",
          value: state.redoStack[state.redoStack.length - 1]
        });
        commit("changeRedoStack", { action: "pop" });
        dispatch("handleMeasurementMapTapped");
      }
    },
    handleMeasurementDelete({ rootState, state, commit, dispatch }) {
      commit("changeMapTappedPoints", { action: "clear" });
      commit("changeUndoStack", { action: "clear" });
      commit("changeRedoStack", { action: "clear" });
      dispatch("handleMeasurementMapTapped");
    },
    handleMeasurementMetricImperial({ state, dispatch }) {
      dispatch("handleMeasurementCalculations");
    },
    handleCoordinateDecimalToDegrees({ state, commit, dispatch }, val) {
      commit("changeIsDecimalCoordinate", !state.isDecimalCoordinate);
      dispatch("handleMeasurementCalculations");
    },
    handleExitMyMode({ commit }) {},
    handleMeasurementCalculations({ rootState, state, commit }) {
      let hereUtil = new HereUtil();
      if (state.currentMeasurementMode === "coordinate") {
        if (state.mapTappedPoints.length > 0) {
          let tapPoint =
            state.mapTappedPoints[state.mapTappedPoints.length - 1];
          commit(
            "changeMeasurementValue",
            state.isDecimalCoordinate
              ? hereUtil.getDecoratedDecimal(tapPoint)
              : hereUtil.getDecoratedDegMinSec(tapPoint)
          );
          apiMeasurement.getMapcode(tapPoint).then(response => {
            commit("changeMapCode", response.data);
          });
        } else {
          commit("changeMeasurementValue", "Lat: Lon:");
          commit("changeMapCode", "");
        }
      } else if (state.currentMeasurementMode === "distance") {
        let displayValue = hereUtil.distanceCalculation(state.mapTappedPoints);
        displayValue = hereUtil.getDistanceMeasurementValue(
          rootState.home.config.units,
          displayValue
        );
        commit("changeMeasurementValue", displayValue);
      } else if (state.currentMeasurementMode === "area") {
        let pointsString = hereUtil.mapPointsToString(state.mapTappedPoints);
        if (pointsString) {
          apiMeasurement.getMeasurementArea(pointsString).then(response => {
            let displayValue = hereUtil.getAreaMeasurementValue(
              rootState.home.config.units,
              response.data
            );
            commit("changeMeasurementValue", displayValue);
          });
        } else {
          commit(
            "changeMeasurementValue",
            0 +
              " " +
              (rootState.home.config.units === "metric" ? "km\xB2" : "ft\xB2")
          );
        }
      }
    },
    handleValidatePoint({ state, commit, dispatch }, latLon) {
      if (state.currentMeasurementMode === "area") {
        let coordinates = [latLon, ...state.mapTappedPoints];
        let hereUtil = new HereUtil();
        let isValidObject = hereUtil.isPolygonValid(coordinates);
        if (!isValidObject.isValid) {
          dispatch("handleShowInvalidPolygonMessage", isValidObject);
        }
        commit("changeIsPolygonValid", isValidObject.isValid);
      }
    },
    handleShowInvalidPolygonMessage({ state }, isValidObject) {
      if (isValidObject.errorMessage !== null) {
        window.getApp.$emit("FLASH_MESSAGE", {
          text: isValidObject.errorMessage,
          type: "error"
        });
      }
    }
  }
};
export default measurement;
