<template>
  <div>
    <v-snackbar
      top
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout">
      <v-icon>{{ snackbar.icon }}</v-icon>
      {{ $t(snackbar.text) }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">
          {{ $t("Close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-app id="inspire">
      <app-toolbar></app-toolbar>
      <v-main id="app-content">
        <HereMap id="app-here-map" v-if="userName !== ' - '"></HereMap>
        <AppFeatureComponents id="app-feature-components"></AppFeatureComponents>
      </v-main>
      <app-bottombar></app-bottombar>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppToolbar from "@/components/AppToolbar";
import AppFeatureComponents from "@/components/AppFeatureComponents";
import AppBottombar from "@/components/AppBottombar";
import AppEvents from "@/util/event";
import HereMap from "@/components/HereMap";

export default {
  name: "App",
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: "",
      timeout: -1,
      icon: ""
    }
  }),
  components: {
    AppToolbar,
    HereMap,
    AppFeatureComponents,
    AppBottombar
  },

  computed: mapState({
    userName: state => state.userName
  }),

  created() {
    AppEvents.forEach(item => this.$on(item.name, item.callback));
    window.getApp = this;
  }
};
</script>

<style>
html { 
  overflow-y: auto 
}
.no-padding {
  padding: 0px;
}
.no-margin {
  margin: 0px;
}
@media print {
  .centerFloatButtons {
    top:200px !important; 
    margin-top: 0px;
    padding-top: 0px;
 }
}
@-moz-document url-prefix() {
  @media print {
    .bottomMenu {
      display: none;
    }
  }
}
</style>
