<template>
  <div>
    <v-autocomplete
      clearable
      hide-no-data
      no-filter
      return-object
      solo
      ref="searchBar"
      v-model="selected"
      :items="searchItems"
      :loading="getLoadingStatus"
      :search-input.sync="searchText"
      item-text="label"
      :label="$t('Search')"
      append-icon="mdi-magnify"
      @change="handleSelectedSearch"
    >
      <template v-slot:item="data">
        <v-list-item-avatar id="logo-style">
          <v-img
            max-height="25"
            max-width="25"
            v-if="data.item.searchType === 'Address'"
            src="@/assets/icon_here.png"
          />
          <v-img
            max-height="25"
            max-width="25"
            v-else
            src="@/assets/icon_latlongo.png"
          />
        </v-list-item-avatar>
        <v-list-item-content v-if="data.item.searchType === 'Feature'">
          <v-list-item-title id="searchItemText">{{
            data.item.feature.match
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            data.item.feature.name
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title
            id="searchItemText"
            v-html="getDropdownText(data.item)"
          />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import featuresApi from "@/api/features";
import { mapGetters, mapState } from "vuex";
import HereUtil from "@/util/here";

export default {
  name: "search",
  data: () => ({
    isFeaturesLoading: false,
    isAddressLoading: false,
    isMapcodeLoading: false,
    selected: null,
    hereUtil: new HereUtil()
  }),
  computed: {
    searchItems() {
      return this.$store.getters["search/searchResults"];
    },
    getLoadingStatus() {
      return (
        this.isFeaturesLoading || this.isAddressLoading || this.isMapcodeLoading
      );
    },
    searchText: {
      get() {
        let value = this.searchQuery;
        return value ? value.trim() : value;
      },
      set(value) {
        this.$store.commit("search/updateSearchQuery", value, { root: true });
      }
    },
    ...mapState({
      hereApiKey: state => state.home.config.here_licenses.js_rest.apikey,
      mapCenter: state => state.home.mapCenter,
      mapbounds: state => state.home.bounds,
      appMode: state => state.appMode,
      geolocateMode: state => state.geolocation.geolocate,
      searchQuery: state => state.search.searchQuery
    }),
    ...mapGetters({
      id: "layer/getOverlayLayerId"
    })
  },
  watch: {
    searchText(queryString) {
      if (!this.selected) {
        if (queryString) {
          if (this.geolocateMode === "follow") {
            this.$store.commit("geolocation/changeGeolocate", "not-follow");
          }
          this.startSearch(queryString);
          // Commenting this line for now as it doesn't make sense. Will add it back if any edge case need to be handled.
          // } else if (queryString !== null) {
          //   this.$store.dispatch("handleBack");
        } else if (queryString === null) {
          // Clearing the search suggestions when the search text is cleared/null
          this.$store.commit("search/clearSearchResults", {}, { root: true });
        }
      }
    }
  },
  methods: {
    getDropdownText(item) {
      return item.searchType === "Address"
        ? item.feature.title + " , " + item.feature.vicinity
        : item.label;
    },
    handleSelectedSearch() {
      if (this.selected) {
        if (this.appMode !== "search") {
          this.$store.dispatch("handleSwitchFromHome", { mode: "search" });
        }
        this.$store.dispatch("search/handleExitMyMode");
        if (this.selected.searchType === "Address") {
          featuresApi
            .fetchAddressInfoForAddress(this.selected.feature.href)
            .then(addressInfo => {
              this.$store.dispatch("handleSwitchFromHome", {
                mode: "featurePanel",
                value: {
                  searchType: this.selected.searchType,
                  feature: addressInfo,
                  tappedCoord: {
                    lat: this.selected.feature.position[0],
                    lng: this.selected.feature.position[1]
                  }
                }
              });
            });
        } else {
          let tappedCoord = this.selected.feature.anchor
            ? {
                lat: this.selected.feature.anchor[1],
                lng: this.selected.feature.anchor[0]
              }
            : {
                lat: this.selected.feature.geometry.coordinates[1],
                lng: this.selected.feature.geometry.coordinates[0]
              };
          this.selected["tappedCoord"] = tappedCoord;
          this.$store.dispatch("handleSwitchFromHome", {
            mode: "featurePanel",
            value: this.selected
          });
        }
      }
    },
    startSearch(queryString) {
      this.isFeaturesLoading = true;
      this.isAddressLoading = true;
      this.isMapcodeLoading = true;

      /* Feature Search */
      featuresApi.findFeatureFromQuery(queryString, this.id).then(response => {
        if (response.features.length && this.searchText) {
          let featureResponse = response.features.map(feature => {
            return {
              searchType: "Feature",
              feature: feature,
              label: feature.name + " , " + feature.match
            };
          });
          let features = [{ header: "Features Results" }].concat(
            featureResponse
          );
          this.$store.commit("search/updateFeatureResults", features, {
            root: true
          });
        } else {
          this.$store.commit("search/updateFeatureResults", [], { root: true });
        }
        this.isFeaturesLoading = false;
      });

      /* Address Search */
      featuresApi
        .findAddressFromQuery(queryString, this.hereApiKey, this.mapCenter)
        .then(response => {
          if (response.length && this.searchText) {
            let addressResponse = response.map(address => {
              return {
                searchType: "Address",
                feature: address,
                label: (address["title"] + " , " + address["vicinity"]).replace(
                  "<br/>",
                  " , "
                )
              };
            });
            let address = [{ header: "Place Results" }].concat(addressResponse);
            this.$store.commit("search/updateAddressResults", address, {
              root: true
            });
          } else {
            this.$store.commit("search/updateAddressResults", [], {
              root: true
            });
          }
          this.isAddressLoading = false;
        });

      /* Mapcode Search */
      featuresApi
        .findMapcodeFromQuery(
          queryString +
            "?bbox=" +
            this.mapbounds.left +
            "," +
            this.mapbounds.bottom +
            "," +
            this.mapbounds.right +
            "," +
            this.mapbounds.top
        )
        .then(
          response => {
            if (
              response.features &&
              response.features.length &&
              this.searchText
            ) {
              let mapcodeResponse = response.features.map(mapcode => {
                return {
                  searchType: "Mapcode",
                  feature: mapcode,
                  label: mapcode.match
                };
              });
              let mapCodes = [{ header: "Mapcodes Results" }].concat(
                mapcodeResponse
              );
              this.$store.commit("search/updateMapcodeResults", mapCodes, {
                root: true
              });
            } else {
              this.$store.commit("search/updateMapcodeResults", [], {
                root: true
              });
            }
            this.isMapcodeLoading = false;
          },
          response => {
            this.$store.commit("search/updateMapcodeResults", [], {
              root: true
            });
            this.isMapcodeLoading = false;
          }
        );
    }
  }
};
</script>
<style>
.theme--light.v-subheader {
  background-color: dimgrey;
  color: white !important;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
  width: 350px;
}
.v-menu__content.v-autocomplete__content {
  max-height: calc(100vh - 200px) !important;
  width: 350px;
}
.v-list-item--link:before {
  background-color: transparent !important;
}
#searchItemText {
  color: rgba(0, 0, 0, 0.87);
}
#logo-style {
  margin-right: 0px;
  margin-left: -15px;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 6px;
  background-clip: padding-box;
  border: 1px solid transparent;
  color: lightgrey;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
</style>
