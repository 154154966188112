import { api, basePath } from "@/api/index";
import server from "@/api/server";
import Vue from "vue";
import qs from "qs";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

const ping = function(serverName) {
  let pingUrl = "https://" + serverName + basePath + "services/ping";
  return api.get(pingUrl).then(
    response => {
      api.defaults.baseURL = "https://" + serverName;
      server.baseURL = "https://" + serverName;
      return response;
    },
    error => {
      throw error;
    }
  );
};

const postLogin = function(model) {
  return api({
    method: "POST",
    url: basePath + "auth/login",
    data: model,
    transformRequest: [
      (data, headers) => {
        if (headers["LatLonGO-API-Key"]) {
          delete headers["LatLonGO-API-Key"];
        }
        return qs.stringify(data);
      }
    ]
  }).then(
    response => {
      let responseApiKey = response.headers["latlongo-api-key"];
      server.apiKey = responseApiKey;

      Vue.$cookies.set("LatLonGO-API-Key", responseApiKey);
      api.defaults.headers["LatLonGO-API-Key"] = responseApiKey;
      return response;
    },
    error => {
      throw error;
    }
  );
};

const getConfigId = function() {
  return api.get(basePath + "configurations").then(
    response => {
      return response;
    },
    error => {
      throw error;
    }
  );
};

// This is a admin level method.
const getConfigIdByUser = function(username) {
  return getUsers(username).then(id => {
    return api
      .get(basePath + "users/" + id)
      .then(response => response.data.config);
  });
};

const getUsers = function(username) {
  return api.get(basePath + "users").then(response => {
    return response.data.find(userDetails => userDetails.user === username).id;
  });
};
const getConfigDetails = function(configId) {
  return api.get(basePath + "configurations/" + configId + "?detail=full").then(
    response => {
      return response;
    },
    error => {
      throw error;
    }
  );
};

const validateHereLicense = function(hereApiKey) {
  return api
    .get(
      "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile" +
        "/newest/normal.day/11/1202/843/256/png?apiKey=" +
        hereApiKey
    )
    .then(
      response => {
        return response;
      },
      error => {
        throw error;
      }
    );
};

export default {
  ping,
  postLogin,
  getConfigId,
  getConfigDetails,
  validateHereLicense,
  getConfigIdByUser
};
