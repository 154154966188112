const more = {
  namespaced: true,
  state: {
    isMoreButtonWindow: false,
    isMenuListClosed: false
  },
  mutations: {
    handleMoreButtonExpand(state) {
      state.isMoreButtonWindow = !state.isMoreButtonWindow;
    },
    closeMenuList(state, payload) {
      state.isMenuListClosed = payload;
    }
  }
};
export default more;
