<template>
  <v-container id="appFeatureComponents" class="no-padding no-margin">
    <v-row v-if="isSearchBarHidden" no-gutters>
      <search id="searchBarRow" class="searchBar" app />
    </v-row>
    <v-row id="layerPanelRow" v-if="isLayerWindow" no-gutters>
      <layer class="layer" />
    </v-row>
    <v-row id="featurePanelRow" v-if="isFeaturePanelVisible" no-gutters>
      <featurePanel class="featurePanel" />
    </v-row>
    <v-row id="featurePanelRow" v-if="isRouteDirectionsPanelVisible" no-gutters>
      <directionsDialogBox class="featurePanel" />
    </v-row>
    <v-row id="editPanelRow" v-if="isReadonlyEditDialogVisible" no-gutters>
      <edit class="edit" />
    </v-row>
    <div id="floatButtonsRow" class="centerFloatButtons">
      <v-row no-gutters v-show="isHomeButtonHidden">
        <v-col class="main-menu-icon" md="auto">
          <v-tooltip :disabled="isShowTooltip" right :open-on-hover="!isMenuDialog">
            <template v-slot:activator="{ on }">
              <v-btn id="homeButton" dark fixed color="primary" name="Home" v-on="on" @click="handleGoToHome">
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Home") }}</span>
          </v-tooltip>
        </v-col>
        <v-col md="auto">
          <v-card dark color="primary" class="main-menu-label" @click="handleGoToHome" v-if="isMenuDialog">{{ $t("Home") }}
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="isLayerButtonHidden">
        <v-col class="main-menu-icon" md="auto">
          <v-tooltip :disabled="isShowTooltip" right :open-on-hover="!isMenuDialog">
            <template v-slot:activator="{ on }">
              <v-btn dark fab fixed color="primary" name="Layers" v-on="on" @click="handleGoToLayer">
                <v-icon>mdi-layers-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Layers") }}</span>
          </v-tooltip>
        </v-col>
        <v-col md="auto">
          <v-card dark color="primary" class="main-menu-label" @click="handleGoToLayer" v-if="isMenuDialog">{{ $t("Layers") }}
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="isEditButtonHidden">
        <v-col class="main-menu-icon" md="auto">
          <v-tooltip :disabled="isShowTooltip" right :open-on-hover="!isMenuDialog">
            <template v-slot:activator="{ on }">
              <v-btn dark fab fixed color="primary" name="Edits" v-on="on" @click="handleGoToEdit">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Edits") }}</span>
          </v-tooltip>
        </v-col>
        <v-col md="auto">
          <v-card dark color="primary" class="main-menu-label" @click="handleGoToEdit" v-if="isMenuDialog">{{ $t("Edits") }}
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="isMeasurementButtonHidden">
        <v-col class="main-menu-icon" md="auto">
          <v-tooltip :disabled="isShowTooltip" right :open-on-hover="!isMenuDialog">
            <template v-slot:activator="{ on }">
              <v-btn dark fab fixed name="Measurements" v-on="on" color="primary" @click="handleGoToMeasurement">
                <v-icon>mdi-tape-measure</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Measurements") }}</span>
          </v-tooltip>
        </v-col>
        <v-col md="auto">
          <v-card dark color="primary" class="main-menu-label" @click="handleGoToMeasurement" v-if="isMenuDialog" >{{ $t("Measurements") }}
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isLoginDialog" no-gutters>
        <login class="login" app></login>
      </v-row>
      <v-row v-if="isHelpDialog" no-gutters>
        <help class="help" app></help>
      </v-row>
      <v-row v-if="isAboutDialog" no-gutters>
        <about class="about" app></about>
      </v-row>
      <v-row v-if="isAudioDialog" no-gutters>
        <audioDialogbox app></audioDialogbox>
      </v-row>
      <v-row v-if="isSettingsDialog" no-gutters>
        <Settings />
      </v-row>
      <v-row v-if="IsEditsPhotoDialog" no-gutters>
        <EditsPhotoDialogbox app></EditsPhotoDialogbox>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Search from "@/components/SearchBar";
import Layer from "@/components/Layer";
import apiLogin from "@/api/login";
import { api } from "@/api/index";
import HereStoreUtil from "@/util/hereStore";

export default {
  name: "AppFeatureComponents",
  components: {
    Search,
    Layer,
    Login: () => import("@/components/Login"),
    Help: () => import("@/components/Help"),
    featurePanel: () => import("@/components/FeaturePanel"),
    About: () => import("@/components/About"),
    AudioDialogbox: () => import("@/components/AudioDialogbox"),
    Settings: () => import("@/components/Settings"),
    Edit: () => import("@/components/Edit"),
    EditsPhotoDialogbox: () => import("@/components/EditsPhotoDialogbox"),
    directionsDialogBox: () => import("@/components/Directions")
  },

  computed: mapState({
    isFeaturePanelVisible: state => state.featurePanel.isFeaturePanelVisible,
    isSearchBarHidden: state => state.isSearchBarHidden,
    isHomeButtonHidden: state => state.isHomeButtonHidden,
    isLayerButtonHidden: state => state.isLayerButtonHidden,
    isEditButtonHidden: state => state.isEditButtonHidden,
    isMeasurementButtonHidden: state => state.isMeasurementButtonHidden,
    isMenuDialog: state => state.isMenuDialog,
    isLayerWindow: state => state.layer.isLayerWindow,
    isLoginDialog: state => state.appMode === "login",
    isHelpDialog: state => state.isHelpDialog,
    isAboutDialog: state => state.isAboutDialog,
    isSettingsDialog: state => state.isSettingsDialog,
    isShowTooltip: state => !state.setting.isToolTipsOn,
    isReadonlyEditDialogVisible: state => state.edit.isReadonlyEditDialogVisible,
    isAudioDialog: state => state.appMode === "audioDialog",
    IsEditsPhotoDialog: state => state.appMode === "editsPhotoDialog",
    isRouteDirectionsPanelVisible: state => state.routing.isRouteDirectionsPanelVisible
  }),

  created() {
    let query = window.location.search;
    query = query.indexOf("%") >= 0 ? decodeURIComponent(query) : query;
    let urlParams = new URLSearchParams(query);
    const serverName = urlParams.has("servername") ? urlParams.get("servername") : window.location.hostname;
    apiLogin.ping(serverName).then(response => {
      if (response.status === 200) {
        localStorage["server-name"] = serverName;
        if (urlParams.has("apikey")) {
          const apiKey = urlParams.get("apikey");
          api.defaults.headers = { "LatLonGO-API-Key": apiKey };
          apiLogin.getConfigId().then(resp => {
            if (resp.status === 200) {
              localStorage["latlongo-api-key"] = apiKey;
              this.$store.dispatch("home/handlePermalinkDetails", urlParams);
              this.switchToHomeModeByApiKey(apiKey);
            } else {
              this.popErrorMessage("Invalid API key");
            }
          }, error => this.popErrorMessage("Invalid API key"));
        } else {
          this.$store.commit("commitAppMode", "login");
        }
      } else {
        this.popErrorMessage("Server not available.");
      }
    }, error => this.popErrorMessage("Server not available."));
  },
  methods: {
    popErrorMessage(errorMessage) {
      window.getApp.$emit("FLASH_MESSAGE", { text: errorMessage, type: "error" })
    },
    handleGoToHome() {
      this.$store.dispatch("home/handleGoToHomeMode");
    },
    handleGoToLayer() {
      // Prepration on app level.
      this.$store.dispatch("handleSwitchFromHome", { mode: "layer" });
      // Prepration on layer level.
      this.$store.dispatch("layer/handleGoToLayerWindow");
    },
    handleGoToEdit() {
      // Prepration on app level.
      this.$store.dispatch("handleSwitchFromHome", { mode: "edit" }).then(() => {
        // Prepration on edit level.
        this.$store.dispatch("edit/handleEnterEdit");
      });
    },
    handleGoToMeasurement() {
      // Prepration on app level.
      this.$store.dispatch("handleSwitchFromHome", { mode: "measurement" }).then(() => {
        // Prepration on measurement level.
        this.$store.dispatch("measurement/handleEnterMeasurement");
      });
    },
    switchToHomeModeByApiKey(apiKey) {
      let hereStoreUtil = new HereStoreUtil();
      hereStoreUtil.handlePostLogin("Apikey Login", this.$store);
    }
  }
};
</script>

<style scoped>
.centerFloatButtons {
  position: fixed;
  top: 30%;
  left: 25px;
}
.featurePanel {
  right: 30px;
  top: 25px;
  width: 350px;
  position: absolute;
  z-index: 100;
}
.searchBar {
  left: 25px;
  top: 25px;
  position: absolute;
}
.layer {
  z-index: 1;
  left: 25px;
  top: 100px;
  width: 450px;
  height: 300px;
  position: fixed;
}
.main-menu-icon {
  width: 70px;
  height: 80px;
}
.main-menu-label {
  margin-top: 15px;
  padding: 3px 10px 3px 10px;
}
#homeButton {
  border-radius: 50%;
  min-width: 54px !important;
  height: 54px;
  display: block;
}
</style>
