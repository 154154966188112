import { api, basePath } from "@/api/index";

const getEditsInBoundingBox = function (attributes) {
  if (attributes.isFilter) {
    let url = (basePath + "edits?bbox="+ attributes.bbox.left + "," + attributes.bbox.bottom + "," + attributes.bbox.right + "," + attributes.bbox.top + 
    (attributes.filterOptions.hasOwnProperty("isArchived") ? "&archived" : "" )+
    (attributes.filterOptions.hasOwnProperty("limit") ? "&limit=" + attributes.filterOptions.limit : "" )+
    (attributes.filterOptions.hasOwnProperty("since") ? "&since=" + attributes.filterOptions.since : "" )+
    (attributes.filterOptions.hasOwnProperty("until") ? "&until=" + attributes.filterOptions.until : ""));
    return api.get(url).then(response => {
      return response;
    }, error => { throw error; });
  } else {
    return api.get(basePath + "edits?bbox="+ attributes.bbox.left + "," + attributes.bbox.bottom + "," + attributes.bbox.right + "," + attributes.bbox.top).then(response => {
      return response;
    }, error => { throw error; });
  } 
}
const getEditDetails = function(id) {
  return api.get(basePath + "edits/" + id).then(
    response => {
      return response;
    },
    error => {
      throw error;
    }
  );
};
const getEditFeatureAttachment = function(editId, editFeatureId) {
  return api
    .get(basePath + "edits/" + editId + "/attachments/" + editFeatureId, {
      responseType: "blob"
    })
    .then(
      res => URL.createObjectURL( res.data ),
      error => {
        throw error;
      }
    );
};
const getEditSpecsFile = function (editId, format) {
  return api.get(basePath +"catalogs/" + editId + "?format=" + format).then(
    response => {
    return response.data;
  }, error => {    
     throw error;
     return [];
  });
};

export default {
  getEditsInBoundingBox,
  getEditDetails,
  getEditFeatureAttachment,
  getEditSpecsFile
};
