import {api, basePath} from "@/api/index";

const getMeasurementArea = function (pointsString) {
  return api.get(basePath + "services/area?p=" + pointsString).then(response => {
    return response;
  }, error => { throw error; });
};

const getMapcode = function (latLon) {
  return api.get(basePath + "services/mapcodes?lat=" + latLon.lat
    + "&lon=" + latLon.lng).then(response => {
    return response;
  }, error => { throw error; });
};

export default {
  getMeasurementArea,
  getMapcode
};
