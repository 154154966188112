const featurePanel = {
  namespaced: true,
  state: {
    isFeaturePanelVisible: false,
    currentFeatureInfo: {
      // Possible values for type are "Mapcode/Address/reverseGeocode/editFeature/Feature"
      type: ""
    },
    currentFeatureInfoStack: [],
    panelTitle: "",
    backButtonText: "",
    isBackVisible: false,
    featureDetails: [],
    featureAttribute: {}
  },
  mutations: {
    changeFeaturePanelVisibility(state, status) {
      state.isFeaturePanelVisible = status;
    },
    changeFeaturePanelTitle(state, panelTitle) {
      state.panelTitle = panelTitle;
    },
    changeBackButtonTitle(state, text) {
      state.backButtonText = text;
    },
    changeBackButtonVisibility(state, status) {
      state.isBackVisible = status;
    },
    changeCurrentFeatureInfo(state, currentFeatureInfo) {
      state.currentFeatureInfo = currentFeatureInfo;
    },
    changeCurrentFeatureInfoStack(state, payload) {
      if (payload.action === "push") {
        state.currentFeatureInfoStack.push(payload.value);
      } else if (payload.action === "pop") {
        state.currentFeatureInfoStack.splice(
          state.currentFeatureInfoStack.length - 1,
          1
        );
      } else if (payload.action === "clear") {
        state.currentFeatureInfoStack.splice(0);
      }
    },
    changeFeaturePopupfeatureDetails(state, featureDetails) {
      state.featureDetails = featureDetails;
    },
    changeSelectedAttribute(state, payload) {
      state.featureAttribute = payload;
    }
  },
  getters: {
    featureLinks(state, getters, rootState) {
      // split button name where , exists and remove leading or trailing spaces if any and
      // filter button such that button name equals feature name
      return rootState.home.config.buttons.filter(
        button =>
          state.currentFeatureInfo.feature &&
          button.name
            .split(",")
            .map(element => element.trim())
            .includes(state.currentFeatureInfo.feature.name)
      );
    }
  },
  actions: {
    handleExitMyMode({ commit }) {
      commit("changeFeaturePanelVisibility", false);
    },
    unselectFeatureFromList({ state, commit }, unselectionFeature) {
      state.currentFeatureInfo.feature = state.currentFeatureInfo.feature.filter(
        feature => feature.id !== unselectionFeature.id
      );
      state.currentFeatureInfo.featureAttributes.joins = state.currentFeatureInfo.featureAttributes.joins.filter(
        join => join.count !== unselectionFeature.id
      );
    },
    handlePopulateFeature({ commit, dispatch }, payload) {
      commit("changeCurrentFeatureInfoStack", { action: "clear" });
      commit("changeBackButtonVisibility", false);
      if (Array.isArray(payload.feature) && payload.feature.length > 1) {
        payload.isFeatureList = true;
        dispatch("handleCurrentFeatureDetails", payload);
      } else {
        if (Object.keys(payload).length === 10) {
          // feature is already processed
          dispatch("handleShowFeature", payload);
        } else {
          commit("changeBackButtonVisibility", payload.hasOtherFeatures);
          commit(
            "changeBackButtonTitle",
            payload.back ? payload.back.title : ""
          );
          dispatch("handleCurrentFeatureDetails", payload);
        }
      }
    },
    handleCurrentFeatureDetails({ state, commit, dispatch }, payload) {
      let featureAttributes = undefined,
        searchType = payload.searchType ? payload.searchType : "Feature",
        feature = payload.feature,
        geometry = searchType === "Feature" ? feature.geometry : undefined,
        zoomToFeature = true, // searchType === "reverseGeocode" ? false : true;
        title = payload.title
          ? payload.title
          : searchType === "Feature"
          ? feature.name
          : searchType === "editFeature"
          ? feature.name
          : searchType === "Address"
          ? (feature.location.text || feature.location.address.text).split(
              "<"
            )[0]
          : searchType === "reverseGeocode"
          ? feature.address.houseNumber + " " + feature.address.street ||
            feature.location.address.houseNumber +
              " " +
              feature.location.address.street
          : searchType,
        layerName = payload.layerName
          ? payload.layerName
          : searchType === "Feature"
          ? feature.layername
          : null,
        hasOtherFeatures = payload.hasOtherFeatures
          ? payload.hasOtherFeatures
          : false,
        isFeatureList = payload.isFeatureList ? payload.isFeatureList : false,
        back = payload.back ? payload.back : null;
      let currentFeatureInfo = {
        type: searchType,
        feature: feature,
        title: title,
        layerName: layerName,
        featureAttributes: featureAttributes,
        geometry: geometry,
        zoomToFeature: zoomToFeature,
        hasOtherFeatures: hasOtherFeatures,
        isFeatureList: isFeatureList,
        back: back
      };
      if (payload.showMultiplePins) {
        currentFeatureInfo["showMultiplePins"] = true;
      }
      switch (searchType) {
        case "Mapcode":
          featureAttributes = {
            attributes: {
              Mapcode: feature.match,
              Latitude: feature.geometry.coordinates[0],
              Longitude: feature.geometry.coordinates[1]
            },
            joins: []
          };
          geometry = {
            type: "Point",
            coordinates: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1]
            ]
          };
          break;
        case "Address":
          let replaceObj = feature.address || feature.location.address;
          Object.keys(replaceObj).forEach(key => {
            if (key === "text") {
              replaceObj["Address"] = replaceObj["text"];
              delete replaceObj["text"];
            }
          });
          featureAttributes = {
            attributes: feature.address || feature.location.address,
            joins: []
          };
          geometry = {
            type: "Point",
            coordinates: [
              feature.location.position[1],
              feature.location.position[0]
            ]
          };
          break;
        case "reverseGeocode":
          featureAttributes = {
            attributes: feature.address || feature.location.address,
            joins: []
          };
          geometry = {
            type: "Point",
            coordinates: [feature.position.lng, feature.position.lat]
          };
          break;
        case "editFeature":
          featureAttributes = {
            attributes: feature.properties,
            joins: []
          };
          geometry = {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates
          };
          break;
        case "geoRssFeature":
          featureAttributes = {
            attributes: feature.properties,
            joins: []
          };
          geometry = {
            type: feature.point ? "Point" : "Polygon",
            coordinates: feature.point.__text.split(" ")
          };
          break;
        case "multiEditFeatures":
          featureAttributes = {
            attributes: feature.properties,
            currentEditfeatures: feature.currentEditfeatures,
            featureType: feature.featureType,
            joins: feature.joins
          };
          break;
        case "Feature":
          featureAttributes = {
            attributes: {},
            joins: []
          };
          if (!isFeatureList) {
            dispatch("layer/toggleLayerIfOff", feature.layer, {
              root: true
            });
            let properties = feature.properties;
            Object.keys(properties).forEach(key => {
              /* As type of null is object,checking its value too */
              if (
                typeof properties[key] === "object" &&
                properties[key] !== null
              ) {
                featureAttributes.joins.push({
                  key: key,
                  value: properties[key],
                  count: properties[key].features.length
                });
              } else {
                featureAttributes["attributes"][key] = properties[key];
              }
            });
          } else {
            feature.forEach(feature => {
              featureAttributes.joins.push({
                key: feature.name,
                value: feature,
                count: feature.id
              });
            });
          }
          break;
      }
      if (featureAttributes !== undefined) {
        currentFeatureInfo.featureAttributes = featureAttributes;
        currentFeatureInfo.geometry = geometry;
        commit("changeCurrentFeatureInfo", currentFeatureInfo);
        dispatch("handleShowFeature", state.currentFeatureInfo);
      } else if (searchType !== "Feature") {
        commit("changeCurrentFeatureInfo", {
          type: searchType,
          feature: feature
        });
      }
    },
    handleShowFeature({ state, commit }, payload) {
      commit("changeFeaturePanelTitle", payload.title);
      commit("changeBackButtonVisibility", payload.hasOtherFeatures);
      commit("changeBackButtonTitle", payload.back ? payload.back.title : "");
      if (!state.isFeaturePanelVisible) {
        commit("changeFeaturePanelVisibility", true);
      }
    },
    handleShowJoinFeature({ state, commit, dispatch }, currentFeatureInfo) {
      commit("changeCurrentFeatureInfoStack", {
        action: "push",
        value: state.currentFeatureInfo
      });
      dispatch("handleCurrentFeatureDetails", currentFeatureInfo);
    },

    handleShowPreviousFeature({ state, commit, dispatch }) {
      commit(
        "changeCurrentFeatureInfo",
        state.currentFeatureInfoStack[state.currentFeatureInfoStack.length - 1]
      );
      commit("changeCurrentFeatureInfoStack", { action: "pop" });
      dispatch("handleShowFeature", state.currentFeatureInfo);
    }
  }
};
export default featurePanel;
