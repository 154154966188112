import Vue from "vue";
import VueI18n from "vue-i18n";
import enTranslations from "@/api/i18n_en_us";
import deTranslations from "@/api/i18n_de";
import frTranslations from "@/api/i18n_fr";

Vue.use(VueI18n);
const messages = {
  "en-US": enTranslations,
  de: deTranslations,
  fr: frTranslations
};
const i18n = new VueI18n({
  locale: navigator.language || navigator.userLanguage, // set locale
  fallbackLocale: "en-US", // set fallback locale
  messages // set locale messages
});
export default i18n;
