const enTranslations = {
  Login: "Login",
  Server: "Server",
  Username: "Username",
  Password: "Password",
  Cancel: "Cancel",
  Logging: "Logging",
  "Unable to connect to this server.": "Unable to connect to this server.",
  "Username or password is incorrect (401)":
    "Username or password is incorrect (401)",
  "No more licenses available OR License already expired (403)":
    "No more licenses available OR License already expired (403)",
  "Kerberos single sign on attempted, but a NTLM ticket was sent (426)":
    "Kerberos single sign on attempted, but a NTLM ticket was sent (426)",
  "An unknown error occurred on Server (500)":
    "An unknown error occurred on Server (500)",
  "Installed Package": "Installed Package",
  "Fetching installed package version": "Fetching installed package version",
  "LatLonGO provides the end-user with a system for distributing ":
    "LatLonGO provides the end-user with a system for distributing ",
  "Enterprise Resource Planning (ERP) information seamlessly to mobile ":
    "Enterprise Resource Planning (ERP) information seamlessly to mobile ",
  "offline sometimes connected devices (Android, iOS, Windows native ":
    "offline sometimes connected devices (Android, iOS, Windows native ",
  "tablet and phone UI) as well as web clients. It comes bundled with ":
    "tablet and phone UI) as well as web clients. It comes bundled with ",
  "HERE Maps.": "HERE Maps.",
  Close: "Close",
  "Error in fetching the installed package version.":
    "Error in fetching the installed package version.",
  Geolocate: "Geolocate",
  Undo: "Undo",
  Redo: "Redo",
  Delete: "Delete",
  More: "More",
  Home: "Home",
  Layers: "Layers",
  Edits: "Edits",
  Measurements: "Measurements",
  Back: "Back",
  Menu: "Menu",
  Print: "Print",
  Mail: "Mail",
  Settings: "Settings",
  Help: "Help",
  About: "About",
  Permalink: "Permalink",
  Save: "Save",
  Scribble: "Scribble",
  Line: "Line",
  Polygon: "Polygon",
  Select: "Select",
  Text: "Text",
  Photo: "Photo",
  Audio: "Audio",
  Routing: "Routing",
  Internals: "Internals",
  Schematics: "Schematics",
  Featurelinks: "Featurelinks",
  Done: "Done",
  "topic-name": "topic-name",
  "topic-parent-name": "topic-parent-name",
  "topic-description": "topic-description",
  Features: "Features",
  "To get more information about a feature, tap on the feature on the map. A window will open which lists the attributes coming out of the GIS system. If there are several features in the tapped area, you can access them via the 'Features' button at the top left. In the case of 'joint' features, the attribute list might include entries that point to one or more other features, instead of just showing a value. A number and a little arrow pointing right indicate how many features can be accessed by clicking on this table entry. Below you see a list of buttons that let you to initiate feature related actions. Not all of them might be available at any point of time.":
    "To get more information about a feature, tap on the feature on the map. A window will open which lists the attributes coming out of the GIS system. If there are several features in the tapped area, you can access them via the 'Features' button at the top left. In the case of 'joint' features, the attribute list might include entries that point to one or more other features, instead of just showing a value. A number and a little arrow pointing right indicate how many features can be accessed by clicking on this table entry. Below you see a list of buttons that let you to initiate feature related actions. Not all of them might be available at any point of time.",
  "This calculates a route from your current location to the selected feature.":
    "This calculates a route from your current location to the selected feature.",
  "Start navigation": "Start navigation",
  "This starts the turn-by-turn navigation.":
    "This starts the turn-by-turn navigation.",
  Directions: "Directions",
  "This opens a list of turn-by-turn instructions. Clicking on an instruction will take the map to the corresponding location.":
    "This opens a list of turn-by-turn instructions. Clicking on an instruction will take the map to the corresponding location.",
  "Current Location": "Current Location",
  "On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.":
    "On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.",
  "Route Map": "Route Map",
  "This zooms the map back to an extent that shows the complete route.":
    "This zooms the map back to an extent that shows the complete route.",
  Guidance: "Guidance",
  "Enables user to view the assets placed internally of the selected feature, such as the internal components of a substation.":
    "Enables user to view the assets placed internally of the selected feature, such as the internal components of a substation.",
  "Enables user to view the schematic diagram of a network the selected feature belongs to.":
    "Enables user to view the schematic diagram of a network the selected feature belongs to.",
  Tracing: "Tracing",
  "Typically available for Service features, this function presents the user with a trace of the signal path.\nWhen a schematic is on screen, and a particular service is selected, the Trace view is a schematic representation of the signal path, including only the components, ports and connections relevant to the selected service.\nWhen the geographical map is on screen, and a particular service is selected, the Trace view highlights the signal path along the cables which carry the selected service.":
    "Typically available for Service features, this function presents the user with a trace of the signal path.\nWhen a schematic is on screen, and a particular service is selected, the Trace view is a schematic representation of the signal path, including only the components, ports and connections relevant to the selected service.\nWhen the geographical map is on screen, and a particular service is selected, the Trace view highlights the signal path along the cables which carry the selected service.",
  "Map interaction": "Map interaction",
  "For interacting with map there are several gestures available as explained below. Furthermore, you can change the units used in the scale legend in the lower left corner of the map by tapping onto it with one finger":
    "For interacting with map there are several gestures available as explained below. Furthermore, you can change the units used in the scale legend in the lower left corner of the map by tapping onto it with one finger",
  "Feature selection": "Feature selection",
  "To select a feature, tap the feature with one finger.":
    "To select a feature, tap the feature with one finger.",
  "Continuous zoom": "Continuous zoom",
  "To continuously zoom in or out, press and hold two fingers to the screen, and increase or decrease the distance between them.":
    "To continuously zoom in or out, press and hold two fingers to the screen, and increase or decrease the distance between them.",
  "Fixed zoom": "Fixed zoom",
  "To zoom the map in a fixed amount, tap the screen twice with one finger.":
    "To zoom the map in a fixed amount, tap the screen twice with one finger.",
  Pan: "Pan",
  "To move the map, press and hold one finger to the screen, and move it in any direction.":
    "To move the map, press and hold one finger to the screen, and move it in any direction.",
  "2 finger pan": "2 finger pan",
  "To move the map, press and hold two fingers on to the screen, and move them in any direction.":
    "To move the map, press and hold two fingers on to the screen, and move them in any direction.",
  "3D Venues": "3D Venues",
  "Tap on a building (coloured purple) on the Here base map and the 3D venue map is presented. This map displays floor plans of buildings. Tap on a building and select the desired floor at top right. This view can provide routing within buildings. Specify your starting point and destination at top left.":
    "Tap on a building (coloured purple) on the Here base map and the 3D venue map is presented. This map displays floor plans of buildings. Tap on a building and select the desired floor at top right. This view can provide routing within buildings. Specify your starting point and destination at top left.",
  "The home button resets the map view to a pre-determined view port defined by the server administrator.":
    "The home button resets the map view to a pre-determined view port defined by the server administrator.",
  "Layer control": "Layer control",
  "Layer control shows all on- and -off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap or WMTS layers. In off-line mode, those layers extracted from the Mobile Server (Land Base, Electricity, Gas, Water, Telco, ...) are available as well as HERE offline layers. \n\nClick on the HERE offline button to download maps for states or whole continents.\n\nIf the metadata about any of the layers is available, a Layer Info button is available to display the layer information.":
    "Layer control shows all on- and -off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap or WMTS layers. In off-line mode, those layers extracted from the Mobile Server (Land Base, Electricity, Gas, Water, Telco, ...) are available as well as HERE offline layers. \n\nClick on the HERE offline button to download maps for states or whole continents.\n\nIf the metadata about any of the layers is available, a Layer Info button is available to display the layer information.",
  Redlining: "Redlining",
  "Redlining allows for the creation of new free-hand or structured drawings that can be associated with notes, photos, voice recordings or GPS. It also allows for the selection/display of existing redlines and for the deletion of redlines (by pushing the 'edit' button). Red lines can be named and grouped into logical placemarks and synchronised with the Mobile Server and the office GIS.":
    "Redlining allows for the creation of new free-hand or structured drawings that can be associated with notes, photos, voice recordings or GPS. It also allows for the selection/display of existing redlines and for the deletion of redlines (by pushing the 'edit' button). Red lines can be named and grouped into logical placemarks and synchronised with the Mobile Server and the office GIS.",
  "Returns to main menu.": "Returns to main menu.",
  "Reverts the previous step.": "Reverts the previous step.",
  "For free-hand drawing.": "For free-hand drawing.",
  "Map Gestures": "Map Gestures",
  "To manipulate the map gestures like pan, zoom...":
    "To manipulate the map gestures like pan, zoom...",
  "Stores photos taken at the user's location from the device camera.":
    "Stores photos taken at the user's location from the device camera.",
  "The Geolocate (compass) button puts the device into GPS lock for a moving map display.":
    "The Geolocate (compass) button puts the device into GPS lock for a moving map display.",
  Share: "Share",
  "Allows for sharing via mail, Twitter, Facebook, ...":
    "Allows for sharing via mail, Twitter, Facebook, ...",
  "Save all edits in the current session.":
    "Save all edits in the current session.",
  "Measurements include coordinates, running distance and area.":
    "Measurements include coordinates, running distance and area.",
  cancel: "cancel",
  undo: "undo",
  Coordinate: "Coordinate",
  "Lat/Lon read-out (configurable)": "Lat/Lon read-out (configurable)",
  Distance: "Distance",
  "Accumulated length measurement": "Accumulated length measurement",
  Area: "Area",
  "Area measurement": "Area measurement",
  geolocate_: "geolocate_",
  "The guidance feature enables the user to specify a feature (or just a desired location), that the device will guide them to by means of an on screen pointer - they just follow the arrow which points from their current location to the guide target.":
    "The guidance feature enables the user to specify a feature (or just a desired location), that the device will guide them to by means of an on screen pointer - they just follow the arrow which points from their current location to the guide target.",
  share: "share",
  Search: "Search",
  "Searches for feature attributes and street addresses. You can also type in geo coordinates (e.g. -37.813824, 144.960397) to center the map at that location.":
    "Searches for feature attributes and street addresses. You can also type in geo coordinates (e.g. -37.813824, 144.960397) to center the map at that location.",
  Sync: "Sync",
  "Synchronises device with server or allows for input of server connection detail if not connected.":
    "Synchronises device with server or allows for input of server connection detail if not connected.",
  help: "help",
  "Invokes this Help System.": "Invokes this Help System.",
  "Allows for printing the current map view screenshot":
    "Allows for printing the current map view screenshot",
  Scale: "Scale",
  Zoom: "Zoom",
  "Capturing is not supported.": "Capturing is not supported.",
  "Overlay Layers": "Overlay Layers",
  Version: "Version",
  Source: "Source",
  Extractor: "Extractor",
  Extracted: "Extracted",
  Updated: "Updated",
  "No Information Available": "No Information Available",
  "Base Layers": "Base Layers",
  Mapcode: "Mapcode",
  coordinate: "coordinate",
  Car: "Car",
  Public: "Public",
  Truck: "Truck",
  Pedestrian: "Pedestrian",
  "Route Directions": "Route Directions",
  "Route overview": "Route overview",
  Time: "Time",
  "Unable to retrive information.": "Unable to retrive information.",
  "Location timeout error.": "Location timeout error.",
  "Location unavailable.": "Location unavailable.",
  "Only secure origins are allowed.": "Only secure origins are allowed.",
  "Location permission denied!": "Location permission denied!",
  "Unknown location error.": "Unknown location error.",
  "Base layer included in permalink is not present in current user's configuration.":
    "Base layer included in permalink is not present in current user's configuration.",
  "Checking for current position...": "Checking for current position...",
  "Routing lost position. Try again": "Routing lost position. Try again",
  "Calculating route...": "Calculating route...",
  "Route calculation failed. The path between your position and the specified destination could not be determined.":
    "Route calculation failed. The path between your position and the specified destination could not be determined.",
  "The input is required.": "The input is required.",
  "The input allows decimal values only.": "The input allows decimal values only.",
  "This field doesn't allow special characters.":
    "This field doesn't allow special characters.",
  "Username should not contain special characters / spaces":
    "Username should not contain special characters / spaces",
  "This field doesn't allow starting and trailing whitespaces.":
    "This field doesn't allow starting and trailing whitespaces.",
  "Number of digits should not exceed 15":
    "Number of digits should not exceed 15",
  "Error: Network Error": "Error: Network Error",
  Others: "Others",
  "Clear All": "Clear All",
  "Error in fetching features at your tapped location.": "Error in fetching features at your tapped location.",
  Download: "Download",
  "Online Layer": "Online Layer",
  "Parse Error": "Parse Error",
  "Invalid URL": "Invalid URL",
  "The Home button resets the map view to a pre-determined view port and layers defined by the server administrator.The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator. The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator.":"The Home button resets the map view to a pre-determined view port and layers defined by the server administrator.The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator. The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator.",
  "On activating Geolocate, the button turns green and your current position is shown on the map. In case, current position is not found or the GPS signal is lost, user is informed by displaying appropriate message.":"On activating Geolocate, the button turns green and your current position is shown on the map. In case, current position is not found or the GPS signal is lost, user is informed by displaying appropriate message.",
  "Layer Control shows all on-line and off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap or WMTS layers.In off-line mode, those layers extracted from the GIS (Land Base, Electricity, Gas, Water, Telco etc…) are available.":"Layer Control shows all on-line and off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap or WMTS layers.In off-line mode, those layers extracted from the GIS (Land Base, Electricity, Gas, Water, Telco etc…) are available.",
  "To get more information about a feature, click on the feature on the map. A map marker showing the feature type will be displayed.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.":"To get more information about a feature, click on the feature on the map. A map marker showing the feature type will be displayed.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.",
  "The edits synced by field users can be viewed by clicking on the Redlines button. It displays the redlines toolbar. On clicking the Redlines button on the redline toolbar displays the list of redlines in the area, along with the users.Clicking on a redline in the list displays all the contained redline objects on the map. If a photo/audio is there as part of redlines, the corresponding icons (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) are displayed on the map. User can click on the icon and see the photo, listen to the audio or view the form.":"The edits synced by field users can be viewed by clicking on the Redlines button. It displays the redlines toolbar. On clicking the Redlines button on the redline toolbar displays the list of redlines in the area, along with the users.Clicking on a redline in the list displays all the contained redline objects on the map. If a photo/audio is there as part of redlines, the corresponding icons (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) are displayed on the map. User can click on the icon and see the photo, listen to the audio or view the form.",
  "Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.":"Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.",
  "Units Toggle: Toggles the units from Metric to Imperial and vice versa. Affects scale bar units, distance/area units in measurements and the distance units in route calculations and manoeuvres.":"Units Toggle: Toggles the units from Metric to Imperial and vice versa. Affects scale bar units, distance/area units in measurements and the distance units in route calculations and manoeuvres.",
  "Search bar searches for features and street addresses. For features, all layers, no matter whether currently visible or not, are searched. If you select from the result list a feature which belongs to a layer which is currently switched off, that layer will automatically be switched on before highlighting the location of the feature.User can also search for mapcodes by providing valid mapcode in the search bar. See the Mapcodes for more information.":"Search bar searches for features and street addresses. For features, all layers, no matter whether currently visible or not, are searched. If you select from the result list a feature which belongs to a layer which is currently switched off, that layer will automatically be switched on before highlighting the location of the feature.User can also search for mapcodes by providing valid mapcode in the search bar. See the Mapcodes for more information.",
  "This calculates a route from your current location to the selected feature/address.Car Routing: A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.Truck Routing This calculates the truck route from current location to the location of selected feature/address.Public Transit RoutingThis calculates the public transit route from current location to the location of selected feature/address. Pedestrian Routing This calculates the pedestrian route from current location to the location of selected feature/address.":"This calculates a route from your current location to the selected feature/address.Car Routing: A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.Truck Routing This calculates the truck route from current location to the location of selected feature/address.Public Transit RoutingThis calculates the public transit route from current location to the location of selected feature/address. Pedestrian Routing This calculates the pedestrian route from current location to the location of selected feature/address.",
  "Feature links are displayed as text buttons at the bottom of the attributes window. Feature links allow open an external application and to send data from LatLonGO to an external application. Feature links can be defined to automatically send data, e.g. attributes and location associated with selected feature.Feature links are defined on the LatLonGO mobile server.":"Feature links are displayed as text buttons at the bottom of the attributes window. Feature links allow open an external application and to send data from LatLonGO to an external application. Feature links can be defined to automatically send data, e.g. attributes and location associated with selected feature.Feature links are defined on the LatLonGO mobile server.",
  "This shows the Street Level view at the location of the selected feature/address. This feature is not yet available for all countries.":"This shows the Street Level view at the location of the selected feature/address. This feature is not yet available for all countries.",
  "This starts the turn-by-turn navigation.":"This starts the turn-by-turn navigation.",
  "On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.":"On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.",
  "This zooms the map back to an extent that shows the complete route.":"This zooms the map back to an extent that shows the complete route.",
  "Enables user to view the assets placed internally of the selected feature, such as the internal components of a substation.":"Enables user to view the assets placed internally of the selected feature, such as the internal components of a substation.",
  "All the measurements can be cleared from the map by clicking on the Clear All button on the measurement toolbar.":"All the measurements can be cleared from the map by clicking on the Clear All button on the measurement toolbar",
  "Clear all":"Clear all",
  "The last measurement action can be undone by clicking on the Undo button on the measurement toolbar.":"The last measurement action can be undone by clicking on the Undo button on the measurement toolbar.",
  "The last measurement action, which was undone using Undo button can be redone by clicking on the Redo button on the measurement toolbar.":"The last measurement action, which was undone using Undo button can be redone by clicking on the Redo button on the measurement toolbar.",
  "User can measure the coordinates at a clicked point by selecting the Coordinate option on the measurement toolbar and clicking at a point on the map.A popover is displayed at the clicked point on the map displaying the Lat/Long values.The Lat, Long values are also populated in the Lat, Long labels on the measurement toolbar.":"User can measure the coordinates at a clicked point by selecting the Coordinate option on the measurement toolbar and clicking at a point on the map.A popover is displayed at the clicked point on the map displaying the Lat/Long values.The Lat, Long values are also populated in the Lat, Long labels on the measurement toolbar.",
  "User can measure the distance between two or more points by selecting the Distance option on the measurement toolbar and clicking on two or more points on the map.A line is drawn between consective points on the map and the individual distance value is displayed at each segment on mouse over. The accumulated distance value is displayed at each vertex on mouse over. The total distance value is populated in the Distance label on the measurement toolbar.":"User can measure the distance between two or more points by selecting the Distance option on the measurement toolbar and clicking on two or more points on the map.A line is drawn between consective points on the map and the individual distance value is displayed at each segment on mouse over. The accumulated distance value is displayed at each vertex on mouse over. The total distance value is populated in the Distance label on the measurement toolbar.",
  "Measure coordinates":"Measure coordinates",
  "Measure distance":"Measure distance",
  "Measure Area":"Measure Area",
  "User can measure the area between three or more points by selecting the Area option on the measurement toolbar and clicking on three or more points on the map.A polygon is drawn between the points on the map and accumulated distance value is displayed at each vertex on mouse over.The total area value is populated in the Area label on the measurement toolbar.":"User can measure the area between three or more points by selecting the Area option on the measurement toolbar and clicking on three or more points on the map.A polygon is drawn between the points on the map and accumulated distance value is displayed at each vertex on mouse over.The total area value is populated in the Area label on the measurement toolbar.",
  "Prints the screenshot of the current map view.":"Prints the screenshot of the current map view.",
  "Generates the permalink to the current map view, which can be copied and shared.":"Generates the permalink to the current map view, which can be copied and shared.",
  "Shares the permalink to the current view in an email.":"Shares the permalink to the current view in an email.",
  "Gives the information about the installed web app package.":"Gives the information about the installed web app package.",
  "Car Routing":"Car Routing",
  "A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.":"A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.",
  "Truck Routing":"Truck Routing",
  "This calculates the truck route from current location to the location of selected feature/address.":"This calculates the truck route from current location to the location of selected feature/address.",
  "Public Transit Routing": "Public Transit Routing",
  "This calculates the public transit route from current location to the location of selected feature/address.":"This calculates the public transit route from current location to the location of selected feature/address.",
  "Pedestrian Routing":"Pedestrian Routing",
  "This calculates the pedestrian route from current location to the location of selected feature/address.": "This calculates the pedestrian route from current location to the location of selected feature/address.",
  "Invalid API key": "Invalid API key"


};
export default enTranslations;
