import HereUtil from "@/util/here";
import apiEdit from "@/api/edit";

const edit = {
  namespaced: true,
  state: {
    isReadonlyEditDialogVisible: false,
    filterMenu: false,
    isEditsListVisible: true,
    editNameList: [],
    currentSelectedEdit: {
      name: null,
      id: null
    },
    currentEditFeatures: null,
    currentSelectedEditFeature: null,
    since: null,
    until: null,
    limit: 20,
    isLimit: false,
    getArchivedResults: false,
    isFiltersSaveEnabled: true,
    currentEditAttachment: undefined,
    areFiltersValid: true,
    filterErrorMessage: null,
    editfillColor: "#FF0000",
    editFillColorForAudioAndPhotoIcons: "#000000",
    editFilterLimit: 30
  },
  mutations: {
    changeIsReadonlyEditDialogVisible(state, value) {
      state.isReadonlyEditDialogVisible = value;
    },
    changeFilterMenu(state, value) {
      state.filterMenu = value;
    },
    changeIsEditsListVisible(state, value) {
      state.isEditsListVisible = value;
    },
    changeSelectedFeature(state, value) {
      state.currentSelectedEditFeature = value;
    },
    changeEditNameList(state, payload) {
      if (payload.action === "replace") {
        state.editNameList = payload.value;
      } else if (payload.action === "clear") {
        state.editNameList.splice(0);
      } else if (payload.action === "moveToFront") {
        let first = state.editNameList.splice(payload.value, 1);
        state.editNameList.sort();
        state.editNameList = first.concat(state.editNameList);
      }
    },
    changeCurrentSelectedEdit(state, value) {
      state.currentSelectedEdit = value;
    },
    changeCurrentEditFeatures(state, value) {
      state.currentEditFeatures = value;
    },
    changeSince(state, value) {
      state.since = value;
    },
    changeUntil(state, value) {
      state.until = value;
    },
    changeLimit(state, value) {
      state.limit = value;
    },
    changeIsLimit(state, value) {
      state.isLimit = value;
    },
    changeGetArchivedResults(state, value) {
      state.getArchivedResults = value;
    },
    changeIsFiltersSaveEnabled(state, value) {
      state.isFiltersSaveEnabled = value;
    },
    changeCurrentEditAttachment(state, value) {
      state.currentEditAttachment = value;
    },
    changeAreFiltersValid(state, value) {
      state.areFiltersValid = value;
    },
    changeFilterErrorMessage(state, value) {
      state.filterErrorMessage = value;
    },
    setEditFilterLimit(state,value) {
      state.editFilterLimit = value;
    }
  },
  actions: {
    handleEnterEdit({ commit, dispatch }) {
      commit("changeIsReadonlyEditDialogVisible", true);
      dispatch("handleFetchEditsInBoundingBox", false);
    },
    handleExitMyMode({ commit, dispatch }) {
      commit("changeIsReadonlyEditDialogVisible", false);
      commit("changeCurrentSelectedEdit", { id: null, name: null });
      commit("changeIsEditsListVisible", true);
      commit("changeCurrentEditFeatures", null);
      commit("changeAreFiltersValid", true);
      commit("changeFilterMenu", false);
    },
    handleSelectedEditName({ state, commit, dispatch }, item) {
      dispatch("handleRemoveEditFromMap");
      if (item.name !== state.currentSelectedEdit.name) {
        commit("changeCurrentSelectedEdit", item);
        if (item.name !== null && item.index !== 0) {
          commit("changeEditNameList", {
            action: "moveToFront",
            value: item.index
          });
        }
        apiEdit.getEditDetails(item.id).then(
          response => {
            let payload = {};
            let editsSpecfile = {};
            if (response.data) {
              payload = { editsData: response.data };
              if (response.data.edit.configName !== "MARKUPS_ONLY") {
                apiEdit
                  .getEditSpecsFile(response.data.edit.configId, "json")
                  .then(
                    result => {
                      editsSpecfile = result;
                      payload = {
                        editsData: response.data,
                        editsSpecfile: editsSpecfile
                      };
                      dispatch("handleShowEditOnMap", payload);
                    },
                    () => {
                      window.getApp.$insProgress.finish();
                    }
                  );
              } else {
                dispatch("handleShowEditOnMap", payload);
              }
            }
          },
          () => {
            window.getApp.$insProgress.finish();
          }
        );
      }
    },
    handleShowEditOnMap({ commit }, edit) {
      commit("changeCurrentEditFeatures", edit);
    },
    handleRemoveEditFromMap({ rootState, state, dispatch, commit }) {
      if (rootState.featurePanel.isFeaturePanelVisible) {
        dispatch("handleBack", null, { root: true });
      }
      if (state.currentEditFeatures !== null) {
        commit("changeCurrentSelectedEdit", { id: null, name: null });
        commit("changeCurrentEditFeatures", null);
      }
    },
    handleEditCount({state, commit, dispatch}) {
      let hereUtil = new HereUtil();
      let bbox = hereUtil.getCurrentMapBounds();
      let attributes = {
        bbox: bbox
      }
      apiEdit.getEditsInBoundingBox(attributes).then (response => {
        commit("setEditFilterLimit", response.data.length);
      })
    },
    handleFetchEditsInBoundingBox({ state, commit, dispatch }, isrefresh) {
      let hereUtil = new HereUtil();
      let bbox = hereUtil.getCurrentMapBounds();
      let refreshedEditList = [];
      if (state.currentSelectedEdit.id !== null) {
        refreshedEditList = [state.currentSelectedEdit];
      }
      commit("changeEditNameList", { action: "clear" });
      let attributes = {
        bbox: bbox,
        isFilter:
          state.filterMenu ||
          localStorage["edits-filter-options"] !== (null || undefined),
        filterOptions: state.filterMenu
          ? {
              ...(state.since !== null && { since: state.since }),
              ...(state.until !== null && { until: state.until }),
              ...(state.isLimit && { limit: state.limit }),
              ...(state.getArchivedResults && {
                isArchived: state.getArchivedResults
              })
            }
          : localStorage["edits-filter-options"] !== (null || undefined)
          ? JSON.parse(localStorage["edits-filter-options"])
          : {}
      };
      apiEdit.getEditsInBoundingBox(attributes).then(response => {
        if (response.data.length > 0) {
          if (refreshedEditList.length > 0) {
            response.data.splice(
              response.data.findIndex(
                edit => edit.id === refreshedEditList[0].id
              ),
              1
            );
            refreshedEditList = [...refreshedEditList, ...response.data];
          } else {
            refreshedEditList = response.data;
          }
        }
        commit("changeEditNameList", {
          action: "replace",
          value: refreshedEditList
        });
        if (isrefresh) {
          // save only when filters are changed.
          localStorage["edits-filter-options"] = JSON.stringify(
            attributes.filterOptions
          );
        }
        if (!state.isFiltersSaveEnabled) {
          dispatch("handleClearFiltersData");
        }
        if (state.currentSelectedEdit.name !== null) {
          commit("changeEditNameList", {
            action: "moveToFront",
            value: refreshedEditList.findIndex(
              edit => edit.name === state.currentSelectedEdit.name
            )
          });
        }
        if (refreshedEditList.length > 0) {
          commit("changeIsEditsListVisible", true);
        } else {
          commit("changeIsEditsListVisible", false);
        }
      });
    },
    handleValidateSinceUntilFilters({ commit }, payload) {
      commit("changeAreFiltersValid", false);
      let isValid = false;
      let error = null;
      let until, since;
      let now = payload.currentDate;
      if (payload.until !== null) {
        let untilDateVal = payload.until.substring(
          0,
          payload.until.indexOf("T")
        );
        let untilTimeVal = payload.until.slice(
          payload.until.indexOf("T") + 1,
          payload.until.indexOf("Z")
        );
        until = new Date(untilDateVal + " " + untilTimeVal);
      }
      if (payload.since !== null) {
        let sinceDateVal = payload.since.substring(
          0,
          payload.since.indexOf("T")
        );
        let sinceTimeVal = payload.since.slice(
          payload.since.indexOf("T") + 1,
          payload.since.indexOf("Z")
        );
        since = new Date(sinceDateVal + " " + sinceTimeVal);
      }
      if (payload.since !== null && payload.until !== null) {
        if (until <= now) {
          if (since <= until) {
            isValid = true;
            error = null;
          } else {
            error = "Since date time should be less than Until date time";
            isValid = false;
          }
        } else {
          isValid = false;
          if (since <= now && since < until) {
            error = "Until date time should be less than current date time";
          } else if (since > now && since < until) {
            error =
              "Since and Until date time should be less than current date time";
          } else if (since > until) {
            error =
              "Until date time should be less than current date time and Since date time should be less than Until date time";
          }
        }
      } else if (payload.since === null && payload.until === null) {
        isValid = true;
        error = null;
      } else if (payload.since !== null) {
        if (since <= now) {
          isValid = true;
          error = null;
        } else {
          error = "Since date time should be less than current date time";
          isValid = false;
        }
      } else if (payload.until !== null) {
        if (until <= now) {
          isValid = true;
          error = null;
        } else {
          error = "Until date time should be less than current date time";
          isValid = false;
        }
      }
      commit("changeAreFiltersValid", isValid);
      commit("changeFilterErrorMessage", error);
      if (error !== null) {
        window.getApp.$emit("FLASH_MESSAGE", {
          text: error,
          type: "error"
        });
      }
    },
    handleClearFiltersData({ commit }) {
      commit("changeSince", null);
      commit("changeUntil", null);
      commit("changeIsLimit", false);
      commit("changeLimit", "20");
      commit("changeGetArchivedResults", false);
    }
  }
};
export default edit;
