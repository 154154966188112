import { api, basePath } from "@/api/index";
import X2JS from "x2js";

const checkForRssFeeds = url => {
  let processedUrl = url;
  if (url.startsWith(api.defaults.baseURL)) {
    processedUrl = url.substring(api.defaults.baseURL.length);
  } else {
    processedUrl = basePath + "services/proxy?url=" + url;
  }
  // Fetching RSS using processed URL.
  return api.get(processedUrl).then(
    response => {
      let x2js = new X2JS();
      return x2js.xml2js(response.data);
    },
    error => {
      return error;
    }
  );
};

const getlayerFeeds = function(layerUrl) {
  return api.get(basePath + "/services/proxy?url=" + layerUrl.url).then(
    response => {
      return response.data;
    },
    error => {
      throw error;
    }
  );
};

export default {
  checkForRssFeeds,
  getlayerFeeds
};
