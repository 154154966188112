const deTranslations = {
  Login: "einloggen",
  Server: "Server",
  Username: "Nutzername",
  Password: "Passwort",
  Cancel: "Abbrechen",
  Logging: "Protokollierung",
  "Unable to connect to this server.":
    "Es konnte keine Verbindung mit diesem Server hergestellt werden.",
  "Username or password is incorrect (401)":
    "Benutzername oder Kennwort ist falsch (401)",
  "No more licenses available OR License already expired (403)":
    "Keine weiteren Lizenzen verfügbar ODER Lizenz bereits abgelaufen (403)",
  "Kerberos single sign on attempted, but a NTLM ticket was sent (426)":
    "Kerberos Einzelzeichen bei versuchsweise, aber ein NTLM-Ticket wurde gesendet (426)",
  "An unknown error occurred on Server (500)":
    "Auf Server ist ein unbekannter Fehler aufgetreten (500)",
  "Installed Package": "Installiertes Paket",
  "Fetching installed package version":
    "Abrufen der installierten Paketversion",
  "LatLonGO provides the end-user with a system for distributing ":
    "LatLonGO bietet dem Endanwender ein System zur ",
  "Enterprise Resource Planning (ERP) information seamlessly to mobile ":
    "Enterprise Resource Planning (ERP)-Informationen nahtlos auf Mobilgeräte ",
  "offline sometimes connected devices (Android, iOS, Windows native ":
    "Offline manchmal verbundene Geräte (Android, iOS, Windows native ",
  "tablet and phone UI) as well as web clients. It comes bundled with ":
    "Tablet- und Telefon-UI) sowie Web-Clients. Es kommt gebündelt mit ",
  "HERE Maps.": "HIER Karten.",
  Close: "Schließen",
  "Error in fetching the installed package version.":
    "Fehler beim Abrufen der installierten Paketversion.",
  Geolocate: "Geolocate",
  Undo: "Rückgängig",
  Redo: "Wiederholen",
  Delete: "Löschen",
  More: "Mehr",
  Home: "Startseite",
  Layers: "Schichten",
  Edits: "Bearbeitungen",
  Measurements: "Messungen",
  Back: "Zurück",
  Menu: "Menü",
  Print: "Drucken",
  Mail: "Mail",
  Settings: "Einstellungen",
  Help: "Hilfe",
  About: "Über",
  Permalink: "Permalink",
  Save: "Speichern",
  Scribble: "Scribble",
  Line: "Linie",
  Polygon: "Polygon",
  Select: "Auswählen",
  Text: "Text",
  Photo: "Foto",
  Audio: "Audio",
  Routing: "Routing",
  Internals: "Interna",
  Schematics: "Schaltpläne",
  Featurelinks: "Featurelinks",
  Done: "fertig",
  "topic-name": "Themenname",
  "topic-parent-name": "topic-parent-name",
  "topic-description": "Themenbeschreibung",
  Features: "Funktionen",
  "To get more information about a feature, tap on the feature on the map. A window will open which lists the attributes coming out of the GIS system. If there are several features in the tapped area, you can access them via the 'Features' button at the top left. In the case of 'joint' features, the attribute list might include entries that point to one or more other features, instead of just showing a value. A number and a little arrow pointing right indicate how many features can be accessed by clicking on this table entry. Below you see a list of buttons that let you to initiate feature related actions. Not all of them might be available at any point of time.":
    "Um weitere Informationen zu einem Feature zu erhalten, tippen Sie auf das Feature auf der Karte. Es wird ein Fenster geöffnet, in dem die Attribute aufgeführt werden, die aus dem GIS-System stammen. Wenn sich im Get-tipp-Bereich mehrere Funktionen befinden, können Sie über die Schaltfläche 'Features' oben links darauf zugreifen. Bei 'gemeinsamen' Features kann die Attributliste Einträge enthalten, die auf ein oder mehrere andere Features verweisen, anstatt nur einen Wert anzuzeigen. Eine Zahl und ein kleiner Pfeil nach rechts zeigen, zeigen an, auf wie viele Features zugegriffen werden können, indem Sie auf diesen Tabelleneintrag klicken. Im Folgenden sehen Sie eine Liste von Schaltflächen, mit denen Sie funktionsbezogene Aktionen initiieren können. Möglicherweise sind nicht alle von ihnen zu einem beliebigen Zeitpunkt verfügbar.",
  "This calculates a route from your current location to the selected feature.":
    "Dadurch wird eine Route von Ihrer aktuellen Position zum ausgewählten Feature berechnet.",
  "Start navigation": "Navigation starten",
  "This starts the turn-by-turn navigation.":
    "Dadurch wird die Turn-by-Turn-Navigation gestartet.",
  Directions: "Wegbeschreibungen",
  "This opens a list of turn-by-turn instructions. Clicking on an instruction will take the map to the corresponding location.":
    "Dadurch wird eine Liste der Turn-by-Turn-Anweisungen geöffnet. Wenn Sie auf eine Anweisung klicken, wird die Karte an den entsprechenden Ort gebracht.",
  "Current Location": "Aktueller Standort",
  "On activating geolocate, puts the device into GPS lock indicating that your current position is shown on the map and map will follow your position when you move.":
    "Wenn Sie Geolocate aktivieren, wird das Gerät in die GPS-Sperre versetzt, die angibt, dass Ihre aktuelle Position auf der Karte angezeigt wird und die Karte Ihrer Position folgt, wenn Sie sich bewegen.",
  "Route Map": "Routenkarte",
  "This zooms the map back to an extent that shows the complete route.":
    "Dadurch wird die Karte auf eine Ausdehnung zurückgezoomt, die die gesamte Route anzeigt.",
  Guidance: "Anleitung",
  "Enables user to view the assets placed internally of the selected feature, such as the internal components of a substation.":
    "Ermöglicht dem Benutzer, die intern platzierten Assets des ausgewählten Features anzuzeigen, z. B. die internen Komponenten einer Unterstation.",
  "Enables user to view the schematic diagram of a network the selected feature belongs to.":
    "Ermöglicht dem Benutzer, das Schaltplandiagramm eines Netzwerks anzuzeigen, zu dem das ausgewählte Feature gehört.",
  Tracing: "Ablaufverfolgung",
  "Typically available for Service features, this function presents the user with a trace of the signal path.\nWhen a schematic is on screen, and a particular service is selected, the Trace view is a schematic representation of the signal path, including only the components, ports and connections relevant to the selected service.\nWhen the geographical map is on screen, and a particular service is selected, the Trace view highlights the signal path along the cables which carry the selected service.":
    "In der Regel für Service-Features verfügbar, stellt diese Funktion dem Benutzer eine Ablaufverfolgung des Signalpfads zur Verfügung. Wenn ein Schaltplan auf dem Bildschirm ist und ein bestimmter Dienst ausgewählt ist, ist die Ablaufverfolgungsansicht eine schematische Darstellung des Signalpfads, einschließlich nur der Komponenten, Ports und Verbindungen, die für den ausgewählten Dienst relevant sind. , und ein bestimmter Dienst ausgewählt ist, hebt die Trace-Ansicht den Signalpfad entlang der Kabel hervor, die den ausgewählten Dienst übertragen.",
  "Map interaction": "Karteninteraktion",
  "For interacting with map there are several gestures available as explained below. Furthermore, you can change the units used in the scale legend in the lower left corner of the map by tapping onto it with one finger":
    "Für die Interaktion mit der Karte stehen mehrere Gesten zur Verfügung, wie unten erläutert. Darüber hinaus können Sie die Einheiten ändern, die in der Maßstabslegende in der unteren linken Ecke der Karte verwendet werden, indem Sie mit einem Finger darauf tippen.",
  "Feature selection": "Feature-Auswahl",
  "To select a feature, tap the feature with one finger.":
    "Um ein Feature auszuwählen, tippen Sie mit einem Finger auf das Feature.",
  "Continuous zoom": "Kontinuierlicher Zoom",
  "To continuously zoom in or out, press and hold two fingers to the screen, and increase or decrease the distance between them.":
    "Um kontinuierlich zu vergrößern oder zu verkleinern, halten Sie zwei Finger auf den Bildschirm, und vergrößern oder verringern Sie den Abstand zwischen ihnen.",
  "Fixed zoom": "Fester Zoom",
  "To zoom the map in a fixed amount, tap the screen twice with one finger.":
    "Um die Karte in einem festen Betrag zu zoomen, tippen Sie zweimal mit einem Finger auf den Bildschirm.",
  Pan: "Pfanne",
  "To move the map, press and hold one finger to the screen, and move it in any direction.":
    "Um die Karte zu verschieben, halten Sie einen Finger auf den Bildschirm, und bewegen Sie sie in eine beliebige Richtung.",
  "2 finger pan": "2 Fingerpfanne",
  "To move the map, press and hold two fingers on to the screen, and move them in any direction.":
    "Um die Karte zu verschieben, drücken und halten Sie zwei Finger auf den Bildschirm, und bewegen Sie sie in eine beliebige Richtung.",
  "3D Venues": "3D-Orte",
  "Tap on a building (coloured purple) on the Here base map and the 3D venue map is presented. This map displays floor plans of buildings. Tap on a building and select the desired floor at top right. This view can provide routing within buildings. Specify your starting point and destination at top left.":
    "Tippen Sie auf ein Gebäude (violett) auf der Hier-Basiskarte und die 3D-Veranstaltungskarte wird angezeigt. Diese Karte zeigt Grundrisse von Gebäuden. Tippen Sie auf ein Gebäude und wählen Sie die gewünschte Etage oben rechts aus. Diese Ansicht kann Routing innerhalb von Gebäuden bereitstellen. Geben Sie Ihren Startpunkt und Ihr Ziel oben links an.",
  "The home button resets the map view to a pre-determined view port defined by the server administrator.":
    "Die Home-Schaltfläche setzt die Kartenansicht auf einen vom Serveradministrator definierten vorab festgelegten Ansichtsport zurück.",
  "Layer control": "Layer-Steuerung",
  "Layer control shows all on- and -off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap or WMTS layers. In off-line mode, those layers extracted from the Mobile Server (Land Base, Electricity, Gas, Water, Telco, ...) are available as well as HERE offline layers. \n\nClick on the HERE offline button to download maps for states or whole continents.\n\nIf the metadata about any of the layers is available, a Layer Info button is available to display the layer information.":
    "Die Layer-Steuerung zeigt alle verfügbaren On- und -Off-Line-Datensätze an. Im Online-Modus könnten diese HIER Karten wie HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day / Night, HERE Satellite, HERE Fleet Map, HERE Traffic , HERE Congestion Zones, HERE POIs, HERE Terrain, MapQuest, OpenStreetMap oder WMTS Layer enthalten. Im Offline-Modus sind die schichten, die aus dem Mobile Server extrahiert werden (Land Base, Electricity, Gas, Water, Telco, ...) sowie HIER Offline-Layer verfügbar. Klicken Sie auf die Schaltfläche 'HIER offline', um Karten für Staaten oder ganze Kontinente herunterzuladen.",
  Redlining: "Redlining",
  "Redlining allows for the creation of new free-hand or structured drawings that can be associated with notes, photos, voice recordings or GPS. It also allows for the selection/display of existing redlines and for the deletion of redlines (by pushing the 'edit' button). Red lines can be named and grouped into logical placemarks and synchronised with the Mobile Server and the office GIS.":
    "Redlining ermöglicht die Erstellung neuer freihändiger oder strukturierter Zeichnungen, die mit Notizen, Fotos, Sprachaufzeichnungen oder GPS verknüpft werden können. Es ermöglicht auch die Auswahl/Anzeige vorhandener Redlines und das Löschen von Redlines (durch Drücken der Schaltfläche 'Bearbeiten'). Rote Linien können benannt und in logische Ortsmarken gruppiert und mit dem Mobile Server und dem Office-GIS synchronisiert werden.",
  "Returns to main menu.": "Kehrt zum Hauptmenü zurück.",
  "Reverts the previous step.": "Kehrt den vorherigen Schritt zurück.",
  "For free-hand drawing.": "Zum Freihandzeichnen.",
  "Map Gestures": "Karte Gesten",
  "To manipulate the map gestures like pan, zoom...":
    "Um die Kartengesten wie Pan zu bearbeiten, zoomen Sie...",
  "Stores photos taken at the user's location from the device camera.":
    "Speichert Fotos, die am Standort des Benutzers aufgenommen wurden, von der Gerätekamera.",
  "The Geolocate (compass) button puts the device into GPS lock for a moving map display.":
    "Mit der Schaltfläche Geolocate (Kompass) wird das Gerät für eine bewegte Kartenanzeige in die GPS-Sperre versetzt.",
  Share: "Freigeben",
  "Allows for sharing via mail, Twitter, Facebook, ...":
    "Ermöglicht das Teilen per Mail, Twitter, Facebook, ...",
  "Save all edits in the current session.":
    "Speichern Sie alle Änderungen in der aktuellen Sitzung.",
  "Measurements include coordinates, running distance and area.":
    "Die Messungen umfassen Koordinaten, Laufstrecke und Fläche.",
  cancel: "Abbrechen",
  undo: "Rückgängig",
  Coordinate: "Koordinieren",
  "Lat/Lon read-out (configurable)": "Lat/Lon-Auslesung (konfigurierbar)",
  Distance: "Distanz",
  "Accumulated length measurement": "Akkumulierte Längenmessung",
  Area: "Bereich",
  "Area measurement": "Flächenmessung",
  geolocate_: "geolocate_",
  "The guidance feature enables the user to specify a feature (or just a desired location), that the device will guide them to by means of an on screen pointer - they just follow the arrow which points from their current location to the guide target.":
    "Die Anleitungsfunktion ermöglicht es dem Benutzer, ein Feature (oder nur eine gewünschte Position) anzugeben, zu dem das Gerät sie mittels eines Bildschirmzeigers führt - er folgt einfach dem Pfeil, der von seiner aktuellen Position auf das Führungsziel zeigt.",
  share: "Freigeben",
  Search: "Suche",
  "Searches for feature attributes and street addresses. You can also type in geo coordinates (e.g. -37.813824, 144.960397) to center the map at that location.":
    "Sucht nach Feature-Attributen und Straßenadressen. Sie können auch Geokoordinaten eingeben (z. B. -37.813824, 144.960397), um die Karte an dieser Position zu zentrieren.",
  Sync: "Sync",
  "Synchronises device with server or allows for input of server connection detail if not connected.":
    "Synchronisiert Gerät mit Server oder ermöglicht die Eingabe von Serververbindungsdetails, wenn nicht verbunden.",
  help: "Hilfe",
  "Invokes this Help System.": "Ruft dieses Hilfesystem auf.",
  "Allows for printing the current map view screenshot":
    "Ermöglicht das Drucken des aktuellen Kartenansichts-Screenshots",
  Scale: "Skala",
  Zoom: "Zoom",
  "Capturing is not supported.": "Die Erfassung wird nicht unterstützt.",
  "Overlay Layers": "Überlagerungsebenen",
  Version: "Version",
  Source: "Quelle",
  Extractor: "Extractor",
  Extracted: "Extrahiert",
  Updated: "aktualisierte",
  "No Information Available": "Keine Informationen verfügbar",
  "Base Layers": "Basis-Layer",
  Mapcode: "Mapcode",
  coordinate: "Koordinieren",
  Car: "Auto",
  Public: "Öffentlich",
  Truck: "Lkw",
  Pedestrian: "Fußgänger",
  "Route Directions": "Routenbeschreibung",
  "Route overview": "Routenübersicht",
  Time: "Zeit",
  "Unable to retrive information.": "Informationen können nicht retrive.",
  "Location timeout error.": "Standorttimeoutfehler.",
  "Location unavailable.": "Speicherort nicht verfügbar.",
  "Only secure origins are allowed.": "Es sind nur sichere Ursprünge zulässig.",
  "Location permission denied!": "Standortberechtigung verweigert!",
  "Unknown location error.": "Unbekannter Standortfehler.",
  "Base layer included in permalink is not present in current user's configuration.":
    "Die in permalink enthaltene Basisschicht ist in der Konfiguration des aktuellen Benutzers nicht vorhanden.",
  "Checking for current position...":
    "Wird nach der aktuellen Position gesucht...",
  "Routing lost position. Try again": "Routing verloren Position. Wiederholen",
  "Calculating route...": "Route wird berechnet...",
  "Route calculation failed. The path between your position and the specified destination could not be determined.":
    "Die Routenberechnung ist fehlgeschlagen. Der Pfad zwischen Ihrer Position und dem angegebenen Ziel konnte nicht bestimmt werden.",
  "The input is required.": "Die Eingabe ist erforderlich.",
  "The input allows decimal values only.": "Die Eingabe erlaubt nur Dezimalwerte.",
  "This field doesn't allow special characters.":
    "Dieses Feld lässt keine Sonderzeichen zu.",
  "Username should not contain special characters / spaces":
    "Benutzername sollte keine Sonderzeichen / Leerzeichen enthalten",
  "This field doesn't allow starting and trailing whitespaces.":
    "Dieses Feld lässt keine Leerzeichen beim Starten und Nachverfolgen zu.",
  "Number of digits should not exceed 15":
    "Anzahl der Ziffern sollte 15 nicht überschreiten",
  "Error: Network Error": "Fehler: Netzwerkfehler",
  Others: "andere",
  "Clear All": "Alle löschen",
  "Error in fetching features at your tapped location.": "Fehler beim Abrufen von Features an Ihrem angezapften Standort.",
  Download: "Herunterladen",
  "Online Layer": "Online-Ebene",
  "Can't get current location. Using home region instead.":"Aktuelle Position kann nicht abgerufen werden. Verwenden von Home Region statt.",
  "Parse Error": "Parse-Fehler",
  "Invalid URL": "Ungültige URL",
  "Shares the permalink to the current view in an email.":"Teilt den Permalink zur aktuellen Ansicht in einer E-Mail.",
  "Generates the permalink to the current map view, which can be copied and shared.":"Generiert den Permalink zur aktuellen Kartenansicht, die kopiert und freigegeben werden kann.",
  "Prints the screenshot of the current map view.": "Druckt den Screenshot der aktuellen Kartenansicht.",
  "User can measure the area between three or more points by selecting the Area option on the measurement toolbar and clicking on three or more points on the map.A polygon is drawn between the points on the map and accumulated distance value is displayed at each vertex on mouse over.The total area value is populated in the Area label on the measurement toolbar.":"Der Benutzer kann die Fläche zwischen drei oder mehr Punkten messen, indem er in der Messsymbolleiste die Option Fläche auswählt und auf drei oder mehr Punkte auf der Karte klickt. Ein Polygon wird zwischen den Punkten auf der Karte gezeichnet und der akkumulierte Entfernungswert wird an jedem Scheitelpunkt beim Mouseover angezeigt. Der Wert für den Gesamtflächenwert wird in der Bereichsbeschriftung auf der Messsymbolleiste aufgefüllt.",
  "Measure Area":"Fläche messen",
  "Measure distance":"Entfernung messen",
  "User can measure the distance between two or more points by selecting the Distance option on the measurement toolbar and clicking on two or more points on the map.A line is drawn between consective points on the map and the individual distance value is displayed at each segment on mouse over. The accumulated distance value is displayed at each vertex on mouse over. The total distance value is populated in the Distance label on the measurement toolbar.":"Der Benutzer kann den Abstand zwischen zwei oder mehr Punkten messen, indem er in der Messsymbolleiste die Option Abstand auswählt und auf zwei oder mehr Punkte auf der Karte klickt. Zwischen den Schnittpunkten auf der Karte wird eine Linie gezeichnet und der individuelle Entfernungswert wird bei jedem Segment per Mouseover angezeigt. Der kumulierte Abstandswert wird bei der Mouseover an jedem Scheitelpunkt angezeigt. Der Wert für die Gesamtentfernung wird in der Messsymbolleiste in die Beschriftung Entfernung eingefügt.",
  "Measure coordinates":"Koordinaten messen",
  "The last measurement action, which was undone using Undo button can be redone by clicking on the Redo button on the measurement toolbar.":"Die letzte Messaktion, die mit der Schaltfläche Rückgängig rückgängig gemacht wurde, kann durch Klicken auf die Schaltfläche Wiederholen in der Messsymbolleiste wiederholt werden.",
  "The last measurement action can be undone by clicking on the Undo button on the measurement toolbar.":"Die letzte Messaktion kann rückgängig gemacht werden, indem Sie auf der Symbolleiste der Messung auf die Schaltfläche Rückgängig klicken.",
  "All the measurements can be cleared from the map by clicking on the Clear All button on the measurement toolbar.": "Alle Messungen können aus der Karte gelöscht werden, indem Sie auf der Messsymbolleiste auf die Schaltfläche Alle löschen klicken.",
  "Clear all":"Alle löschen",
  "Street Level":"Straßenniveau",
  "This shows the Street Level view at the location of the selected feature/address. This feature is not yet available for all countries.":"Dies zeigt die Street Level-Ansicht an der Position des ausgewählten Features / der ausgewählten Adresse an. Diese Funktion ist noch nicht für alle Länder verfügbar.",
  "Feature Links":"Feature-Links",
  "Feature links are displayed as text buttons at the bottom of the attributes window. Feature links allow open an external application and to send data from LatLonGO to an external application. Feature links can be defined to automatically send data, e.g. attributes and location associated with selected feature.Feature links are defined on the LatLonGO mobile server.":"Feature-Links werden als Textschaltflächen am unteren Rand des Attributfensters angezeigt. Feature-Links ermöglichen es, eine externe Anwendung zu öffnen und Daten von LatLonGO an eine externe Anwendung zu senden. Feature-Links können definiert werden, um automatisch Daten zu senden, z. B. Attribute und Standort, die mit dem ausgewählten Feature verknüpft sind. Feature-Links werden auf dem Mobilen Server von LatLonGO definiert.",
  "This calculates a route from your current location to the selected feature/address.Car Routing: A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.Truck Routing This calculates the truck route from current location to the location of selected feature/address.Public Transit RoutingThis calculates the public transit route from current location to the location of selected feature/address. Pedestrian Routing This calculates the pedestrian route from current location to the location of selected feature/address.":"Dadurch wird eine Route von Ihrem aktuellen Standort zum ausgewählten Feature /Adresse berechnet. Car Routing: Eine Car-Route wird berechnet, wenn der Benutzer im Attributfenster auf die Schaltfläche Routing klickt. Das Car-Routing ist der Standard-Routing-Modus. Truck Routing Berechnet die LKW-Route vom aktuellen Standort zum Standort des ausgewählten Features/der ausgewählten Adresse. Public Transit RoutingDies berechnet die Route des öffentlichen Nahverkehrs vom aktuellen Standort zum Standort der ausgewählten Funktion/Adresse. Fußgänger-Routing Hiermit wird die Fußgängerroute vom aktuellen Standort zum Standort der ausgewählten Funktion/Adresse berechnet.",
  "Search bar searches for features and street addresses. For features, all layers, no matter whether currently visible or not, are searched. If you select from the result list a feature which belongs to a layer which is currently switched off, that layer will automatically be switched on before highlighting the location of the feature.User can also search for mapcodes by providing valid mapcode in the search bar. See the Mapcodes for more information.":"Die Suchleiste sucht nach Merkmalen und Straßenadressen. Für Features werden alle Ebenen, egal ob aktuell sichtbar oder nicht, durchsucht. Wenn Sie aus der Ergebnisliste ein Feature auswählen, das zu einem aktuell ausgeschalteten Layer gehört, wird dieser Layer automatisch eingeschaltet, bevor die Position des Features markiert wird. Der Benutzer kann auch nach Mapcodes suchen, indem er gültigen Mapcode in der Suchleiste angibt. Weitere Informationen finden Sie in den Mapcodes.",
  "Units Toggle: Toggles the units from Metric to Imperial and vice versa. Affects scale bar units, distance/area units in measurements and the distance units in route calculations and manoeuvres.":"Einheiten umschalten: Schaltet die Einheiten von metrisch in imperial und umgekehrt um. Betrifft Maßstabsleisteneinheiten, Entfernungs-/Flächeneinheiten in Messungen und die Entfernungseinheiten in Routenberechnungen und Manövern.",
  "Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.Clicking on the Measure tool on the main navigation toolbar brings up the Measurement toolbar.":"Wenn Sie in der Hauptnavigationssymbolleiste auf das Messwerkzeug klicken, wird die Symbolleiste Messung angezeigt. Wenn Sie auf der Hauptnavigationssymbolleiste auf das Messwerkzeug klicken, wird die Symbolleiste Messung angezeigt.",
  "The edits synced by field users can be viewed by clicking on the Redlines button. It displays the redlines toolbar. On clicking the Redlines button on the redline toolbar displays the list of redlines in the area, along with the users.Clicking on a redline in the list displays all the contained redline objects on the map. If a photo/audio is there as part of redlines, the corresponding icons (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) are displayed on the map. User can click on the icon and see the photo, listen to the audio or view the form.":"Die von Feldbenutzern synchronisierten Bearbeitungen können durch Klicken auf die Schaltfläche Rote Linien angezeigt werden. Es zeigt die Symbolleiste mit den roten Linien an. Wenn Sie auf die Schaltfläche Redlines in der Redline-Symbolleiste klicken, wird die Liste der Redlines im Bereich zusammen mit den Benutzern angezeigt. Wenn Sie auf eine rote Linie in der Liste klicken, werden alle enthaltenen Redline-Objekte auf der Karte angezeigt. Wenn ein Foto/Audio als Teil von Redlines vorhanden ist, werden die entsprechenden Symbole (Camera Alt Redline Toolbar , Microphone Alt edits Toolbar) auf der Karte angezeigt. Der Benutzer kann auf das Symbol klicken und das Foto anzeigen, das Audio anhören oder das Formular anzeigen.",
  "To get more information about a feature, click on the feature on the map. A map marker showing the feature type will be displayed.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.Clicking on the map marker, will open a window which lists the attributes coming out of the GIS system.If there are several features in the clicked area, you can access them via the Others button displayed along with the map marker for the clicked feature or the Features button displayed at the top left of the attributes window.":"Um weitere Informationen zu einem Feature zu erhalten, klicken Sie auf das Feature auf der Karte. Eine Kartenmarkierung mit dem Feature-Typ wird angezeigt. Ein Klick auf die Kartenmarkierung öffnet ein Fenster, in dem die Attribute aufgelistet sind, die aus dem GIS-System kommen. Wenn sich im angeklickten Bereich mehrere Features befinden, können Sie über die Schaltfläche Andere zugreifen, die zusammen mit der Kartenmarkierung für das angeklickte Feature angezeigt wird, oder über die Schaltfläche Features, die oben links im Attributfenster angezeigt wird. Ein Klick auf die Kartenmarkierung öffnet ein Fenster, in dem die Attribute aufgelistet sind, die aus dem GIS-System kommen. Wenn sich im angeklickten Bereich mehrere Features befinden, können Sie über die Schaltfläche Andere zugreifen, die zusammen mit der Kartenmarkierung für das angeklickte Feature angezeigt wird, oder über die Schaltfläche Features, die oben links im Attributfenster angezeigt wird.",
  "Layer Control shows all on-line and off-line available data sets. In on-line mode these could include HERE maps such as HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap or WMTS layers.In off-line mode, those layers extracted from the GIS (Land Base, Electricity, Gas, Water, Telco etc…) are available.":"Layer Control zeigt alle online und offline verfügbaren Datensätze an. Im Online-Modus könnten dies HERE-Karten wie HERE Normal Day, HERE Hybrid Day, HERE Pedestrian Day/Night, HERE Satellite, HERE Terrain, OpenStreetMap oder WMTS-Layer sein. Im Offline-Modus sind die aus dem GIS extrahierten Layer (Land Base, Strom, Gas, Wasser, Telco usw.) verfügbar.",
  "On activating Geolocate, the button turns green and your current position is shown on the map. In case, current position is not found or the GPS signal is lost, user is informed by displaying appropriate message.":"Wenn Sie Geolocate aktivieren, wird die Schaltfläche grün und Ihre aktuelle Position wird auf der Karte angezeigt. Für den Fall, dass die aktuelle Position nicht gefunden wird oder das GPS-Signal verloren geht, wird der Benutzer durch Anzeigen einer entsprechenden Meldung informiert.",
  "The Home button resets the map view to a pre-determined view port and layers defined by the server administrator.The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator. The Home button also closes any mode (redlines, measurements, routing, internals, schematics etc.) if displayed on the map, removes the selection if any on the map and resets the scale bar units to the original units set by server administrator.":"Die Home-Taste setzt die Kartenansicht auf einen vordefinierten Ansichtsport und Layer zurück, die vom Serveradministrator definiert wurden. Die Home-Taste schließt auch jeden Modus (Rote Linien, Maße, Routing, Interna, Schaltpläne usw.), wenn er auf der Karte angezeigt wird, entfernt die Auswahl, falls vorhanden auf der Karte, und setzt die Einheiten der Maßstabsleiste auf die ursprünglichen Einheiten zurück, die vom Serveradministrator festgelegt wurden. Die Home-Taste schließt auch jeden Modus (Rote Linien, Messungen, Routing, Interna, Schaltpläne usw.), wenn er auf der Karte angezeigt wird, entfernt die Auswahl, falls vorhanden auf der Karte, und setzt die Maßstabsleisteneinheiten auf die vom Serveradministrator festgelegten ursprünglichen Einheiten zurück.",
  "Gives the information about the installed web app package.":"Gibt die Informationen zum installierten Web-App-Paket an.",
  "Car Routing":"Car Routing",
  "A car route is calculated when user clicks on the Routing button on the attributes window. Car routing is the default routing mode.":"Eine Autoroute wird berechnet, wenn der Benutzer im Attributfenster auf die Schaltfläche Routing klickt. Car Routing ist der Standard-Routing-Modus.",
  "Truck Routing":"LKW-Routing",
  "This calculates the truck route from current location to the location of selected feature/address.":"Dadurch wird die LKW-Route vom aktuellen Standort zum Standort des ausgewählten Merkmals / der ausgewählten Adresse berechnet.",
  "Public Transit Routing":"ÖPNV-Routing",
  "This calculates the public transit route from current location to the location of selected feature/address.":"Dadurch wird die Route des öffentlichen Nahverkehrs vom aktuellen Standort zum Standort des ausgewählten Merkmals / der ausgewählten Adresse berechnet.", 
  "Pedestrian Routing":"Fußgänger-Routing",
  "This calculates the pedestrian route from current location to the location of selected feature/address.":"Dadurch wird die Fußgängerroute vom aktuellen Standort zum Standort des ausgewählten Merkmals / der ausgewählten Adresse berechnet."
};
export default deTranslations;
