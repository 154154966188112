import HereUtil from "@/util/here";

const routing = {
  namespaced: true,
  state: {
    startPoint: undefined,
    endPoint: [],
    currentTransportMode: "car",
    alternatives: 3,
    routeArray: [],
    selectedRouteIndex: 0,
    isRouteDirectionsPanelVisible: false
  },
  getters: {
    getRouteDirections(state) {
      return state.routeArray[state.selectedRouteIndex].sections[0].actions;
    },
    distance(state, getters, rootState) {
      if (state.routeArray.length > 0) {
        let distance = 0;
        state.routeArray[state.selectedRouteIndex].sections.forEach(section => {
          distance += section.travelSummary.length;
        });
        let currentUnitSystem = rootState.home.config.units;
        let unit = "m";
        let uniFactor = 1;
        if (currentUnitSystem === "imperial") {
          unit = "ft";
          uniFactor = 3.28084;
          if (distance > 1000) {
            unit = "mi";
            uniFactor = 0.000621371;
          }
        } else {
          if (distance > 1000) {
            unit = "km";
            uniFactor = 0.001;
          }
        }
        return (distance * uniFactor).toFixed(3) + unit;
      }
    },
    time(state) {
      if (state.routeArray.length > 0) {
        let time = 0;
        state.routeArray[state.selectedRouteIndex].sections.forEach(section => {
          time += section.travelSummary.duration;
        });
        let hour = Math.floor(time / 3600);
        let minute = Math.floor((time % 3600) / 60);
        let second = Math.floor((time % 3600) % 60);

        let hourDisplay =
          hour > 0 ? hour + (hour == 1 ? " hour, " : " hours, ") : "";
        let minDisplay =
          minute > 0 ? minute + (minute == 1 ? " minute, " : " minutes, ") : "";
        let secDisplay =
          second > 0 ? second + (second == 1 ? " second" : " seconds") : "";
        return hourDisplay + minDisplay + secDisplay;
      }
    }
  },
  mutations: {
    changeStartPoint(state, value) {
      state.startPoint = value;
    },
    changeEndPoint(state, value) {
      state.endPoint = value;
    },
    changeCurrentTransporMode(state, mode) {
      state.currentTransportMode = mode;
    },
    changeAlternatives(state, value) {
      state.alternatives = value;
    },
    changeRouteArray(state, payload) {
      state.routeArray.splice(0);
      if (payload) {
        state.routeArray = payload;
      }
    },
    changeSelectedRouteIndex(state, value) {
      state.selectedRouteIndex = value;
    },
    changeIsRouteDirectionWindowOpen(state, value) {
      state.isRouteDirectionsPanelVisible = value;
    }
  },
  actions: {
    handleRouteToDestination(
      { state, rootState, commit, dispatch },
      destination
    ) {
      window.getApp.$emit("FLASH_MESSAGE", {
        text: "Checking for current position...",
        type: "info"
      });
      if (rootState.setting.isMockGpsSwitchOn) {
        commit("changeStartPoint", rootState.setting.mockLocation);
        commit("changeEndPoint", destination);
        dispatch("handleCalculateRouteToDestination");
      } else {
        navigator.geolocation.getCurrentPosition(
          position => {
            commit("changeStartPoint", position);
            commit("changeEndPoint", destination);
            dispatch("handleCalculateRouteToDestination");
          },
          error => {
            window.getApp.$emit("FLASH_MESSAGE", {
              text: "Routing lost position. Try again",
              type: "error"
            });
            window.getApp.$insProgress.finish();

            // show feature panel
            commit("featurePanel/updateFeaturePanelVisibility", true, {
              root: true
            });
          },
          { maximumAge: 1000, timeout: 5000, enableHighAccuracy: false }
        );
      }
    },
    handleCalculateRouteToDestination({ rootState, state, commit, dispatch }) {
      commit("changeSelectedRouteIndex", 0);
      commit("changeRouteArray");
      window.getApp.$emit("FLASH_MESSAGE", {
        text: "Calculating route...",
        type: "info"
      });
      let hereUtil = new HereUtil();
      hereUtil
        .calculateRoute(
          state.startPoint,
          state.endPoint,
          state.currentTransportMode,
          state.alternatives,
          rootState.home.config.units
        )
        .then(
          response => {
            if (response.routes.length > 0) {
              dispatch("geolocation/handleGeolocation", "not-follow", {
                root: true
              });
              if (rootState.appMode !== "routing") {
                dispatch(
                  "handleSwitchFromHome",
                  { mode: "routing", value: response.routes },
                  { root: true }
                ).then(() => {
                  commit("changeRouteArray", response.routes);
                });
              } else {
                commit("changeRouteArray", response.routes);
              }
            } else {
              window.getApp.$insProgress.finish();
              window.getApp.$emit("FLASH_MESSAGE", {
                text:
                  "Route calculation failed. The path between your position and the specified destination could not be determined.",
                type: "error"
              });
            }
          },
          error => {
            window.getApp.$insProgress.finish();
            window.getApp.$emit("FLASH_MESSAGE", {
              text:
                "Route calculation failed. The path between your position and the specified destination could not be determined.",
              type: "error"
            });
          }
        );
    },
    handleChangeTransportMode({ state, commit, dispatch }, mode) {
      commit("changeRouteArray");
      commit("changeCurrentTransporMode", mode);
      dispatch("handleRouteToDestination", state.endPoint);
    },
    handleExitMyMode({ rootState, commit, dispatch }) {
      dispatch("geolocation/handleGeolocation", "off", { root: true });
      commit("changeRouteArray");
      commit("changeCurrentTransporMode", "car");
    }
  }
};
export default routing;
