import HereUtil from "@/util/here";
import H from "@here/maps-api-for-javascript";
import apiLogin from "@/api/login";

export default class HereStoreUtil {
  static #_instance = null;
  constructor() {
    if (!HereStoreUtil.#_instance) {
      HereStoreUtil.#_instance = this;
      console.log("creating here store util instance");
    }
    return HereStoreUtil.#_instance;
  }
  getEditFeatureDomIcon(
    svg,
    parentId,
    childId,
    title,
    store,
    highlight_fill_color,
    id,
    featureType
  ) {
    let self = this;
    return new H.map.DomIcon(svg.firstElementChild, {
      // the function is called every time marker enters the viewport
      onAttach: function(clonedElement, domIcon, domMarker) {
        let currentFeatureDetails = {
          parentId: parentId,
          childId: childId,
          clonedElement: clonedElement,
          title: title,
          highlight_fill_color: highlight_fill_color
        };
        domMarker.setData({
          id: id,
          currentFeatureDetails: currentFeatureDetails,
          featureType: featureType
        });
      }
    });
  }

  handleEditTap(evt, store) {
    let self = this;
    let hereUtil = new HereUtil();
    hereUtil.hereMap.getObjectsAt(
      evt.currentPointer.viewportX,
      evt.currentPointer.viewportY,
      function(objects) {
        let editObjectsList = [];
        if (objects) {
          objects.forEach(object => {
            if (object.getData()) {
              editObjectsList.push(object);
            }
          });
        }
        if (editObjectsList.length === 1) {
          let editDetails = editObjectsList[0].getData();
          // Processing the tap on edit feature if it is not LatLonGORedlineNote
          if (editDetails) {
            if (store.state.appMode === "featurePanel") {
              store.commit("featurePanel/changeCurrentFeatureInfo", {
                type: ""
              });
              store.commit("featurePanel/changeFeaturePanelVisibility", false);
            }
            if (editDetails.featureType === "LatLonGORedlinePhoto") {
              store.commit(
                "edit/changeSelectedFeature",
                editDetails.currentFeatureDetails
              );
              editDetails.currentFeatureDetails.clonedElement.firstElementChild.style.fill =
                store.state.home.config.highlight_fill_color;
              store.commit("commitAppMode", "editsPhotoDialog");
            } else if (
              editDetails.featureType === "LatLonGORedlineAudioFeature"
            ) {
              store.commit(
                "edit/changeSelectedFeature",
                editDetails.currentFeatureDetails
              );
              editDetails.currentFeatureDetails.clonedElement.firstElementChild.childNodes.forEach(
                node =>
                  (node.style.fill =
                    store.state.home.config.highlight_fill_color)
              );
              store.commit("commitAppMode", "audioDialog");
            } else {
              store.dispatch("handleSwitchFromHome", {
                mode: "featurePanel",
                value: editDetails
              });
            }
          }
        } else if (editObjectsList.length > 1) {
          self.openMultiEditFeaturesWindow(editObjectsList, store);
        }
      }
    );
  }
  updateMeasurementValueOnDrag(
    { verticeGroup, measurementFeature, labelsGroup },
    store,
    hereMap,
    mode,
    units
  ) {
    // event listener for vertice markers group to resize the geo measurementFeature object if dragging over markers
    let hereUtil = new HereUtil();
    verticeGroup.addEventListener("dragend", function(evt) {
      store.commit("measurement/changeUndoStack", {
        action: "push"
      });
    });
    verticeGroup.addEventListener(
      "drag",
      function(evt) {
        let pointer = evt.currentPointer;
        let geoLineString;
        let geoPoint = hereMap.screenToGeo(
          pointer.viewportX,
          pointer.viewportY
        );
        let verticeIndex = evt.target.getData()["verticeIndex"];

        if (mode === "area") {
          geoLineString = measurementFeature.getGeometry().getExterior();
          geoLineString.removePoint(verticeIndex);
          geoLineString.insertPoint(verticeIndex, geoPoint);
          let newPolygonVertices = geoLineString.getLatLngAltArray();
          let coordinates = [];
          for (let i = 0; i < newPolygonVertices.length; i = i + 3) {
            let obj = {
              lat: newPolygonVertices[i],
              lng: newPolygonVertices[i + 1]
            };
            coordinates.push(obj);
          }
          hereUtil.addLabel(geoLineString, labelsGroup, hereUtil, false, units);
          let isPolygonValid = hereUtil.isPolygonValid(coordinates);
          if (isPolygonValid.isValid) {
            evt.target.setGeometry(geoPoint);
            measurementFeature.setGeometry(new H.geo.Polygon(geoLineString));
            store.commit("measurement/changeMapTappedPoints", {
              action: "pointUpdate",
              index: verticeIndex,
              value: geoLineString.extractPoint(verticeIndex)
            });
            store.dispatch("measurement/handleMeasurementCalculations");
          } else {
            store.dispatch(
              "measurement/handleShowInvalidPolygonMessage",
              isPolygonValid
            );
          }
        } else {
          evt.target.setGeometry(geoPoint);
          geoLineString = measurementFeature.getGeometry();
          // set new position for measurementFeature's vertice
          geoLineString.removePoint(verticeIndex);
          geoLineString.insertPoint(verticeIndex, geoPoint);
          measurementFeature.setGeometry(geoLineString);
          hereUtil.addLabel(geoLineString, labelsGroup, hereUtil, true, units);
          store.commit("measurement/changeMapTappedPoints", {
            action: "pointUpdate",
            index: verticeIndex,
            value: measurementFeature.getGeometry().extractPoint(verticeIndex)
          });
          store.dispatch("measurement/handleMeasurementCalculations");
        }
        // stop propagating the drag event, so the map doesn't move
        evt.stopPropagation();
      },
      true
    );
  }

  openMultiEditFeaturesWindow(objects, store) {
    let editsUnderTap = {};
    editsUnderTap["properties"] = {};
    editsUnderTap["currentEditfeatures"] = {};
    editsUnderTap["featureType"] = {};
    editsUnderTap["joins"] = [];
    objects.forEach(object => {
      let editDetails = object.getData();
      if (editDetails) {
        //this is to ignore redline note
        if (editDetails.title) {
          editsUnderTap["joins"].push({
            key: editDetails.title,
            value: editDetails
          });
        } else {
          editsUnderTap["properties"][editDetails.id] =
            editDetails.currentFeatureDetails.title;
          editsUnderTap["currentEditfeatures"][editDetails.id] =
            editDetails.feature || editDetails.currentFeatureDetails;
          editsUnderTap["featureType"][editDetails.id] =
            editDetails.searchType || editDetails.featureType;
        }
      }
    });
    store.dispatch("handleSwitchFromHome", {
      mode: "featurePanel",
      value: {
        feature: editsUnderTap,
        searchType: "multiEditFeatures",
        title: store.state.edit.currentSelectedEdit.name
      }
    });
  }

  handlePostLogin(username, store) {
    // store.commit("commitLatlongoApiKey", response.headers["latlongo-api-key"]);
    store.commit("commitLoginSuccess", username);
    return apiLogin.getConfigId().then(response => {
      if (response.data.length > 1) {
        // This requires admin access.
        return apiLogin.getConfigIdByUser(username).then(configId => {
          store.commit("commitConfigId", configId);
          store.dispatch("home/handleGoToHomeMode");
          return true;
        });
      } else {
        let configId = response.data[0].id;
        if (configId) {
          store.commit("commitConfigId", configId);
          store.dispatch("home/handleGoToHomeMode");
        }
        return true;
      }
    });
  }
}
