const axios = require("axios");
const https = require("https");
// import defaults from "@/util/defaults";

let api;
let basePath = '/ws/v6/';

// let userDefaults = defaults.getUserDefaults();
let userDefaults = { latlongoServer: "" };
api = axios.create({
  baseURL: userDefaults.latlongoServer,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  headers: { "LatLonGO-API-Key": "" }
});

export {
  api,
  basePath
};
