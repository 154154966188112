import { api, basePath } from "@/api/index";

const getlayerDetails = function(layerId) {
  return api.get(basePath + "layers/" + layerId + "/metadata").then(
    response => {
      return response.data;
    },
    error => {
      throw error;
    }
  );
};

const getLayersDetails = function(layerIds) {
  let layerDetailsPromises = [];
  layerIds.forEach(layerId =>
    layerDetailsPromises.push(getlayerDetails(layerId))
  );
  return Promise.all(layerDetailsPromises);
};

export default {
  getlayerDetails,
  getLayersDetails
};
