<template>
  <v-footer
    fixed
    app
    dense
    padless
    :dark="$vuetify.dark"
    name="BottomToolbar"
    color="primary"
    height="50"
    max-height="50"
  >
    <v-tooltip top :disabled="isShowTooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          id="geolocateStyle"
          dark
          name="Geolocate"
          v-on="on"
          :style="{
            color: geoColor,
            animation: geoAnimation,
            backgroundColor: 'transparent',
          }"
          :loading="isLoading"
          @click="geoLocate"
        >
          <v-icon>mdi-adjust</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Geolocate") }}</span>
    </v-tooltip>
    <v-divider vertical dark></v-divider>
    <Measurement v-if="isMeasurementMode" />
    <Routing v-if="isRoutingMode" />
    <v-spacer />
    <v-tooltip top :disabled="isShowTooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          dark
          name="Undo"
          :disabled="!isUndoDisable"
          v-on="on"
          @click="undo"
          v-show="isUndoButtonVisible"
        >
          <v-icon>mdi-undo</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Undo") }}</span>
    </v-tooltip>
    <v-tooltip top :disabled="isShowTooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          dark
          name="Redo"
          :disabled="!isRedoDisable"
          v-on="on"
          @click="redo"
          v-show="isRedoButtonVisible"
        >
          <v-icon>mdi-redo</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Redo") }}</span>
    </v-tooltip>

    <v-tooltip top :disabled="isShowTooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          dark
          name="Delete"
          v-on="on"
          @click="delet"
          :disabled="!isUndoDisable && !isRedoDisable"
          v-show="isDeleteButtonVisible"
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Clear All") }}</span>
    </v-tooltip>
    <v-divider vertical dark></v-divider>
    <v-menu top offset-y :close-on-content-click="closeonclick" z-index="100">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top :disabled="isShowTooltip">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              dark
              name="More"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("More") }}</span>
        </v-tooltip>
      </template>
      <Bottombarmore />
    </v-menu>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "app-bottom-toolbar",
  components: {
    Bottombarmore: () => import("@/components/BottombarMore"),
    Measurement: () => import("@/components/Measurement"),
    Routing: () => import("@/components/Routing")
  },
  computed: {
    ...mapState({
      isDeleteButtonVisible: state => state.isDeleteButtonVisible,
      isUndoButtonVisible: state => state.isUndoButtonVisible,
      isRedoButtonVisible: state => state.isRedoButtonVisible,
      appMode: state => state.appMode,
      isRoutingMode: state => state.appMode === "routing",
      isMeasurementMode: state => state.appMode === "measurement",
      isLoading: state => state.geolocation.loading,
      geolocate: state => state.geolocation.geolocate,
      closeonclick: state => state.more.isMenuListClosed,
      isShowTooltip: state => !state.setting.isToolTipsOn,
      isUndoDisable: state => state.measurement.undoStack.length,
      isRedoDisable: state => state.measurement.redoStack.length
    }),
    geoColor() {
      return this.$store.getters["geolocation/color"];
    },
    geoAnimation() {
      return this.$store.getters["geolocation/animation"];
    }
  },
  methods: {
    undo() {
      this.$store.dispatch("handleUndo");
    },
    redo() {
      this.$store.dispatch("handleRedo");
    },
    delet() {
      this.$store.dispatch("handleDelete");
    },
    geoLocate() {
      let geolocateNext = this.geolocate === "off" ? "follow" : "off";
      this.$store.dispatch("geolocation/handleGeolocation", geolocateNext);
    }
  }
};
</script>
<style>
#geolocateStyle {
  box-shadow: none !important;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
