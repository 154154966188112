const setting = {
  namespaced: true,
  state: {
    isShareFeatureOn: true,
    isToolTipsOn: true,
    ishereToolsOn: true,
    isMockGpsSwitchOn: false,
    mockLocation: {
      coords: {
        latitude: null,
        longitude: null
      }
    }
  },
  mutations: {
    changeMockGPSLocation(state) {
      state.isMockGpsSwitchOn = !state.isMockGpsSwitchOn;
    },
    updateMockLocation(state, payLoad) {
      state.mockLocation.coords.latitude = payLoad.mocklat;
      state.mockLocation.coords.longitude = payLoad.mocklon;
    },
    updateHereToolStatus(state) {
      state.ishereToolsOn = !state.ishereToolsOn;
    },
    changeIsToolTipsStatus(state) {
      state.isToolTipsOn = !state.isToolTipsOn;
    },
    changeShareFeatureStatus(state) {
      state.isShareFeatureOn = !state.isShareFeatureOn;
    }
  }
};
export default setting;
